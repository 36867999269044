import { EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPanel, EuiSpacer } from '@elastic/eui';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';
import Pagination from '../../components/Form/Pagination';
import ContextMenu from '../../components/Global/Pagination/ContextMenu';
import { fetchPastTableOrderDetails } from '../../components/TableManagement/Api/tableOperations';
import CustomDatePicker from '../../components/UIComponent/CustomDatePicker';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';
import { SET_ACTIVE_TABLE_ID } from '../../reduxStore/types/tableManagement';
import { toastsErrorMessage } from '../../utils/toasts';
import SessionOrdersFlyout from './SessionOrdersFlyout';

const PastTableSession = () => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletDetails = useSelector((state) => state.outlet.selectedOutlet?.outlet_data);
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const { screenHeight } = useSingleScreenHeightCalc(400);
    const [selectedTable, setSelectedTable] = useState({});
    const tableOrderDetails = useSelector((state) => state.tableManagement.tableOrderDetails?.orders);

    const [state, setState] = useState({
        isLoading: false,
        data: {},
        items: [],
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [showSessionOrders, setShowSessionOrders] = useState(false);

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                name: 'Session ID',
            },
            {
                field: 'table',
                name: 'Table',
            },
            {
                field: 'time',
                name: 'Time',
            },
            {
                field: 'amount',
                name: 'Amount',
                render: (amount) => {
                    return `${outletDetails?.settings?.currency_symbol}${amount}`;
                },
            },
        ];
    }, [outletDetails?.settings?.currency_symbol]);

    const getRowProps = (item) => {
        const { id } = item;
        return {
            'data-test-subj': `row-${id}`,
            className: 'customRowClass',
            onClick: () => {
                setShowSessionOrders(true);
                handleOrderFlyoutState(item);
            },
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            className: 'customCellClass',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    const fetchPastTableSessions = useCallback(async () => {
        setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        try {
            const response = await APIV3.get(
                `/restaurants/42/outlets/${outletId}/sessions?page=${pagination.pageIndex + 1}&per_page=${
                    pagination.pageSize
                }&sort_by=id&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format(
                    'YYYY-MM-DD'
                )}&sorting_direction=asc`
            );

            const tableFormattedData = [];

            if (response.data.data?.length) {
                for (const record of response.data.data) {
                    tableFormattedData.push({
                        id: record.id,
                        table: record.table_name,
                        amount: record.amount,
                        time: moment(record.started_at)
                            .fromNow()
                            .replace('a few seconds ago', '0 Mins')
                            .replace('a minute ago', '0 Mins')
                            .replace('minutes', 'Mins')
                            .replace('ago', '')
                            .replace('hours', 'hrs'),
                        outlet_table_id: record,
                    });
                }
            }

            setState((prevState) => ({
                ...prevState,
                isLoading: false,
                data: response.data,
                items: tableFormattedData,
            }));
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                isLoading: false,
            }));
        }
    }, [startDate, outletId, endDate, pagination.pageIndex, pagination.pageSize]);

    useEffect(() => {
        fetchPastTableSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outletId, pagination.pageIndex, startDate, endDate, pagination.pageSize]);

    const handlePageChange = (page) => {
        setPagination({
            ...pagination,
            pageIndex: page,
        });
    };

    const handleOrderFlyoutState = useCallback(
        (table) => {
            table.internal_name = table?.table;
            // setOrderFlyout((prevState) => !prevState);
            setSelectedTable(table);
            // if (!showSessionOrders) {
            if (table?.outlet_table_id?.outlet_table_id) {
                // setIsLoading(true);
                dispatch({
                    type: SET_ACTIVE_TABLE_ID,
                    payload: table.outlet_table_id.outlet_table_id,
                });
                dispatch(fetchPastTableOrderDetails(table.id)).then((response) => {
                    if (!response.orders.data?.length) {
                        toastsErrorMessage('No Orders Found', dispatch);
                    }
                    // setIsLoading(false);
                });
            }
        },
        [dispatch, setSelectedTable]
    );

    const popOverItems = useMemo(() => {
        return [
            {
                key: '10 rows',
                onClick: (closePopover) => {
                    closePopover();
                    setPagination((prevState) => ({
                        ...prevState,
                        pageSize: 10,
                    }));
                },
                iconSize: 10,
            },
            {
                key: '20 rows',
                onClick: (closePopover) => {
                    closePopover();
                    setPagination((prevState) => ({
                        ...prevState,
                        pageSize: 20,
                    }));
                },
                iconSize: 20,
            },
            {
                key: '50 rows',
                onClick: (closePopover) => {
                    closePopover();
                    setPagination((prevState) => ({
                        ...prevState,
                        pageSize: 50,
                    }));
                },
                iconSize: 50,
            },
            {
                key: '100 rows',
                onClick: (closePopover) => {
                    closePopover();
                    setPagination((prevState) => ({
                        ...prevState,
                        pageSize: 100,
                    }));
                },
                iconSize: 100,
            },
        ];
    }, []);

    return (
        <>
            {showSessionOrders && tableOrderDetails?.length >= 0 && (
                <SessionOrdersFlyout selectedTable={selectedTable} setShowSessionOrders={setShowSessionOrders} />
            )}
            <EuiPanel>
                <CustomDatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    displayFormat='DD-MM-YYYY'
                    isLoading={state.isLoading}
                />
                <EuiSpacer />
                {state.isLoading ? (
                    <>
                        <EuiLoadingContent lines={5} />
                    </>
                ) : (
                    <>
                        <EuiBasicTable
                            tableCaption='Table of past sessions'
                            items={state.items}
                            rowHeader='firstName'
                            columns={columns}
                            rowProps={getRowProps}
                            cellProps={getCellProps}
                            style={{
                                maxHeight: screenHeight,
                                overflowY: 'auto',
                            }}
                            className='scrollbar'
                        />
                    </>
                )}
                {state.items?.length ? (
                    <>
                        <EuiSpacer />
                        <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' responsive={false} wrap>
                            <EuiFlexItem grow={false}>
                                <ContextMenu rowSize={pagination.pageSize} popOverItems={popOverItems} />
                            </EuiFlexItem>

                            <EuiFlexItem grow={false}>
                                {state.data.total > state.data.per_page && (
                                    <Pagination
                                        totalRecord={state.data.total}
                                        pageSize={state.data.per_page}
                                        activePage={state.data.current_page - 1}
                                        handlePageChange={handlePageChange}
                                    />
                                )}
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                ) : null}
            </EuiPanel>
        </>
    );
};

export default React.memo(PastTableSession);
