import React, { useEffect, useState } from 'react';
import {
    EuiOverlayMask,
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
} from '@elastic/eui';
import VariantAddonLinking from './varaintAddonLinking';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../../reduxStore/types/menu';
import { isEmpty } from 'lodash';

const VariantAddonLinkModal = (props) => {
    const { setVaraintAddonLinkModalState, editItem, control, isOutletMenu, addonList } = props;

    const [variantAddonLinkState, setVariantAddonLinkState] = useState({});
    const variantGroup = useSelector((state) => state.menu.variantGroup);
    const variantAddonLink = useSelector((state) => state.menu.variantAddonLink);
    const dispatch = useDispatch();
    const recentlySavedData = useSelector((state) => state.menuReducer.savedVariantGroup);

    useEffect(() => {
        let innerObj = {};
        const afterVariantChanges = editItem?.variants?.[0]?.options?.filter((group) =>
            recentlySavedData?.variantGroup?.some((option) => option.option_id === group.id)
        );

        if (!isEmpty(variantAddonLink)) {
            innerObj = variantAddonLink;
        } else if (afterVariantChanges?.length) {
            afterVariantChanges?.forEach((option) => {
                option.option_id = option.id;
                innerObj[option.id] = option.addons;
            });
        } else if (editItem?.variants?.[0]?.options?.length) {
            editItem.variants[0].options.forEach((option) => {
                option.option_id = option.id;
                innerObj[option.id] = option.addons;
            });
        }
        setVariantAddonLinkState(innerObj);
    }, [editItem, variantAddonLink, recentlySavedData]);

    const closeModal = () => {
        setVaraintAddonLinkModalState(false);
    };

    const varinatOptionsList = () => {
        let optionsArray = [];
        if (variantGroup?.length) {
            variantGroup.forEach((variant) => {
                optionsArray.push(
                    <VariantAddonLinking
                        variantAddonLinkState={variantAddonLinkState}
                        setVariantAddonLinkState={setVariantAddonLinkState}
                        control={control}
                        option={variant}
                        withVariant
                        isOutletMenu={isOutletMenu}
                        addonList={addonList}
                    />
                );
            });
        } else if (editItem?.variants?.[0]?.options?.length) {
            editItem.variants[0].options.forEach((option) => {
                option.option_id = option.id;
                optionsArray.push(
                    <VariantAddonLinking
                        variantAddonLinkState={variantAddonLinkState}
                        setVariantAddonLinkState={setVariantAddonLinkState}
                        control={control}
                        option={option}
                        isOutletMenu={isOutletMenu}
                        addonList={addonList}
                    />
                );
            });
        }

        return optionsArray;
    };

    const onSave = () => {
        dispatch({
            type: types.SET_VARIANT_ADDON_LINK,
            payload: {
                variantAddonLink: variantAddonLinkState,
            },
        });

        closeModal();
    };

    return (
        <EuiOverlayMask>
            <EuiModal style={{ width: 800 }} onClose={closeModal}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Link Addon with Variant</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>{varinatOptionsList()}</EuiModalBody>

                <EuiModalFooter>
                    <EuiButton onClick={closeModal}>Close</EuiButton>
                    {!isOutletMenu ? (
                        <EuiButton onClick={onSave} fill>
                            Save
                        </EuiButton>
                    ) : null}
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    );
};

export default VariantAddonLinkModal;
