import {
    EuiAccordion,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiTitle,
    EuiCard,
    EuiSpacer,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchItemAvailabilityItems } from '../../menuComponentsRefactorCopy/catalogue/Api/ItemAvailability/FetchItemAvailabilityData';
import CategoryChildRow from './CategoryChildRow';
import CategoryItemRow from './CategoryItemRow';
import AvailabilitySwitch from './Components/AvailabilitySwitch';

export const checkCategoryStatus = (category = {}, childCategories = []) => {
    const doesModeExists = (category) => {
        return category.in_stock_item_ordering_mode_count > 0 && category.total_item_ordering_modes > 0 ? true : false;
    };
    const buttonPosition = (category) => {
        return doesModeExists(category) &&
            category.in_stock_item_ordering_mode_count !== category.total_item_ordering_modes
            ? 'partial_state'
            : doesModeExists(category) &&
                category.in_stock_item_ordering_mode_count === category.total_item_ordering_modes
              ? 'turned_on'
              : 'turned_off';
    };

    let status;
    let color;
    if (childCategories?.length) {
        let mainCategoryStatus = [];
        for (const childCategory of childCategories) {
            mainCategoryStatus.push(buttonPosition(childCategory));
        }
        if (mainCategoryStatus?.length) {
            const caetgoryStatus = mainCategoryStatus.every((categoryStatus) => categoryStatus === 'turned_on')
                ? 'turned_on'
                : mainCategoryStatus.every((categoryStatus) => categoryStatus === 'turned_off')
                  ? 'turned_off'
                  : 'partial_state';
            status = caetgoryStatus;
        }
    } else {
        status = buttonPosition(category);
    }

    switch (status) {
        case 'turned_off':
            color = 'danger';
            break;
        case 'turned_on':
            color = 'success';
            break;
        case 'partial_state':
            color = 'warning';
            break;
        default:
            break;
    }
    return { status, color };
};

const CategoryRow = ({ category, handleAvailablityModal, fetchItemAvailabilityData, searchedItems, buttonSize }) => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const categories = useSelector((state) => state.itemAvailabilityReducer.outlet_categories);
    const [listDetails, setListDetails] = useState({
        items: [],
        childCategories: categories.filter((caetgoryItem) => caetgoryItem.parent_id === category.id),
        isLoading: true,
    });

    const handleCategoryClick = useCallback(
        (searchedItems) => {
            if (!listDetails.childCategories.length) {
                fetchItemAvailabilityItems(outletId, category.id).then((response) => {
                    let items = [];
                    setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                    if (response.success) {
                        if (searchedItems?.length) {
                            let mappedItems = [];
                            for (const categoryItem of response.items) {
                                for (const matchedItem of searchedItems) {
                                    if (matchedItem.item_id === categoryItem.id) {
                                        mappedItems.push(categoryItem);
                                    }
                                }
                            }
                            items = [...mappedItems];
                        } else {
                            items = [...response.items];
                        }

                        setListDetails((prevState) => ({
                            ...prevState,
                            items: items,
                            isLoading: false,
                        }));
                    }
                });
            }
        },
        [listDetails, outletId, category]
    );

    useEffect(() => {
        if (searchedItems?.length && !listDetails.items.length) {
            handleCategoryClick(searchedItems);
        }
    }, [searchedItems?.length, handleCategoryClick, listDetails.items.length, searchedItems]);

    const hasChildCategories = useCallback(() => {
        return listDetails.childCategories.length;
    }, [listDetails]);

    const loadingItems = useCallback(() => {
        return listDetails.isLoading && !listDetails.childCategories.length;
    }, [listDetails]);

    const [toggleCompressedIdSelected, setToggleCompressedIdSelected] = useState(
        checkCategoryStatus(category, listDetails.childCategories).status
    );
    const [buttonColor, setButtonColor] = useState(checkCategoryStatus(category, listDetails.childCategories).color);

    useEffect(() => {
        const { color, status } = checkCategoryStatus(category, listDetails.childCategories);
        setToggleCompressedIdSelected(status);
        setButtonColor(color);
    }, [categories, category, listDetails.childCategories]);

    const handleAvailabilitySwitchChange = useCallback(
        (optionId, isCategory, selectedElement) => {
            let callbackData = {
                showModal: true,
            };
            if (!optionId === 'partial_state') setToggleCompressedIdSelected(optionId);
            if (optionId === 'turned_off') {
                setButtonColor('danger');
            } else if (optionId === 'turned_on') {
                setButtonColor('success');
            }

            if (isCategory) {
                callbackData.isCategory = true;
            } else {
                callbackData.isCategory = false;
            }

            if (optionId === 'turned_off' || optionId === 'turned_on') {
                if (isCategory) {
                    callbackData.modalResponse = {
                        data: selectedElement,
                        isActive: optionId === 'turned_off' ? 0 : 1,
                        category_id: category.id,
                    };
                }
            }

            handleAvailablityModal({
                showModal: true,
                modalResponse: callbackData.modalResponse,
                isCategory: isCategory ? isCategory : false,
                clearSwitchStateOnClose: () => {
                    setToggleCompressedIdSelected(checkCategoryStatus(category, listDetails.childCategories).status);
                    setButtonColor(buttonColor);
                },
                fetchUpdateCategories: () => {
                    fetchItemAvailabilityData();
                },
            });
        },
        [handleAvailablityModal, category, listDetails.childCategories, buttonColor, fetchItemAvailabilityData]
    );

    return (
        <>
            <EuiHorizontalRule margin='none' />
            <EuiAccordion
                paddingSize='s'
                initialIsOpen={searchedItems?.length}
                buttonContent={
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiTitle size='s'>
                                <h4>{category.internal_name}</h4>
                            </EuiTitle>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                }
                extraAction={
                    <AvailabilitySwitch
                        buttonColor={buttonColor}
                        handleAvailabilitySwitchChange={handleAvailabilitySwitchChange}
                        toggleCompressedIdSelected={toggleCompressedIdSelected}
                        isCategory={true}
                        selectedElement={category}
                        name={category.id.toString()}
                        buttonSize={buttonSize && buttonSize}
                    />
                }
                id={category.id.toString()}
                onToggle={(isOpen) => {
                    if (isOpen && !listDetails.items.length) {
                        handleCategoryClick();
                    }
                }}
                style={{ padding: '16px 0' }}
            >
                {loadingItems() ? (
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty isLoading={true}>Loading</EuiButtonEmpty>
                    </EuiFlexItem>
                ) : (
                    <>
                        {hasChildCategories() ? (
                            <CategoryChildRow
                                key={category.id}
                                category={category}
                                handleAvailablityModal={handleAvailablityModal}
                                fetchItemAvailabilityData={fetchItemAvailabilityData}
                            />
                        ) : (
                            <>
                                {listDetails.items?.length ? (
                                    <>
                                        {listDetails.items.map((item) => {
                                            return (
                                                <React.Fragment key={item.id.toString()}>
                                                    <EuiSpacer size='xs' />

                                                    <CategoryItemRow
                                                        item={item}
                                                        setListDetails={setListDetails}
                                                        category={category}
                                                        handleAvailablityModal={handleAvailablityModal}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <EuiCard title='0 Items Found' />
                                )}
                            </>
                        )}
                    </>
                )}
            </EuiAccordion>
        </>
    );
};

export default CategoryRow;
