import { isEmpty } from 'lodash';
import { getItem, getTaxAmount } from './utils';

export const itemLinkingResetValue = (
    defaultValue,
    category,
    totalCategoryItems,
    linkedItems,
    petPoojaItems,
    petPoojaTaxes
) => {
    let filterSelectedItem = [];
    let linkedItemList = [];
    defaultValue[`item_${category.category_id}`] = [];

    if (totalCategoryItems?.length) {
        for (const categoryKey of totalCategoryItems) {
            if (categoryKey?.[category.category_id]?.length) {
                for (const [fieldIndex, item] of categoryKey?.[category.category_id].entries()) {
                    defaultValue[`item_${category.category_id}`].push(item);
                    filterSelectedItem = linkedItems.filter((itemData) => {
                        return itemData.module_id == item.item_id && itemData.module_name === 'item';
                    });

                    for (const itemData of linkedItems) {
                        if (itemData.module_id == item.item_id && itemData.module_name === 'item') {
                            linkedItemList.push(itemData);
                        }
                    }
                    for (const [index, orderingMode] of item?.ordering_modes?.entries()) {
                        defaultValue[`item_${category.category_id}`][fieldIndex].ordering_modes[index].selected_item =
                            [];
                        for (const linkedItem of linkedItemList) {
                            if (orderingMode.restaurant_ordering_mode_id === linkedItem.restaurant_ordering_mode_id) {
                                let itemResponse = getItem(linkedItem.third_party_module_id, petPoojaItems);
                                let { taxArrayObj } = getTaxAmount(itemResponse, petPoojaTaxes, item.price);
                                if (
                                    item.item_id == linkedItem.module_id &&
                                    linkedItem.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id
                                ) {
                                    defaultValue[`item_${category.category_id}`][fieldIndex].ordering_modes[
                                        index
                                    ].selected_item = [
                                        {
                                            id: linkedItem?.id,
                                            module_id: linkedItem?.module_id,
                                            itemid: linkedItem.third_party_module_id,
                                            module_name: linkedItem?.module_name,
                                            label: linkedItem.title,
                                            third_party_service_provider: 'pet-pooja',
                                            third_party_module_id: linkedItem.third_party_module_id,
                                            outlet_id: linkedItem?.outlet_id,
                                            title: linkedItem?.title,
                                            uuid: linkedItem?.third_party_module_id?.toString(),
                                            restaurant_ordering_mode_id: orderingMode?.restaurant_ordering_mode_id,
                                            linkedItem: true,
                                            tax: taxArrayObj,
                                            ...linkedItem,
                                        },
                                    ];
                                }
                            }
                            // else {
                            //     defaultValue[`item_${category.category_id}`][fieldIndex].ordering_modes[
                            //         index
                            //     ].selected_item = [];
                            // }
                        }
                    }

                    // RESETTING VARIANTS
                    let filterVariants = linkedItems?.filter((variant) => variant?.module_name === 'variant_item');

                    let filterItemVariation = petPoojaItems?.filter(
                        (item) => item?.itemid === filterSelectedItem?.[0]?.third_party_module_id
                    );

                    if (item?.variants?.length) {
                        for (const [optionIndex, option] of item.variants[0]?.options.entries()) {
                            for (const [modeIndex, orderingMode] of option?.ordering_modes.entries()) {
                                defaultValue[`item_${category.category_id}`][fieldIndex].variants[0].options[
                                    optionIndex
                                ].ordering_modes[modeIndex].selected_item = [];
                                for (const linkedVariant of filterVariants) {
                                    if (
                                        orderingMode.restaurant_ordering_mode_id ===
                                            linkedVariant.restaurant_ordering_mode_id &&
                                        linkedVariant.module_id === option.id
                                    ) {
                                        filterItemVariation[0]?.variation?.forEach((petPoojaVariant) => {
                                            if (petPoojaVariant.id === linkedVariant.third_party_module_id) {
                                                defaultValue[`item_${category.category_id}`][
                                                    fieldIndex
                                                ].variants[0].options[optionIndex].ordering_modes[
                                                    modeIndex
                                                ].selected_item = [
                                                    {
                                                        variant_item_id: petPoojaVariant.id,
                                                        id: petPoojaVariant.variationid,
                                                        label: petPoojaVariant.name,
                                                        module_id: linkedVariant.module_id,
                                                        module_name: linkedVariant.module_name,
                                                        outlet_id: linkedVariant?.outlet_id,
                                                        title: linkedVariant?.title,
                                                        uuid: linkedVariant?.third_party_module_id?.toString(),
                                                        linkedItem: true,
                                                        restaurant_ordering_mode_id:
                                                            orderingMode?.restaurant_ordering_mode_id,
                                                        ...petPoojaVariant,
                                                    },
                                                ];
                                            }
                                        });
                                    }
                                    // else {
                                    //     defaultValue[`item_${category.category_id}`][fieldIndex].variants[0].options[
                                    //         optionIndex
                                    //     ].ordering_modes[modeIndex].selected_item = [];
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return defaultValue;
};

export const variantLinkingResetValue = (defaultValue, addonGroups, linkedItems) => {
    if (addonGroups?.length && linkedItems?.length) {
        for (const addonGroup of addonGroups) {
            defaultValue[`addon_${addonGroup.addonGroupId}`] = [];
            for (const linkedItem of linkedItems) {
                if (
                    addonGroup.addonGroupId === linkedItem.module_id &&
                    linkedItem.module_name === 'restaurant_addon_group'
                ) {
                    // resets group information
                    defaultValue[`addon_${addonGroup.addonGroupId}`][0] = {
                        ...addonGroup,
                        addOnChoices: addonGroup.addOnChoices,
                        selectedGroup: [
                            {
                                addOnChoices: addonGroup.addOnChoices,
                                label: linkedItem.title,
                                petpoojaAddonGroupId: linkedItem.third_party_module_id,
                                third_party_module_id: linkedItem.third_party_module_id,
                                petpoojaAddonGroupName: linkedItem.title,
                                module_id: addonGroup.addonGroupId,
                                linkedItem: true,
                            },
                        ],
                    };
                } else if (isEmpty(defaultValue[`addon_${addonGroup.addonGroupId}`][0])) {
                    defaultValue[`addon_${addonGroup.addonGroupId}`][0] = {
                        ...addonGroup,
                        addOnChoices: addonGroup.addOnChoices,
                    };
                }
            }

            if (addonGroup?.addOnChoices?.length) {
                for (const [choiceIndex, addOnChoice] of addonGroup.addOnChoices.entries()) {
                    if (addOnChoice?.orderingModes?.length) {
                        const matchItem = addOnChoice?.orderingModes?.filter((orderingMode, modeIndex) =>
                            linkedItems.some((linkedItem) => {
                                if (
                                    orderingMode.restaurant_ordering_mode_id ==
                                        linkedItem.restaurant_ordering_mode_id &&
                                    linkedItem.module_name == 'restaurant_addon_groups_choice' &&
                                    addOnChoice.choice_id == linkedItem.module_id
                                ) {
                                    defaultValue[`addon_${addonGroup.addonGroupId}`][0].addOnChoices[
                                        choiceIndex
                                    ].orderingModes[modeIndex] = {
                                        ...orderingMode,
                                        selectedChoice: [
                                            {
                                                active: addOnChoice.selectedPetpoojaAddonChoice.active,
                                                addonitem_name: addOnChoice.name,
                                                choice_id: addOnChoice.choice_id,
                                                module_id: linkedItem.module_id,
                                                module_name: linkedItem.module_name,
                                                third_party_module_id: linkedItem.third_party_module_id,
                                                addonitem_price:
                                                    addOnChoice.selectedPetpoojaAddonChoice.addonitem_price,
                                                price: orderingMode.price,
                                                name: addOnChoice.name,
                                                addonitemid: addOnChoice.selectedPetpoojaAddonChoice.addonitemid,
                                                attributes: addOnChoice.selectedPetpoojaAddonChoice.attributes,
                                                label: linkedItem?.title ? linkedItem?.title : addOnChoice.name,
                                                restaurant_ordering_mode_id: linkedItem.restaurant_ordering_mode_id,
                                                third_party_service_provider: linkedItem.third_party_service_provider,
                                                title: linkedItem.title,
                                                linkedItem: true,
                                            },
                                        ],
                                    };
                                }
                            })
                        );

                        for (const [modeIndex, orderingMode] of addOnChoice?.orderingModes?.entries()) {
                            if (
                                !defaultValue[`addon_${addonGroup.addonGroupId}`][0].addOnChoices[choiceIndex]
                                    .orderingModes?.[modeIndex]?.length
                            ) {
                                defaultValue[`addon_${addonGroup.addonGroupId}`][0].addOnChoices[
                                    choiceIndex
                                ].orderingModes[modeIndex] = {
                                    ...orderingMode,
                                    selectedChoice: [],
                                };
                            }
                        }
                    }
                }
            }
        }
    }

    if (addonGroups?.length && linkedItems?.length) {
        for (const addonGroup of addonGroups) {
            for (const linkedItem of linkedItems) {
                // resetting the addon choices
                if (addonGroup?.addOnChoices?.length) {
                    for (const [choiceIndex, addOnChoice] of addonGroup.addOnChoices.entries()) {
                        if (addOnChoice?.orderingModes?.length) {
                            for (const [modeIndex, orderingMode] of addOnChoice?.orderingModes.entries()) {
                                if (
                                    orderingMode.restaurant_ordering_mode_id ==
                                        linkedItem.restaurant_ordering_mode_id &&
                                    linkedItem.module_name == 'restaurant_addon_groups_choice' &&
                                    addOnChoice.choice_id == linkedItem.module_id
                                ) {
                                    defaultValue[`addon_${addonGroup.addonGroupId}`][0].addOnChoices[
                                        choiceIndex
                                    ].orderingModes[modeIndex] = {
                                        ...orderingMode,
                                        selectedChoice: [
                                            {
                                                active: addOnChoice.selectedPetpoojaAddonChoice.active,
                                                addonitem_name: addOnChoice.name,
                                                choice_id: addOnChoice.choice_id,
                                                module_id: linkedItem.module_id,
                                                module_name: linkedItem.module_name,
                                                third_party_module_id: linkedItem.third_party_module_id,
                                                addonitem_price:
                                                    addOnChoice.selectedPetpoojaAddonChoice.addonitem_price,
                                                price: addOnChoice.price,
                                                name: addOnChoice.name,
                                                addonitemid: addOnChoice.selectedPetpoojaAddonChoice.addonitemid,
                                                attributes: addOnChoice.selectedPetpoojaAddonChoice.attributes,
                                                label: linkedItem?.title ? linkedItem?.title : addOnChoice.name,
                                                restaurant_ordering_mode_id: linkedItem.restaurant_ordering_mode_id,
                                                third_party_service_provider: linkedItem.third_party_service_provider,
                                                title: linkedItem.title,
                                                linkedItem: true,
                                            },
                                        ],
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return defaultValue;
};
