import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTextColor } from '@elastic/eui';
import React from 'react';
import { ReactComponent as TableIcon } from '../../assets/img/svg/LiveOrderTableIcon.svg';
import { useSelector } from 'react-redux';

const OrderRowCustomer = ({ customer, item }) => {
    const themeMode = useSelector((state) => state.theme.theme);
    const type = item?.order_details?.ordering_mode_details?.type
        ? item?.order_details?.ordering_mode_details?.type
        : item?.type?.ordering_mode_details?.type
          ? item?.type?.ordering_mode_details?.type
          : item?.type?.ordering_mode;

    return (
        <>
            <EuiFlexItem>
                <EuiText size='s'>{customer}</EuiText>

                <EuiFlexGroup gutterSize='none' alignItems='center' justifyContent='flexStart'>
                    <EuiFlexItem grow={false}>
                        {type === 'qsr' || type === 'fine_dine' ? (
                            <TableIcon height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />
                        ) : null}
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} style={{ marginLeft: '6px' }}>
                        <EuiText size='xs'>
                            <EuiTextColor color='warning'>
                                {type === 'self_serve' ? 'Self Serve' : item?.order_details?.table?.internal_name}
                            </EuiTextColor>
                        </EuiText>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
        </>
    );
};

export default OrderRowCustomer;
