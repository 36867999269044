import React, { useMemo, useState, memo, useCallback } from 'react';
import {
    EuiText,
    EuiDataGrid,
    EuiLink,
    EuiContextMenuItem,
    EuiPopover,
    EuiPagination,
    EuiContextMenuPanel,
    EuiFlexItem,
    EuiFlexGroup,
    EuiButtonEmpty,
    EuiBadge,
} from '@elastic/eui';
import moment from 'moment';
import { isEmpty } from 'lodash';

const columns = [
    {
        id: 'date',
        display: 'Date',
        displayAsText: 'Date',
        isSortable: true,
    },
    {
        id: 'order_id',
        display: 'Bill Id',
        displayAsText: 'Bill Id',
        isSortable: false,
    },
    {
        id: 'name',
        display: 'Name',
        displayAsText: 'Name',
    },
    {
        id: 'order_value',
        display: 'Total',
        displayAsText: 'Total',
    },
    {
        id: 'points',
        display: 'Cashback',
        displayAsText: 'Cashback',
    },
];

const RewardsGrid = (props) => {
    // const { logsRecord, pagination, setPagination } = props;
    const { logsRecord, activePage, rowSize } = props;

    const orderFlyoutOpen = useCallback(
        (order) => {
            props.flyoutCallback(true);
            props.selectedOrderCallback(order);
        },
        [props]
    );

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    console.log('logsRecord', logsRecord);

    let mappedItems = useMemo(() => {
        return logsRecord?.reward_point_logs?.data?.map((log) => {
            return {
                date: (
                    <>
                        {
                            <EuiText key={log.id + 'date'}>
                                <span> {log.created_at ? moment(log.created_at).format('DD-MM-YYYY') : ''} </span>{' '}
                            </EuiText>
                        }
                    </>
                ),
                order_id: (
                    <>
                        {log?.bill_payment_details?.id ? (
                            <EuiText key={log.id + 'order_id'}>{log.bill_payment_details.id}</EuiText>
                        ) : null}
                    </>
                ),
                name: (
                    <>
                        {
                            <EuiText
                                key={log.id + 'name'}
                                onClick={() => !isEmpty(log.order) && orderFlyoutOpen(log.order)}
                            >
                                {!isEmpty(log.order) ? (
                                    <EuiLink> {!isEmpty(log.customer) ? log.customer.name : ''} </EuiLink>
                                ) : (
                                    <span>{log?.customer?.name}</span>
                                )}
                            </EuiText>
                        }
                    </>
                ),
                order_value: (
                    <>
                        {log?.bill_payment_details?.total ? (
                            <EuiText key={log.id + 'order_id'}>{log.bill_payment_details.total}</EuiText>
                        ) : null}
                    </>
                ),
                points: (
                    <>
                        {
                            <EuiText key={log.id + 'points'}>
                                {' '}
                                {log.type === 'earned' ? (
                                    <EuiBadge color='secondary'>Earned - {log.points}</EuiBadge>
                                ) : (
                                    <EuiBadge color='danger'>Redeme - {log.points}</EuiBadge>
                                )}
                            </EuiText>
                        }
                    </>
                ),
            };
        });
    }, [logsRecord?.reward_point_logs?.data, orderFlyoutOpen]);

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems?.hasOwnProperty(rowIndex) ? mappedItems?.[rowIndex]?.[columnId] : null;
        };
    }, [mappedItems]);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const PAGE_COUNT = !isEmpty(logsRecord) && Math.ceil(logsRecord.reward_point_logs.total / rowSize);

    const onChangeRowSize = useCallback(
        (row_size) => {
            props.activePageCallback(0);
            props.rowSizeCallback(row_size);
        },
        [props]
    );

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    // const goToPage = (pageNumber) => setActivePage(pageNumber);
    const goToPage = (pageNumber) => props.activePageCallback(pageNumber);

    const getIconType = useCallback(
        (size) => {
            return size === rowSize ? 'check' : 'empty';
        },
        [rowSize]
    );

    const button = useMemo(() => {
        return (
            <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
                Rows per page: {rowSize}
            </EuiButtonEmpty>
        );
    }, [rowSize]);

    const items = useMemo(() => {
        return [
            <EuiContextMenuItem
                key='10 rows'
                icon={getIconType(10)}
                onClick={() => {
                    closePopover();
                    onChangeRowSize(10);
                }}
            >
                10 rows
            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key='20 rows'
                icon={getIconType(20)}
                onClick={() => {
                    closePopover();
                    onChangeRowSize(20);
                }}
            >
                20 rows
            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key='50 rows'
                icon={getIconType(50)}
                onClick={() => {
                    closePopover();
                    onChangeRowSize(50);
                }}
            >
                50 rows
            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key='100 rows'
                icon={getIconType(100)}
                onClick={() => {
                    closePopover();
                    onChangeRowSize(100);
                }}
            >
                100 rows
            </EuiContextMenuItem>,
        ];
    }, [getIconType, onChangeRowSize]);

    return (
        <>
            <EuiDataGrid
                aria-label='Customer List Datagrid'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                // inMemory={{ level: 'sorting' }}
                renderCellValue={renderCellValue}
                // sorting={{ columns: sortingColumns, onSort }}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <EuiPopover
                        button={button}
                        isOpen={isPopoverOpen}
                        closePopover={closePopover}
                        panelPaddingSize='none'
                    >
                        <EuiContextMenuPanel items={items} />
                    </EuiPopover>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT}
                        // PAGE_COUNT
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};
export default memo(RewardsGrid);
