import {
    EuiButtonEmpty,
    EuiDraggable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiKeyPadMenuItem,
    EuiLoadingSpinner,
    EuiText,
    htmlIdGenerator,
} from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_TABLE_ID, SET_TABLE_ORDER_DETAILS } from '../../reduxStore/types/tableManagement';
import { fetchTableOrderDetails } from './Api/tableOperations';

const Table = ({ table, isEditTableMode, idx, orderFlyout, setOrderFlyout, setSelectedTable, setShowFlyout }) => {
    let net_total = 0;
    if (table?.current_session?.orders?.length) {
        table.current_session.orders.filter((order) => (net_total = parseInt(order.net_total) + net_total));
    }

    const tableStatusColor = table?.current_session?.kot_running_order_counts
        ? '#E94616'
        : table?.current_session?.running_order_counts > 0 && table?.current_session?.kot_running_order_counts === 0
          ? '#7de2b5'
          : '#FFFFFF';

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleOrderFlyoutState = useCallback(
        (table) => {
            setOrderFlyout((prevState) => !prevState);
            setSelectedTable(table);
            if (!orderFlyout) {
                if (table?.id) {
                    setIsLoading(true);
                    dispatch({
                        type: SET_ACTIVE_TABLE_ID,
                        payload: table.id,
                    });
                    dispatch(fetchTableOrderDetails()).then(() => setIsLoading(false));
                }
            } else {
                dispatch({
                    type: SET_TABLE_ORDER_DETAILS,
                    payload: {},
                });
            }
        },
        [dispatch, orderFlyout, setOrderFlyout, setSelectedTable]
    );

    const handleFlyoutState = useCallback(
        (table) => {
            setShowFlyout((prevState) => !prevState);
            setSelectedTable(table);
        },
        [setSelectedTable, setShowFlyout]
    );

    return (
        <EuiDraggable
            spacing='m'
            key={table.id.toString()}
            index={idx}
            draggableId={table.id.toString()}
            customDragHandle={true}
            hasInteractiveChildren={true}
        >
            {(provided) => (
                <EuiFlexItem
                    {...(isEditTableMode && { ...provided.dragHandleProps })}
                    {...provided.dragHandleProps}
                    grow={false}
                    style={{ minWidth: 100 }}
                    key={idx}
                >
                    <EuiKeyPadMenuItem
                        style={{ border: '1px solid grey', background: tableStatusColor }}
                        onClick={() =>
                            isEditTableMode
                                ? handleFlyoutState(table)
                                : table?.current_session
                                  ? handleOrderFlyoutState(table)
                                  : null
                        }
                        className='keypad-overrider-border'
                        label=''
                        id={htmlIdGenerator()()}
                    >
                        <EuiFlexGroup direction='column' gutterSize='xs' justifyContent='center' alignItems='center'>
                            {isEditTableMode ? (
                                <EuiFlexItem>
                                    <EuiButtonEmpty size='s' color='success' iconType={'pencil'} iconSize='s'>
                                        <EuiText size='xs'>Edit</EuiText>
                                    </EuiButtonEmpty>
                                </EuiFlexItem>
                            ) : null}
                            {isEditTableMode ? (
                                <EuiFlexItem>
                                    <EuiIcon type='grab' />{' '}
                                </EuiFlexItem>
                            ) : null}
                            {isLoading ? (
                                <EuiFlexItem>
                                    <EuiLoadingSpinner size='m' />
                                </EuiFlexItem>
                            ) : null}
                            <EuiFlexItem>
                                <EuiText style={{ wordBreak: 'break-all' }}>{table.internal_name}</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText size='xs'>{net_total ? '₹' + net_total : null}</EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiKeyPadMenuItem>
                </EuiFlexItem>
            )}
        </EuiDraggable>
    );
};

export default React.memo(Table);
