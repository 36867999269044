import {
    EuiBadge,
    EuiButton,
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPopover,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import { uniqWith } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ItemDetailsCollaspibleNav from '../../../modules/Menu/Components/Catalogue/ItemDetails/ItemDetailsCollaspibleNav';
import { SET_BADGES_IDS, UNLINK_BADGES_IDS } from '../Store/MenuTypes';
import Badges from './Badges';
import { ReactComponent as ItemBadgeIcon } from '../../../assets/img/svg/ItemBadgeIcon.svg';

const ItemBadges = () => {
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const dispatch = useDispatch();
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const {
        control,
        setValue,
        formState: { errors },
        watch,
    } = useFormContext({});

    const themeMode = useSelector((state) => state.theme.theme);

    const [isPopoverOpen, setPopover] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const languageId = useSelector((state) => state.language.languageId);
    const restaurantBadges = useSelector((state) => state.menuReducer.restaurantBadges);
    const [selectableBadges, setSelectableBadge] = useState([]);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const closePopover = useCallback(() => {
        setPopover(false);
    }, []);

    useEffect(() => {
        if (restaurantBadges?.length) {
            let options = [];
            for (const badge of restaurantBadges) {
                let comboBoxOption = {};
                comboBoxOption.label = badge?.translations?.display_name[languageId]
                    ? badge.translations.display_name[languageId]
                    : '';
                comboBoxOption.color = badge.color;
                comboBoxOption.id = badge.id;
                comboBoxOption.isLinkned = true;
                options.push(comboBoxOption);
            }
            const filteredBadges = uniqWith(options, 'id');

            setSelectableBadge(filteredBadges);
        }
    }, [languageId, restaurantBadges]);

    useEffect(() => {
        if (editItem?.badges?.length) {
            let badges = [];
            for (let badge of editItem.badges) {
                badge.label = badge?.translations?.display_name?.[languageId]
                    ? badge.translations.display_name[languageId]
                    : '';
                badges.push(badge);
            }
            setSelectedOptions(badges);
        }
    }, [editItem.badges, languageId]);

    const togglePopover = useCallback(() => {
        setPopover(!isPopoverOpen);
    }, [isPopoverOpen]);

    const onChange = useCallback(
        (selectedOption) => {
            let selectedOptionsIds = [];
            for (const element of selectedOption) {
                selectedOptionsIds.push(element.id);
            }
            setSelectedOptions(selectedOption);
            dispatch({
                type: SET_BADGES_IDS,
                payload: selectedOptionsIds,
            });

            // handle unlink

            if (editItem?.badges?.length) {
                let removedItemIds = [];
                const removedBadge = editItem?.badges.filter(
                    (server) => !selectedOption.some((local) => local.id === server.id)
                );

                if (removedBadge?.length) {
                    for (const badge of removedBadge) {
                        removedItemIds.push(badge.id);
                    }
                }

                dispatch({
                    type: UNLINK_BADGES_IDS,
                    payload: removedItemIds,
                });
            }
        },
        [dispatch, editItem.badges]
    );

    const onCreateOption = useCallback(
        () =>
            (searchValue, flattenedOptions = []) => {
                if (!searchValue) {
                    return;
                }

                const normalizedSearchValue = searchValue.trim().toLowerCase();

                if (!normalizedSearchValue) {
                    return;
                }

                const newOption = {
                    label: searchValue,
                };

                // Select the option.
                setSelectedOptions([...selectedOptions, newOption]);
            },
        [selectedOptions]
    );

    const comboBox = useMemo(() => {
        return (
            <>
                <EuiComboBox
                    placeholder='Select or create options'
                    options={selectableBadges}
                    selectedOptions={selectedOptions}
                    onChange={onChange}
                    onCreateOption={onCreateOption}
                    compressed
                    isDisabled={isOutletMenu}
                />
            </>
        );
    }, [isOutletMenu, onChange, onCreateOption, selectableBadges, selectedOptions]);

    const button = useMemo(() => {
        return (
            <EuiButton disabled={isOutletMenu} iconType='arrowDown' iconSide='right' onClick={togglePopover}>
                Add more badge
            </EuiButton>
        );
    }, [togglePopover, isOutletMenu]);

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Badges'
                cardDescription={
                    <>
                        {!selectedOptions?.length ? (
                            <EuiText size='xs'>Create and add badges</EuiText>
                        ) : (
                            <>
                                <EuiSpacer size='xs' />
                                <EuiFlexGroup gutterSize='xs'>
                                    {selectedOptions.map((badge) => {
                                        return (
                                            <EuiFlexItem key={badge.id.toString() + badge.internal_name}>
                                                <EuiBadge color={badge.color}>
                                                    {badge?.internal_name ? badge?.internal_name : badge?.label}
                                                </EuiBadge>
                                            </EuiFlexItem>
                                        );
                                    })}
                                </EuiFlexGroup>
                            </>
                        )}
                    </>
                }
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Item Pricing'
                icon={<ItemBadgeIcon fill={themeMode === 'light' ? 'black' : 'white'} />}
            >
                <EuiSpacer size='xs' />
                <EuiFlexGroup
                    style={{ paddingTop: 8 }}
                    wrap
                    gutterSize='s'
                    justifyContent='flexStart'
                    alignItems='flexStart'
                    direction='column'
                >
                    <EuiFlexItem>
                        <EuiFlexGroup wrap gutterSize='xs'>
                            <EuiFlexItem grow={false}>
                                <EuiPopover
                                    id='popover'
                                    ownFocus
                                    button={button}
                                    isOpen={isPopoverOpen}
                                    closePopover={closePopover}
                                >
                                    <div style={{ width: '300px' }}>{comboBox}</div>
                                </EuiPopover>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <Badges
                            watch={watch}
                            control={control}
                            setValue={setValue}
                            formErrors={errors}
                            isOutletMenu={isOutletMenu}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemBadges);
