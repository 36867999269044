import React, { useState, useEffect } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiButton,
    EuiText,
    EuiTitle,
    EuiForm,
    EuiFormRow,
    EuiFieldPassword,
    EuiButtonEmpty,
} from '@elastic/eui';
import { useForm, Controller } from 'react-hook-form';
import API from '../../api/axios/API';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../Form/TextField';
import MobileNumber from '../Form/MobileNumber';
import { isEmpty } from 'lodash';

const AddUserFlyout = ({ setIsFlyoutVisible, setPermissionUpdate }) => {
    const editUser = useSelector((state) => state.user.editUser);
    const dispatch = useDispatch();
    const defaultValue = {};
    const [loader, setLoader] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        if (editUser) {
            const user = editUser.user;
            setSelectedUser(user);
            defaultValue.name = user.name;
            defaultValue.email = user.email;
            defaultValue.mobile_number = user.country_code
                ? `${user.country_code}${user.mobile_number}`
                : user.mobile_number;
            if (user.mobile_number) {
                let country_code = user.country_code ? user.country_code.slice(1, user.country_code.length) : '91';
                defaultValue.mobile_number = `${country_code} ${user.mobile_number}`;
                defaultValue.country_code = user.country_code;
            }
            reset({
                ...defaultValue,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editUser]);
    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: { ...defaultValue },
    });

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const onSubmit = async (data, e) => {
        if (editUser) {
            onEditUser(data, e);
        } else {
            onAdduser(data, e);
        }
    };
    const onAdduser = async (data, e) => {
        setLoader(true);
        try {
            const user = await API.post(`restaurants/:restaurantId/users`, {
                name: data.name,
                email: data.email,
                password: data.password,
                password_confirmation: data.confirm_password,
                mobile_number: data.selectedCountryNumber ? data.selectedCountryNumber.number : data.mobile_number,
                country_code: data.selectedCountryNumber ? `+${data.selectedCountryNumber.dialCode}` : '+91',
            });

            if (user.success === true) {
                setSuccessMessage(user.message);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
                reset();
                setPermissionUpdate((prevState) => !prevState);
            }
            setIsFlyoutVisible(false);
        } catch (e) {
            console.log('inside add user catch', data);
            setErrorMessage(e.errors.email || e.errors.mobile_number);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
        setLoader(false);
    };

    const onEditUser = async (data, e) => {
        setLoader(true);
        try {
            const user = await API.put(`restaurants/:restaurantId/users/${editUser.user.id}`, {
                name: data.name,
                email: data.email,
                password: data.password,
                password_confirmation: data.confirm_password,
                // mobile_number: data.mobile_number,
                country_code: !isEmpty(data.selectedCountryNumber)
                    ? `+${data.selectedCountryNumber.dialCode}`
                    : !isEmpty(selectedUser) && selectedUser.country_code
                      ? selectedUser.country_code
                      : '+91',
                mobile_number: !isEmpty(data.selectedCountryNumber)
                    ? data.selectedCountryNumber.number
                    : !isEmpty(selectedUser) && selectedUser.mobile_number
                      ? selectedUser.mobile_number
                      : '',
            });
            if (user.success === true) {
                handleCloseFlyout();
                setSuccessMessage(user.message);
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);
                reset();
                setPermissionUpdate((prevState) => !prevState);
            }
        } catch (e) {
            setErrorMessage(e.errors.email || e.errors.mobile_number);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000);
        }
        setLoader(false);
    };

    const handleCloseFlyout = () => {
        dispatch({
            type: 'edit_user',
            payload: {
                editUser: null,
            },
        });
        setIsFlyoutVisible(false);
    };

    if (editUser && !watch('confirm_password') && !watch('password')) {
        delete errors['confirm_password'];
        delete errors['password'];
    }

    return (
        <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
            <EuiFlyout ownFocus onClose={() => handleCloseFlyout()} aria-labelledby='flyoutTitle'>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>{editUser ? <h2> Edit User</h2> : <h2>Add User</h2>}</EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    {error && (
                        <EuiText>
                            <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>
                        </EuiText>
                    )}
                    {success && (
                        <EuiText>
                            <p style={{ color: 'green', textAlign: 'center' }}>{successMessage}</p>
                        </EuiText>
                    )}
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <TextField
                                name={'name'}
                                label='name'
                                isInvalid={errors.name}
                                errors={errors}
                                rules={{
                                    required: 'Please enter name',
                                    minLength: {
                                        value: 3,
                                        message: 'minimum length should be 3',
                                    },
                                }}
                                control={control}
                                autoComplete={'username'}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <TextField
                                name='email'
                                label='email'
                                isInvalid={errors.email}
                                errors={errors}
                                rules={{
                                    required: 'Please Enter your email',
                                    minLength: {
                                        value: 3,
                                        message: 'minimum length should be 3',
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: 'Please enter valid email ',
                                    },
                                }}
                                control={control}
                                autoComplete={'username'}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFormRow
                                label='password'
                                isInvalid={errors.password}
                                error={errors.password ? errors.password.message : ''}
                            >
                                <Controller
                                    render={({ field }) => (
                                        <EuiFieldPassword
                                            value={field.value}
                                            onChange={field.onChange}
                                            autoComplete={'new-password'}
                                        />
                                    )}
                                    // as={EuiFieldPassword}
                                    name='password'
                                    control={control}
                                    rules={
                                        editUser !== null && !watch('password') && !watch('confirm_password')
                                            ? {}
                                            : {
                                                  required: 'Please Enter your password',
                                                  minLength: {
                                                      value: 6,
                                                      message: 'minimum length should be 6',
                                                  },
                                              }
                                    }
                                    isInvalid={errors.password}
                                    // defaultValue=''
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow
                                label='confirm password'
                                isInvalid={errors.confirm_password}
                                error={errors.confirm_password ? errors.confirm_password.message : ''}
                            >
                                <Controller
                                    render={({ field }) => (
                                        <EuiFieldPassword
                                            value={field.value}
                                            onChange={field.onChange}
                                            autoComplete={'new-password'}
                                        />
                                    )}
                                    // as={EuiFieldPassword}
                                    name='confirm_password'
                                    control={control}
                                    defaultValue=''
                                    rules={
                                        editUser !== null && !watch('password') && !watch('confirm_password')
                                            ? {}
                                            : {
                                                  required: 'Please Enter confirm password',
                                                  validate: (value) =>
                                                      value === watch('password') || "Passwords don't match.",
                                              }
                                    }
                                    isInvalid={errors.confirm_password}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <MobileNumber
                                watch={watch}
                                setValue={setValue}
                                label={'Mobile number'}
                                isInvalid={errors['mobile_number']}
                                errors={errors}
                                placeholder='Enter Customer number'
                                name={'mobile_number'}
                                control={control}
                                rules={true}
                            />
                            {/* <NumberField 
                                label='mobile number'
                                isInvalid={errors.mobile_number}
                                errors={errors}
                                name='mobile_number'
                                control={control}
                                rules={{
                                    required: 'Please Enter your mobile number',
                                    minLength: {
                                        value: 10,
                                        message: ' length should be 10',
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: ' length should be 10',
                                    },
                                }}
                            /> */}
                        </EuiFlexItem>
                        <EuiFlexItem></EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty iconType='cross' onClick={() => handleCloseFlyout()} flush='left'>
                                Close
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                onClick={handleSubmit(onSubmit)}
                                isLoading={loader}
                                type='submit'
                                fill
                                style={{ margin: '10px 0px' }}
                            >
                                Save user
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </EuiForm>
    );
};

export default AddUserFlyout;
