import { EuiButton, EuiCard, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui';
import React, { useCallback, useState } from 'react';

const RefundBillStatus = ({ refunds, handleRefund }) => {
    const [refundLoader, setRefundLoader] = useState(false);

    const retryRefund = useCallback(async () => {
        setRefundLoader(true);
        await handleRefund().then(() => {
            setRefundLoader(false);
        });
    }, [handleRefund]);

    const RefundStatus = () => {
        let status = {
            init: 0,
            fail: 0,
            success: 0,
        };

        refunds.forEach((data) => {
            if (data.failed_at) status.fail++;
            if (data.initiated_at) status.init++;
            if (data.refunded_at) status.success++;
        });

        if (status.fail) {
            return (
                <>
                    <EuiCard
                        display='plain'
                        layout='horizontal'
                        paddingSize='s'
                        style={{ backgroundColor: '#ff00002e' }}
                        title={
                            <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                <EuiIcon color='rgb(189, 39, 30)' type='alert' />
                                <div style={{ marginLeft: '10px', color: 'rgb(189, 39, 30)' }}>
                                    <span>Refund Failed</span>
                                </div>
                            </EuiFlexItem>
                        }
                        description={
                            <>
                                <EuiText size='s'>
                                    Refund process couldn't able to complete. Please retry refund.
                                </EuiText>
                                <EuiSpacer size='s' />
                                <EuiFlexItem>
                                    <EuiButton fill size='s' onClick={retryRefund} isLoading={refundLoader}>
                                        Retry
                                    </EuiButton>
                                </EuiFlexItem>
                            </>
                        }
                    />
                </>
            );
        }

        if (status.success) {
            return (
                <>
                    <EuiCard
                        display='plain'
                        layout='horizontal'
                        paddingSize='s'
                        style={{ backgroundColor: '#74decef2' }}
                        title={
                            <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                <EuiIcon color='#347e73' type='alert' />
                                <div style={{ marginLeft: '10px', color: '#347e73' }}>
                                    <span>Refund Succeeded</span>
                                </div>
                            </EuiFlexItem>
                        }
                        description='Refund process completed successfully.'
                    />
                    <EuiSpacer />
                </>
            );
        }

        return (
            <>
                <EuiCard
                    display='plain'
                    layout='horizontal'
                    paddingSize='s'
                    style={{ backgroundColor: '#F5A700' }}
                    title={
                        <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                            <EuiIcon color='#FFFFF0' type='alert' />
                            <div style={{ marginLeft: '10px', color: '#FFFFF0' }}>
                                <span>Refund Inititated</span>
                            </div>
                        </EuiFlexItem>
                    }
                    description='Refund process inititated successfully.'
                />
                <EuiSpacer />
            </>
        );
    };
    return (
        <React.Fragment>
            <RefundStatus />
        </React.Fragment>
    );
};

export default React.memo(RefundBillStatus);
