import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPanel, EuiStat } from '@elastic/eui';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import RewardsGrid from './rewardsGrid';
import { OrderFlyout } from '../../components/orderRating/orderFlyout';
import { getRewardPointsList } from '../../api/rewardPoints/getRewardPointsList';
import { isEmpty } from 'lodash';
import { numberFormat } from '../../utils/numberFormat';
import { NoOutletMessage } from '../../utils/noOutletMessage';
import { useMemo } from 'react';
import CustomDatePicker from '../UIComponent/CustomDatePicker';

const RewardsComponent = () => {
    const [logsRecords, setLogsRecords] = useState({});
    const [loading, setLoading] = useState(true);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);
    const [startDate1, setStartDate1] = useState(moment());
    const [endDate1, setEndDate1] = useState(moment());
    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});
    const dispatch = useDispatch();

    const flyoutCallback = useCallback((value) => {
        setFlyoutOpen(value);
    }, []);

    const selectedOrderCallback = useCallback((value) => {
        setSelectedOrder(value);
    }, []);

    const activePageCallback = useCallback((value) => {
        setActivePage(value);
    }, []);

    const rowSizeCallback = useCallback((value) => {
        setRowSize(value);
    }, []);

    const fetchUniversalOutletDetails = useCallback(
        async (startDate, endDate) => {
            if (startDate && endDate) {
                setLoading(true);
                dispatch(getRewardPointsList(selectedOutlet, activePage + 1, rowSize, startDate1, endDate1)).then(
                    (res) => {
                        if (res.success) {
                            setLogsRecords(res);
                        } else {
                            alert('Error in fetching list');
                        }
                        setLoading(false);
                    }
                );
            }
        },
        [activePage, dispatch, endDate1, rowSize, selectedOutlet, startDate1]
    );

    useEffect(() => {
        if (selectedOutlet) {
            fetchUniversalOutletDetails(startDate1, endDate1);
        }
    }, [startDate1, endDate1, fetchUniversalOutletDetails, activePage, rowSize, selectedOutlet]);

    let modal = useMemo(() => {
        return <OrderFlyout setSelectedOrder={setSelectedOrder} setFlyoutOpen={setFlyoutOpen} order={selectedOrder} />;
    }, [selectedOrder]);

    if (!selectedOutlet) {
        return <NoOutletMessage />;
    }

    return (
        <>
            <EuiFlexGroup direction='column'>
                {flyoutOpen && modal}
                <EuiFlexItem style={{ padding: 2 }}>
                    <CustomDatePicker
                        startDate={startDate1}
                        setStartDate={setStartDate1}
                        endDate={endDate1}
                        setEndDate={setEndDate1}
                        displayFormat='DD-MM-YYYY'
                    />
                </EuiFlexItem>

                {!loading ? (
                    <>
                        <EuiFlexItem style={{ flexDirection: 'row', width: '100%' }}>
                            <div style={{ width: '20%' }}>
                                <EuiPanel>
                                    <EuiStat
                                        title={
                                            !isEmpty(logsRecords)
                                                ? numberFormat(logsRecords.total_redeemed_points_amount)
                                                : ''
                                        }
                                        description={'Cashback'}
                                        textAlign='right'
                                        isLoading={isEmpty(logsRecords)}
                                    />
                                </EuiPanel>
                            </div>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <RewardsGrid
                                logsRecord={logsRecords}
                                activePage={activePage}
                                activePageCallback={activePageCallback}
                                rowSize={rowSize}
                                rowSizeCallback={rowSizeCallback}
                                flyoutCallback={flyoutCallback}
                                selectedOrderCallback={selectedOrderCallback}
                            />
                        </EuiFlexItem>{' '}
                    </>
                ) : (
                    <EuiLoadingContent lines={4} />
                )}
            </EuiFlexGroup>
        </>
    );
};

export default RewardsComponent;
