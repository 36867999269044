import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import OrdersFlyout from '../../components/TableManagement/OrdersFlyout';
import { SET_SELECTED_ORDER } from '../../reduxStore/types/orders';
import { SET_TABLE_ORDER_DETAILS } from '../../reduxStore/types/tableManagement';

const SessionOrdersFlyout = ({ setShowSessionOrders, selectedTable }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return function cleanup() {
            dispatch({
                type: SET_SELECTED_ORDER,
                payload: null,
            });
            dispatch({
                type: SET_TABLE_ORDER_DETAILS,
                payload: {},
            });
        };
    }, [dispatch]);

    return (
        <OrdersFlyout
            closeFlyout={() => setShowSessionOrders(false)}
            selectedTable={selectedTable}
            isPastTableSession={true}
        />
    );
};

export default SessionOrdersFlyout;
