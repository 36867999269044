import {
    EuiCard,
    EuiComboBox,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSuperSelect,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FieldArrayNumberField from '../../Form/FieldArrayNumberField';
import TextField from '../../Form/TextField';
import VariantOrderingModePricing from './VariantOrderingModePricing';

export const gstOptions = [
    { value: '0.00', text: '0%' },
    { value: '5.00', text: '5%' },
    { value: '12.00', text: '12%' },
    { value: '18.00', text: '18%' },
    { value: '28.00', text: '28%' },
];

const VariantPricing = ({ control, errors, index, fieldVariant, watch, setValue }) => {
    const allergensOptions = useSelector((state) => state.menuReducer.allergens)?.filter(
        (allergen) => (allergen.label = allergen.internal_name)
    );

    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const [activeOrderingModes, setActiveOrderingModes] = useState([]);

    const itemOrderingModes = useWatch({
        control,
        name: `variantGroup[${index}].ordering_modes`,
    });

    const onChangeGST = useCallback(
        (value, name) => {
            setValue(name, value);
        },
        [setValue]
    );

    const quantityUnits = useSelector((state) => state.menuReducer.quantityUnits);
    const [quantityIdOptions, setQuantityIdOptions] = useState([]);

    useEffect(() => {
        if (orderingModes?.length) {
            const activeOrderingModes = orderingModes.filter((orderingMode) =>
                itemOrderingModes.some(
                    (itemMode) => itemMode.restaurant_ordering_mode_id === orderingMode.id && orderingMode.status
                )
            );

            setActiveOrderingModes(activeOrderingModes);
        }
    }, [itemOrderingModes, orderingModes]);

    useEffect(() => {
        if (quantityUnits?.length) {
            let units = [];
            for (const element of quantityUnits) {
                units.push({
                    value: element.id,
                    inputDisplay: element.unit,
                });
            }
            setQuantityIdOptions(units);
        }
    }, [quantityUnits]);

    const handleAllergensDeletion = (options) => {
        if (fieldVariant?.ordering_modes?.[0]?.allergens?.length) {
            const removedAllergens = fieldVariant.ordering_modes[0]?.allergens.filter(
                (allergen) => !options.some((option) => option.id === allergen.id)
            );
            for (
                let orderingModeIndex = 0;
                orderingModeIndex < fieldVariant.ordering_modes.length;
                orderingModeIndex++
            ) {
                setValue(
                    `variantGroup[${index}].ordering_modes[${orderingModeIndex}].delete_allergens`,
                    removedAllergens
                );
            }
        }
    };

    return (
        <EuiFlexItem>
            {watch(`variantGroup[${index}].is_enabled_same_price_details_for_all_ordering_modes`) ? (
                <>
                    {orderingModes?.length ? (
                        <>
                            {itemOrderingModes.map((itemOrderingMode, idx) => {
                                return orderingModes.map((orderingMode) => {
                                    return (
                                        <React.Fragment key={orderingMode.id.toString()}>
                                            {itemOrderingMode.restaurant_ordering_mode_id === orderingMode.id &&
                                            orderingMode.status ? (
                                                <VariantOrderingModePricing
                                                    orderingModeIdx={idx}
                                                    index={index}
                                                    orderingMode={itemOrderingMode}
                                                    watch={watch}
                                                    control={control}
                                                    setValue={setValue}
                                                    errors={errors}
                                                    quantityIdOptions={quantityIdOptions}
                                                    key={index.toString() + idx.toString()}
                                                    fieldVariant={fieldVariant}
                                                />
                                            ) : null}
                                        </React.Fragment>
                                    );
                                });
                            })}
                        </>
                    ) : (
                        <EuiCard title='Ordering Modes Not Found' />
                    )}
                </>
            ) : (
                <EuiFlexGroup direction='row' wrap responsive gutterSize='s'>
                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <FieldArrayNumberField
                            error={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`price`]
                                    ? errors.variantGroup[index][`price`].message
                                    : ''
                            }
                            isInvalid={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`price`]
                                    ? errors.variantGroup[index][`price`]
                                    : ''
                            }
                            label={'Price'}
                            name={`variantGroup[${index}].price`}
                            placeholder='price'
                            rules={{
                                required: 'Please enter price',
                            }}
                            control={control}
                            watch={watch}
                            defaultValue={fieldVariant[`variantGroup[${index}].price`]}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <FieldArrayNumberField
                            error={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`compare_price`]
                                    ? errors.variantGroup[index][`compare_price`].message
                                    : ''
                            }
                            isInvalid={
                                errors.variantGroup &&
                                errors.variantGroup.hasOwnProperty(index) &&
                                errors.variantGroup[index][`compare_price`]
                                    ? errors.variantGroup[index][`compare_price`]
                                    : ''
                            }
                            label={'Compare price'}
                            name={`variantGroup[${index}].compare_price`}
                            placeholder='Compare price'
                            rules={{
                                required: 'Please enter compare price',
                            }}
                            control={control}
                            watch={watch}
                            defaultValue={fieldVariant[`variantGroup[${index}].compare_price`]}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <FieldArrayNumberField
                            error={
                                errors?.variantGroup?.hasOwnProperty(index) &&
                                errors.variantGroup[index][`packaging_charge`]
                                    ? errors.variantGroup[index][`packaging_charge`].message
                                    : ''
                            }
                            isInvalid={
                                errors?.variantGroup?.hasOwnProperty(index) &&
                                errors.variantGroup[index][`packaging_charge`]
                                    ? errors.variantGroup[index][`packaging_charge`]
                                    : ''
                            }
                            label={'Packaging price'}
                            name={`variantGroup[${index}].packaging_charge`}
                            placeholder='Compare price'
                            rules={{
                                required: 'Please enter packaging price',
                            }}
                            control={control}
                            watch={watch}
                            defaultValue={fieldVariant[`variantGroup[${index}].packaging_charge`]}
                        />
                    </EuiFlexItem>

                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <EuiFormRow label='GST' style={{ maxWidth: '150px' }}>
                            <Controller
                                render={() => (
                                    <EuiSelect
                                        options={gstOptions}
                                        value={watch(`variantGroup[${index}].gst_slab`)}
                                        onChange={(e) => onChangeGST(e.target.value, `variantGroup[${index}].gst_slab`)}
                                    />
                                )}
                                name={`variantGroup[${index}].gst_slab`}
                                control={control}
                                defaultValue={
                                    fieldVariant[`variantGroup[${index}].gst_slab`]
                                        ? fieldVariant[`variantGroup[${index}].gst_slab`]
                                        : '0'
                                }
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <TextField
                            label={`Quantity`}
                            name={`variantGroup[${index}].quantity`}
                            control={control}
                            defaultValue={watch('quantity')}
                            errors={errors}
                            placeholder='Item Quantity'
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <EuiFormRow
                            label={`Quantity Unit Id`}
                            style={{
                                minWidth: 150,
                            }}
                        >
                            <Controller
                                render={({ field }) => {
                                    return (
                                        <EuiSuperSelect
                                            options={quantityIdOptions}
                                            valueOfSelected={field.value}
                                            onChange={field.onChange}
                                        />
                                    );
                                }}
                                name={`variantGroup[${index}].quantity_unit_id`}
                                min={0}
                                step={'any'}
                                control={control}
                                defaultValue={watch('quantity_unit_id')}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: 150 }}>
                        <TextField
                            label={`Serves`}
                            name={`variantGroup[${index}].serves`}
                            control={control}
                            defaultValue={watch('serves')}
                            errors={errors}
                            placeholder='Item Serves'
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <TextField
                            label={`Serves Unit`}
                            name={`variantGroup[${index}].serves_unit`}
                            control={control}
                            defaultValue={watch('serves_unit')}
                            errors={errors}
                            placeholder='Item Serves Unit'
                            style={{
                                minWidth: 150,
                            }}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            style={{
                                minWidth: 150,
                            }}
                            label={`Calories`}
                        >
                            <Controller
                                render={({ field }) => (
                                    <EuiFieldNumber
                                        value={field.value}
                                        onChange={field.onChange}
                                        placeholder='Eg. 450 Kcal'
                                        append={'Kcal'}
                                    />
                                )}
                                name={`variantGroup[${index}].calories`}
                                control={control}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem
                        style={{
                            minWidth: 220,
                        }}
                    >
                        <Controller
                            render={({ field }) => {
                                return (
                                    <EuiFormRow label={`Allergens`}>
                                        <EuiComboBox
                                            options={allergensOptions}
                                            selectedOptions={field?.value}
                                            onChange={(options) => {
                                                handleAllergensDeletion(options);
                                                field.onChange(options);
                                            }}
                                            placeholder='Select allergens'
                                        />
                                    </EuiFormRow>
                                );
                            }}
                            name={`variantGroup[${index}].allergens`}
                            control={control}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </EuiFlexItem>
    );
};

export default VariantPricing;
