import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { EuiLoadingContent, EuiPanel, EuiSpacer, htmlIdGenerator } from '@elastic/eui';
import BasicDetails from './BasicDetails';
import Configure from './Configure';
import Clubbing from './Clubbing';
import VisibilityAndLinking from './VisibilityAndLinking';
import ValidityAndAcceptibility from './ValidityAndAcceptibility';
import OfferTiming from './OfferTiming';
import { useHistory } from 'react-router-dom';
import ApplyOrderingModes from './ApplyOrderingModes';
import {
    resetBasicDetails,
    resetClubbingDetails,
    resetConfigureDetails,
    resetCoupledOfferCheck,
    resetOfferApply,
    resetOfferCreationForm,
    resetOfferTimingDetails,
    resetValidityApplicability,
    resetVisibilityLinkingDetails,
} from '../Utils/formResetUtilities';
import { getItemOrVariantName, timeSlotDataOffers as timeSlotData } from '../Utils/utils';
import OfferValidationError from './OfferValidationError';
import OfferSection from './OfferSection';
import { BOGO_WITH_VIEW, COUPLED_OFFER, FREE_DELIVERY, MENU_DISCOUNT } from '../OfferTypes/OfferTypes';
import API from '../../../../api/axios/API';
import TermsAndConditions from './TermsAndConditions';
import CoupledOffer from '../../../../components/offerModification/coupledOffer';
import {
    fetchCategoryItemsForCoupledOffer,
    fetchRestaurantCatalogue,
} from '../../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../utils/toasts';
import useFormActionsHandler from '../../../../hooks/useFormActionsHandler';

const OfferCreateEditForm = ({ offer, selectedOfferType }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const languages = useSelector((state) => state.language.availableLanguages);
    const [categories, setCategories] = useState([]);
    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);

    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(true);
    const [dummyState, setDummyState] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [submitError, setSubmitError] = useState(false);

    useEffect(() => {
        if (!offer?.id || selectedOfferType !== COUPLED_OFFER) {
            setLoading(false);
        }
    }, [selectedOfferType, offer?.id]);

    const getItemsOfSelectedCategory = useCallback(
        async (id) => {
            let items = [];
            let categoryData = {};
            if (categories?.length) {
                var results = Promise.all(
                    categories.map(async (category) => {
                        if (category?.category_id === id) {
                            await fetchCategoryItemsForCoupledOffer(category.category_id, languageId)
                                .then((response) => {
                                    items = [...response.items];
                                    categoryData = {
                                        id: category?.category_id,
                                        label: category?.internal_name,
                                        isSubCategory: category.parent_id ? true : false,
                                        data: category,
                                        items: response.items,
                                        foo: true,
                                    };
                                    setLoading(false);
                                })
                                .catch((errr) => {
                                    setLoading(false);
                                });
                        }
                    })
                );
                await results;
            }

            return { items, categoryData };
        },
        [categories, languageId]
    );

    useEffect(() => {
        if (selectedOfferType === COUPLED_OFFER) {
            fetchRestaurantCatalogue(languageId)
                .then((response) => {
                    if (response.success) {
                        setCategories(response.categories);
                    }
                })
                .catch((err) => {});
        }
    }, [selectedOfferType, languageId, dispatch]);

    const coupledOffer = useCallback(
        (defaultValue, offerDetails) => {
            return new Promise(async (resolve, reject) => {
                setLoading(true);
                let id;
                if (offerDetails?.linked_categories?.length) {
                    id = offerDetails.linked_categories[0].primary_category_id;
                }

                if (id) {
                    let { items, categoryData } = await getItemsOfSelectedCategory(id);
                    defaultValue.primary_category_id = [categoryData];

                    let innerAray = [];
                    offerDetails.linked_categories.forEach((item) => {
                        let name = getItemOrVariantName(item, items, languageId);
                        innerAray.push({ ...item, name });
                    });
                    defaultValue['items'] = innerAray;
                    resolve(defaultValue);
                }
            });
        },
        [getItemsOfSelectedCategory, languageId]
    );

    const updateFormValues = useCallback(
        async (data) => {
            const defaultValue = {};
            if (data?.id) {
                if (data.offer_type === COUPLED_OFFER) {
                    setLoading(true);
                }
                defaultValue.offer_type = data.offer_type;

                resetBasicDetails(defaultValue, data);

                await resetCoupledOfferCheck(defaultValue, selectedOfferType, data, coupledOffer);

                resetConfigureDetails(defaultValue, selectedOfferType, data, languageId);

                resetOfferApply(defaultValue, data);

                resetVisibilityLinkingDetails(defaultValue, data);

                resetClubbingDetails(defaultValue, data);

                resetValidityApplicability(defaultValue, data, moment);

                resetOfferTimingDetails(defaultValue, data);
                setDummyState(htmlIdGenerator()());

                return {
                    ...defaultValue,
                    time: defaultValue.time ? defaultValue.time : [],
                    week: defaultValue.week
                        ? defaultValue.week
                        : {
                              sunday: [],
                              monday: [],
                              tuesday: [],
                              wednesday: [],
                              thursday: [],
                              friday: [],
                              saturday: [],
                          },
                };
            } else {
                if (selectedOfferType) {
                    resetOfferCreationForm(defaultValue, selectedOfferType, languages, restaurantOrderingModes);

                    return { ...defaultValue };
                }
            }
        },
        [categories?.length, coupledOffer, languageId, languages, restaurantOrderingModes, selectedOfferType]
    );

    async function resetOfferData(offer) {
        const value = await updateFormValues(offer);
        return value;
    }

    useEffect(() => {
        if (offer) {
            if (offer.offer_type === COUPLED_OFFER) {
                if (categories?.length) {
                    resetOfferData(offer).then((response) => {
                        setDefaultValues(response);
                    });
                }
            } else {
                resetOfferData(offer).then((response) => {
                    setDefaultValues(response);
                });
            }
        }
    }, [offer, categories]);

    const handleUpdateOfferAction = useCallback(
        async (data) => {
            let response = null;
            try {
                response = await API.put(`restaurants/:restaurantId/offers/${data.id}`, data);
                if (response.success) {
                    setErrorMessage('');
                    setSubmitError(false);
                    history.goBack();
                    toastSuccessMessage('Offer successfully updated.', dispatch);
                } else {
                    setSubmitError(true);
                    setErrorMessage(response.errors);
                }
            } catch (e) {
                response = e;
                if (e?.errors?.coupon_code) {
                    dispatchErrorMessage(e.errors?.coupon_code);
                }
                setErrorMessage(e.errors);
                setSubmitError(true);
            }

            return response;
        },
        [dispatch, history]
    );

    const dispatchErrorMessage = (property) => {
        toastsErrorMessage(property, dispatch);
    };

    const handleOfferCreationAction = useCallback(
        async (data) => {
            let response = null;
            try {
                response = await API.post(`restaurants/:restaurantId/offers`, data);
                if (response.success) {
                    toastSuccessMessage('Offer successfully added', dispatch);
                    history.goBack();
                    setErrorMessage('');
                    setSubmitError(false);
                } else {
                    setSubmitError(true);
                    setErrorMessage(response.errors);
                }
            } catch (e) {
                response = e;
                setErrorMessage(e.errors);
                setSubmitError(true);

                if (e?.errors?.coupon_code) {
                    dispatchErrorMessage(e.errors?.coupon_code);
                } else if (e?.errors?.combo_deal_item_index) {
                    dispatchErrorMessage(e?.errors?.combo_deal_item_index);
                } else if (e?.errors?.ordering_modes) {
                    dispatchErrorMessage(e?.errors?.ordering_modes);
                } else if (e?.errors?.offer_type) {
                    dispatchErrorMessage(e?.errors?.offer_type);
                } else if (e?.errors?.coupled_offer) {
                    dispatchErrorMessage(e?.errors?.coupled_offer);
                } else if (e?.errors?.min_cart_item) {
                    dispatchErrorMessage(e?.errors?.min_cart_item);
                } else if (e?.errors?.max_discount_amount) {
                    dispatchErrorMessage(e?.errors?.max_discount_amount);
                } else if (e?.errors?.discount_amount) {
                    dispatchErrorMessage(e?.errors?.discount_amount);
                } else if (e?.errors?.percentage) {
                    dispatchErrorMessage(e?.errors?.percentage);
                } else if (e?.errors?.min_cart_amount) {
                    dispatchErrorMessage(e?.errors?.min_cart_amount);
                } else if (e?.errors?.max_usage_count) {
                    dispatchErrorMessage(e?.errors?.max_usage_count);
                } else if (e?.errors?.max_usage_count_per_user) {
                    dispatchErrorMessage(e?.errors?.max_usage_count_per_user);
                } else if (e?.errors?.min_cart_item_quantity) {
                    dispatchErrorMessage(e?.errors?.min_cart_item_quantity);
                } else if (e?.errors?.quantity_of_free_item) {
                    dispatchErrorMessage(e?.errors?.quantity_of_free_item);
                } else if (e?.errors?.allow_offer_clubbing) {
                    dispatchErrorMessage(e?.errors?.allow_offer_clubbing);
                } else if (e?.errors?.auto_apply) {
                } else if (e?.errors?.club_membership_consolidation_with_offer) {
                    dispatchErrorMessage(e?.errors?.club_membership_consolidation_with_offer);
                } else if (e?.errors?.auto_apply) {
                    dispatchErrorMessage(e?.errors?.auto_apply);
                } else if (e?.errors?.free_items) {
                    dispatchErrorMessage(e?.errors?.free_items);
                } else if (e?.errors?.required_items) {
                    dispatchErrorMessage(e?.errors?.required_items);
                } else if (e?.errors?.customer_ids) {
                    dispatchErrorMessage(e?.errors?.customer_ids);
                } else if (e?.errors?.categories) {
                    dispatchErrorMessage(e?.errors?.categories);
                } else if (e?.errors?.items) {
                    dispatchErrorMessage(e?.errors?.items);
                } else if (e?.errors?.outlet_id) {
                    dispatchErrorMessage(e?.errors?.outlet_id);
                } else if (e?.errors?.display_in_offers_tab) {
                    dispatchErrorMessage(e?.errors?.display_in_offers_tab);
                } else if (e?.errors?.display_in_cart_tab) {
                    dispatchErrorMessage(e?.errors?.display_in_cart_tab);
                } else if (e?.errors?.translations) {
                    dispatchErrorMessage(e?.errors?.translations);
                } else if (e?.errors?.linked_categories) {
                    dispatchErrorMessage(e?.errors?.linked_categories);
                } else if (e?.errors?.linked_categories?.module_id) {
                    dispatchErrorMessage(e?.errors?.linked_categories?.module_id);
                } else if (e?.errors?.linked_categories?.module_name) {
                    dispatchErrorMessage(e?.errors?.linked_categories?.module_name);
                } else if (e?.errors?.linked_categories?.secondary_category_id) {
                    dispatchErrorMessage(e?.errors?.linked_categories?.secondary_category_id);
                } else if (e?.errors?.linked_categories?.combo_deal_item_index) {
                    dispatchErrorMessage(e?.errors?.linked_categories?.combo_deal_item_index);
                } else {
                    toastsErrorMessage('Error in adding offer', dispatch);
                }
            }

            return response;
        },
        [dispatch, history]
    );

    const onFormSaveApi = useCallback(
        async (data) => {
            timeSlotData(data);

            data.offer_type = selectedOfferType;
            data.is_membership_offer = false;
            data.is_all_option_required = data.is_all_option_required[0].value;

            if (selectedOfferType === COUPLED_OFFER) {
                data.primary_category_id = data.primary_category_id[0].id;
                let items = [];
                data.items.forEach((item) => {
                    if (item?.secondary_category_id?.length) {
                        items.push({ ...item, secondary_category_id: item.secondary_category_id[0].id });
                    }
                });

                delete data.items;
                data.linked_categories = items;
            }

            if (selectedOfferType === BOGO_WITH_VIEW) {
                data.auto_apply = 1;

                // Extract required_item_category_id values from categories
                const requiredItemCategoryIds = data.category.categories.map(
                    (category) => category.required_item_category_id
                );
                data.primary_category_ids = requiredItemCategoryIds;
                delete data.minimum_cart_amount_value;
                delete data.max_discount_amount_value;
                delete data.buy_x1;
                delete data.get_y1;
                delete data.catogaries;
                delete data.is_all_option_required;
                delete data.category;
            }

            if (data.min_cart_amount) {
                data.min_cart_amount = data.minimum_cart_amount_value;
            } else {
                data.min_cart_amount = 0;
            }

            if (data.max_discount_amount) {
                data.max_discount_amount = data.max_discount_amount_value;
            } else {
                data.max_discount_amount = 0;
            }

            if (data.buy_x1 !== '') {
                data.min_cart_item_quantity = data.buy_x1;
            }

            if (data.get_y1 !== '') {
                data.quantity_of_free_item = data.get_y1;
            }

            if (data.offer_type !== MENU_DISCOUNT) {
                const title = {};
                const description = {};
                for (const property in data.translations.title) {
                    title[parseInt(property)] = data.translations.title[property];
                }
                for (const property in data.translations.description) {
                    description[parseInt(property)] = data.translations.description[property];
                }
                data.translations.title = title;
                data.translations.description = description;
            } else {
                data.translations = null;
            }

            if (!isEmpty(data?.translations?.terms_and_conditions)) {
                let terms_and_conditions = {};

                for (const property in data.translations.terms_and_conditions) {
                    terms_and_conditions[parseInt(property)] = data.translations.terms_and_conditions[property];
                }

                data.translations.terms_and_conditions = terms_and_conditions;
            }

            if (data.allow_offer_clubbing) {
                data.allow_offer_clubbing = 1;
            } else {
                data.allow_offer_clubbing = 0;
            }
            if (data.club_membership_consolidation_with_offer) {
                data.club_membership_consolidation_with_offer = 1;
            } else {
                data.club_membership_consolidation_with_offer = 0;
            }

            if (selectedOfferType !== BOGO_WITH_VIEW) {
                if (data.auto_apply) {
                    data.auto_apply = 1;
                } else {
                    data.auto_apply = 0;
                }
            }

            data.promo_consolidation = data.promo_consolidation ? 1 : 0;
            if (data.max_usage_count) {
                data.max_usage_count = data.number_of_coupen_available;
            } else {
                data.max_usage_count = 0;
            }
            if (data.max_usage_count_per_user) {
                data.max_usage_count_per_user = data.number_of_coupen_available_per_user;
            } else {
                data.max_usage_count_per_user = 0;
            }

            if (data.display_in_cart_tab) {
                data.display_in_cart_tab = 1;
            } else {
                data.display_in_cart_tab = 0;
            }
            if (data.display_in_offers_tab === 'offer_yes') {
                data.display_in_offers_tab = 1;
            } else {
                data.display_in_offers_tab = 0;
            }

            if (data.customer_ids === true) {
                data.customer_ids = [];
            } else {
                data.customer_ids = data.selected_users;
            }
            // Modified transformation function for submitting data
            const transformRequiredItemsForSubmission = (items) => {
                if (!items || !items.length) return [];

                const transformedRequiredItems = [];

                items.forEach((item) => {
                    const quantity = parseInt(item.quantity);

                    // Handle variant option IDs consistently
                    if (
                        item.variant_option_id &&
                        Array.isArray(item.variant_option_id) &&
                        item.variant_option_id.length > 0
                    ) {
                        // If variant_option_id contains full objects with id property
                        item.variant_option_id.forEach((variant) => {
                            transformedRequiredItems.push({
                                item_id: item.item_id,
                                label: item.label,
                                quantity: quantity,
                                variant_option_id: variant.id || variant,
                            });
                        });
                    } else if (
                        item.variant_option_ids &&
                        Array.isArray(item.variant_option_ids) &&
                        item.variant_option_ids.length > 0
                    ) {
                        // If variant_option_ids contains just IDs
                        item.variant_option_ids.forEach((variantId) => {
                            transformedRequiredItems.push({
                                item_id: item.item_id,
                                label: item.label,
                                quantity: quantity,
                                variant_option_id: variantId,
                            });
                        });
                    } else if (item.variant_option_id && !Array.isArray(item.variant_option_id)) {
                        // Single variant ID
                        transformedRequiredItems.push({
                            item_id: item.item_id,
                            label: item.label,
                            quantity: quantity,
                            variant_option_id: item.variant_option_id,
                        });
                    } else {
                        // No variants
                        transformedRequiredItems.push({
                            item_id: item.item_id,
                            label: item.label,
                            quantity: quantity,
                            variant_option_id: null,
                        });
                    }
                });

                return transformedRequiredItems;
            };
            if (selectedOfferType !== BOGO_WITH_VIEW) {
                if (data.catogaries === true || data.catogaries === 'all_categories') {
                    data.required_items = null;
                    data.catogaries = null;
                } else if (data.catogaries === 'specific_categories') {
                    data.required_items = null;
                    data.categories = data.category.categories;
                    if (data.categories) {
                        data.categories.forEach((item) => {
                            item.quantity = parseInt(item.quantity);
                        });
                    }
                } else {
                    data.catogaries = null;
                    if (data?.required_items?.length) {
                        data.required_items = transformRequiredItemsForSubmission(data.required_items);
                    }
                }
            }

            // set free item quantity
            if (data?.free_items?.items) {
                data.free_items.items.forEach((item, index) => {
                    item.quantity = parseInt(item.quantity);
                });
            }

            data.validate_from =
                data.validate_from && data.perpetual_offer === 'with_expiry'
                    ? moment(data.validate_from).format('YYYY-MM-DD')
                    : null;
            data.validate_to =
                data.validate_to && data.perpetual_offer === 'with_expiry'
                    ? moment(data.validate_to).format('YYYY-MM-DD')
                    : null;
            const filteredOrderingModes = data.ordering_modes.filter((mode) => mode?.restaurant_ordering_mode_id);

            data.ordering_modes = filteredOrderingModes;

            data.min_required_order_counts =
                data?.min_required_order_counts >= 0 ? parseInt(data.min_required_order_counts) : null;

            data.max_required_order_counts =
                data?.max_required_order_counts >= 0 ? parseInt(data.max_required_order_counts) : null;

            if (data.offer_type === FREE_DELIVERY) {
                const deliveryMode = restaurantOrderingModes?.filter(
                    (orderingMode) => orderingMode.type === 'delivery'
                );
                if (deliveryMode?.length) {
                    data.ordering_modes = [
                        {
                            restaurant_ordering_mode_id: deliveryMode[0].id,
                            restaurant_ordering_mode_name: deliveryMode[0].display_name,
                        },
                    ];
                }
            }
            let response = null;
            if (offer?.id) {
                data.id = offer?.id;
                response = await handleUpdateOfferAction(data);
            } else {
                response = await handleOfferCreationAction(data);
            }

            return response;
        },
        [handleOfferCreationAction, handleUpdateOfferAction, offer?.id, restaurantOrderingModes, selectedOfferType]
    );

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };

    if (loading) {
        return (
            <>
                <EuiPanel>
                    <EuiLoadingContent lines={6} />
                </EuiPanel>
                <EuiSpacer />
                <EuiPanel>
                    <EuiLoadingContent lines={2} />
                </EuiPanel>
            </>
        );
    }

    return (
        <FormProvider {...methods}>
            {selectedOfferType !== COUPLED_OFFER ? (
                <>
                    {selectedOfferType !== MENU_DISCOUNT && (
                        <OfferSection
                            Component={<BasicDetails selectedOfferType={selectedOfferType} />}
                            initialIsOpen={true}
                            title='Basic Details'
                        />
                    )}
                    <OfferSection
                        Component={<Configure selectedOfferType={selectedOfferType} allCategories={categories} />}
                        title='Configure'
                    />
                    <OfferSection Component={<Clubbing selectedOfferType={selectedOfferType} />} title='Clubbing' />

                    {selectedOfferType !== MENU_DISCOUNT && (
                        <OfferSection Component={<VisibilityAndLinking />} title='Visibility and Linking' />
                    )}
                    {selectedOfferType !== FREE_DELIVERY && (
                        <OfferSection Component={<ApplyOrderingModes />} title='Offer Apply' />
                    )}
                    <OfferSection
                        Component={<ValidityAndAcceptibility selectedOfferType={selectedOfferType} />}
                        title='Validity and Applicability'
                    />
                    <OfferSection Component={<OfferTiming />} title='Offer Timing' />
                    <OfferSection Component={<TermsAndConditions />} title='Terms &#38; conditions' />
                </>
            ) : (
                <>
                    <OfferSection
                        Component={<BasicDetails selectedOfferType={selectedOfferType} />}
                        initialIsOpen={true}
                        title='Basic Details'
                    />

                    {offer?.id ? (
                        <>
                            {watch('primary_category_id') ? (
                                <OfferSection
                                    Component={<CoupledOffer dummyState={dummyState} allCategories={categories} />}
                                    title='Item List'
                                />
                            ) : null}
                        </>
                    ) : (
                        <OfferSection
                            Component={<CoupledOffer dummyState={dummyState} allCategories={categories} />}
                            title='Item List'
                        />
                    )}
                </>
            )}
            <OfferValidationError
                submitError={submitError}
                showError={showError}
                setShowError={setShowError}
                errorMessage={errorMessage}
            />
        </FormProvider>
    );
};

export default React.memo(OfferCreateEditForm);
