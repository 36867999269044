import React, { useCallback, useRef, useState } from 'react';
import {
    EuiPanel,
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiSpacer,
    EuiButtonEmpty,
    EuiGlobalToastList,
    htmlIdGenerator,
    EuiStat,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import { cardStyles } from './BillPaymentTable';
import { orderSuperSet } from '../../components/liveOrders/liveOrderCustomerInformation';
import BillInvoicePrint from './BillInvoicePrint';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isElectron } from '../../utils/utils';
import RupeeSymbol from '../rupeeComponent.js';
import APIV3 from '../../api/axios/APIV3';
import { fetchSelectedBillDetails } from '../../api/BillPayments/fetchSelectedBillDetails';
import RefundBillStatus from './RefundBillStatus';
import { userHasPermission } from '../../helper/auth';

const BillDetails = ({ details, setSelectedPayment, outlet, customer }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const [toasts, setToasts] = useState([]);
    const invoicePrinterRef = useRef(null);
    const status = details?.paytmTransaction?.status
        ? details.paytmTransaction.status
        : details?.stripeTransaction?.status
          ? details.stripeTransaction.status
          : null;
    let restaurantList = useSelector((state) => state.auth.restaurantList);
    const [loading, setLoading] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const history = useHistory();

    const order_id = details?.paytmTransaction?.cart_token
        ? details.paytmTransaction.cart_token
        : details?.stripeTransaction?.cart_token
          ? details.stripeTransaction.cart_token
          : null;

    const handleRefund = useCallback(async () => {
        setLoading(true);
        let response;
        try {
            response = await APIV3.post(
                `/restaurants/:restaurantId/outlets/${details.outlet_id}/customer-bill-payment/${details.id}/refund`,
                { amount: details.amount }
            );

            if (response.success) {
                fetchSelectedBillDetails(details.id, details.outlet_id).then((response) => {
                    if (!response?.isError) {
                        setSelectedPayment((prevState) => ({
                            ...prevState,
                            details: response.customer_bill_payments,
                        }));
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                });

                setToasts([
                    ...toasts,
                    {
                        title: response.message,
                        id: htmlIdGenerator()(),
                    },
                ]);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setToasts([
                ...toasts,
                {
                    title: error?.message ? error.message : error,
                    id: htmlIdGenerator()(),
                },
            ]);
            setLoading(false);
        }
        return response;
    }, [details.amount, details.id, details.outlet_id, setSelectedPayment, toasts]);

    const removeToast = useCallback(
        (removedToast) => {
            setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
        },
        [toasts]
    );

    const handleBillPrint = useCallback(() => {
        if (window?.api?.printBill) {
            let restaurantId = parseInt(localStorage.getItem('selectedRestaurant'));
            let restaurant = restaurantList.restaurants.filter(
                (restaurant) => parseInt(restaurant.id) === parseInt(restaurantId)
            );
            window.api.printBill('toMainPrint', {
                orderId: details?.id,
                restaurantName: restaurant[0].name,
            });
        }
    }, [details?.id, restaurantList?.restaurants]);

    return (
        <>
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
            <EuiFlexItem style={{ display: 'none' }}>
                <BillInvoicePrint
                    details={details}
                    status={status}
                    order_id={order_id}
                    ref={(el) => (invoicePrinterRef.current = el)}
                    orderSuperSet={orderSuperSet}
                    languageId={languageId}
                    outlet={outlet}
                />
            </EuiFlexItem>
            <EuiPanel hasBorder className='scrollbar' style={{ boxShadow: cardStyles.boxShadow, maxHeight: '95%' }}>
                <EuiFlexGroup
                    direction='column'
                    gutterSize='xs'
                    style={{ justifyContent: 'space-between', height: '100%' }}
                >
                    {details?.refunds?.length ? (
                        <EuiFlexItem grow={false}>
                            <RefundBillStatus handleRefund={handleRefund} refunds={details.refunds} />
                        </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem grow={false}>
                        <EuiText>
                            <h2>
                                <b>Code: {details?.verification_code}</b>
                            </h2>
                        </EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <EuiFlexItem grow={5}>
                                <EuiText>
                                    <h4>{customer?.name}</h4>
                                </EuiText>
                                <EuiSpacer size='xs' />
                                <EuiText size='xs'>{customer?.mobile_number}</EuiText>
                            </EuiFlexItem>

                            <EuiFlexItem
                                grow={5}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    paddingTop: '7px',
                                }}
                            >
                                <EuiText size='xs'>
                                    {details?.created_at
                                        ? moment(details.created_at).format('DD MMM YYYY, hh:mm a')
                                        : ''}
                                </EuiText>
                                <EuiText size='xs'>{details?.outlet?.translations?.name[languageId]}</EuiText>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <EuiText size='s'>
                                        Customer's {details?.customer_bill_payment_count}
                                        <span style={{ fontSize: '70%', verticalAlign: 'super' }}>
                                            {orderSuperSet(details?.order_count)}&nbsp;
                                        </span>
                                    </EuiText>

                                    <EuiText style={{ marginTop: '3px' }} size='s'>
                                        {' '}
                                        bill
                                    </EuiText>
                                </div>

                                <div>
                                    {isElectron() ? (
                                        <>
                                            <EuiButtonEmpty
                                                style={{ fontSize: '12px', marginRight: '-8px' }}
                                                id='printInvoice'
                                                size='s'
                                                onClick={handleBillPrint}
                                            >
                                                PRINT BILL
                                            </EuiButtonEmpty>
                                        </>
                                    ) : (
                                        <>
                                            <ReactToPrint
                                                copyStyles={false}
                                                trigger={() => (
                                                    <EuiButtonEmpty
                                                        style={{ fontSize: '12px', marginRight: '-8px' }}
                                                        id='printInvoice'
                                                        size='s'
                                                    >
                                                        PRINT ACKNOWLEDGEMENT
                                                    </EuiButtonEmpty>
                                                )}
                                                content={() => invoicePrinterRef.current}
                                            />
                                        </>
                                    )}
                                </div>
                            </EuiFlexItem>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexItem grow={false}>
                            <EuiSpacer size='xxl' />
                            <EuiFlexGroup direction='row'>
                                <EuiFlexItem>
                                    <EuiStat title={details?.total_amount} description={'Amount'} />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiStat title={details?.amount} description={'Customer Paid'} />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexItem>
                    <EuiSpacer />
                    <EuiFlexItem
                        style={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr className='borderColorForTable'>
                                    <td className='borderForFirstTd'>
                                        <span>Bill Amount</span>
                                    </td>
                                    <td className='borderForSecondTd'>
                                        <RupeeSymbol size={'12px'} />
                                        {details?.total_amount}
                                    </td>
                                </tr>
                                {details?.bill_payment_convenience_charge ? (
                                    <tr>
                                        <td className='borderForFirstTd'>
                                            <span>Convenience Charge</span>
                                        </td>
                                        <td className='borderForSecondTd' align='right'>
                                            <RupeeSymbol size={'12px'} />
                                            {details?.bill_payment_convenience_charge}
                                        </td>
                                    </tr>
                                ) : null}

                                <tr className='borderColorForTable'>
                                    <td className='borderForFirstTd'>Discount Amount</td>
                                    <td className='borderForSecondTd'>
                                        <RupeeSymbol size={'12px'} />
                                        {details?.discount_amount}
                                    </td>
                                </tr>
                                <tr className='borderColorForTable'>
                                    <td className='borderForFirstTd'>Net Amount</td>
                                    <td className='borderForSecondTd'>
                                        <RupeeSymbol size={'12px'} />
                                        {details?.amount}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </EuiFlexItem>
                    <EuiSpacer size='s' />
                    {details.amount > 0 && !details?.refunds?.length && (
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup>
                                <EuiFlexItem onClick={handleRefund}>
                                    <EuiButton
                                        disabled={
                                            loading ||
                                            !userHasPermission(permissions, history.location.pathname, 'write')
                                        }
                                        fill
                                    >
                                        Refund
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            </EuiPanel>
        </>
    );
};

export default React.memo(BillDetails);
