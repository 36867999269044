import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextField from '../../../components/Form/TextField';
import NumberField from '../../../components/Form/NumberField';
import TextAreaField from '../../../components/Form/TextAreaField';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import MobileNumber from '../../../components/Form/MobileNumber';
import API from '../../../api/axios/API';
import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiButton,
    EuiTitle,
    EuiFlexGroup,
    EuiForm,
    EuiFlyoutFooter,
    EuiText,
    EuiToolTip,
    EuiFormRow,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiTextColor,
    EuiImage,
    EuiSpacer,
    EuiCard,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import ImageSelectionContainer from '../../../components/UIComponent/ImageSelection/ImageSelectionContainer';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import { useDispatch } from 'react-redux';

const AddRiderForm = ({ setRefetch, closeFlyout }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const editRiderId = useSelector((state) => state.rider.edit_rider_id);
    const outletList = useSelector((state) => state.outlet.outletList);
    const riderList = useSelector((state) => state.rider.riderList);
    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [selectedImages, setSelectedImages] = useState({});
    const [isShowingImageSelectionModal, setIsShowingImageSelectionModal] = useState(false);
    const rider = riderList.filter((item) => item.rider_id === editRiderId);
    const dispatch = useDispatch();

    const toggleModal = useCallback(() => {
        setIsShowingImageSelectionModal((prevState) => !prevState);
        if (!isEmpty(rider?.[0])) {
            setSelectedImages({
                ...rider[0].profile_upload_id,
                src: rider[0].profile_upload_id.image_url,
                image_url: rider[0].profile_upload_id.image_url,
            });
        } else {
            setSelectedImages({});
        }
    }, [rider]);

    const handleImageSelection = useCallback(
        (image) => {
            setSelectedImages(image);
        },
        [setSelectedImages]
    );

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        defaultValues: {},
    });
    const [loader, setLoader] = useState(false);
    const [imageDeleteState, setImageDeleteState] = useState({ profile: {} });
    const [selectedRider, setSelectedRider] = useState({});

    const handleOnSave = useCallback(() => {
        setIsShowingImageSelectionModal((prevState) => !prevState);
        if (!isEmpty(selectedImages)) {
            setValue('profile', selectedImages);
        }
    }, [selectedImages, setValue]);

    useEffect(() => {
        if (editRiderId) {
            const rider = riderList.filter((item) => item.rider_id === editRiderId);
            const editRiderInfo = rider[0];
            setSelectedRider(editRiderInfo);

            let defaultValues = {};
            let outlet = [];
            defaultValues.profile = {
                ...editRiderInfo.profile_upload_id,
                src: editRiderInfo.profile_upload_id.image_url,
                image_url: editRiderInfo.profile_upload_id.image_url,
            };
            setSelectedImages({
                ...editRiderInfo.profile_upload_id,
                src: editRiderInfo.profile_upload_id.image_url,
                image_url: editRiderInfo.profile_upload_id.image_url,
            });
            setImageDeleteState({ profile: defaultValues.profile });
            defaultValues.first_name = editRiderInfo.first_name;
            defaultValues.middle_name = editRiderInfo.middle_name;
            defaultValues.last_name = editRiderInfo.last_name;
            defaultValues.normal_service_radius = editRiderInfo.normal_service_radius;
            defaultValues.delivery_rate = editRiderInfo.delivery_rate;
            defaultValues.extra_delivery_rate = editRiderInfo.extra_delivery_rate;
            defaultValues.mobile_number = editRiderInfo.country_code
                ? `${editRiderInfo.country_code}${editRiderInfo.mobile_number}`
                : editRiderInfo.mobile_number;
            defaultValues.age = editRiderInfo.age;
            defaultValues.address = editRiderInfo.address;
            editRiderInfo.outlets.map((item) =>
                outlet.push({
                    id: item.outlet_id,
                    label: item.translations.name[languageId],
                })
            );
            defaultValues.outlets = outlet;
            reset({ ...defaultValues });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editRiderId]);

    const imageChecker = (data) => {
        if (data.profile) {
            if (
                imageDeleteState.profile &&
                data.profile.id &&
                imageDeleteState.profile.id &&
                data.profile.id !== imageDeleteState.profile.id
            ) {
                try {
                    API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.profile.id}`);
                } catch (error) {
                    console.log('error in image delete ', error);
                }
            }
        } else {
            if (!isEmpty(imageDeleteState.profile) && imageDeleteState.profile.id) {
                try {
                    API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.profile.id}`);
                } catch (error) {
                    console.log('error in image delete ', error);
                }

                // data.profile = imageDeleteState.profile
            }
        }
    };

    const onSubmit = async (data) => {
        if (isEmpty(data?.profile)) {
            setErrorMessage((prevState) => [...prevState, 'Please add a profile image']);
            return;
        }
        imageChecker(data);
        data.profile_upload_id = data.profile.id;
        data.country_code = data.selectedCountryNumber
            ? `+${data.selectedCountryNumber.dialCode}`
            : !isEmpty(selectedRider) && selectedRider.country_code
              ? selectedRider.country_code
              : '+91';
        data.mobile_number = data.selectedCountryNumber
            ? data.selectedCountryNumber.number
            : !isEmpty(selectedRider) && selectedRider.mobile_number
              ? selectedRider.mobile_number
              : '';
        let outlets = [];
        data.outlets.map((item, index) => outlets.push(item.id));
        data.outlets = outlets;
        setErrorMessage([]);
        delete data.profile;
        setLoader(true);
        try {
            if (editRiderId) {
                const updateRider = await API.put(`restaurants/:restaurantId/riders/${editRiderId}`, data);
                if (updateRider.success) {
                    toastSuccessMessage('Rider Updated Successfully', dispatch);
                    setRefetch((preState) => !preState);
                } else {
                    toastsErrorMessage('Please try later', dispatch);
                }
            } else {
                const addRider = await API.post(`restaurants/:restaurantId/riders`, data);
                if (addRider.success) {
                    toastSuccessMessage('Rider Stored Successfully', dispatch);
                    setRefetch((preState) => !preState);
                } else {
                    toastsErrorMessage('Please try later', dispatch);
                }
            }
            setErrorMessage([]);
            setSubmitError(false);
            closeFlyout();
        } catch (e) {
            if (e?.errors?.middle_name) {
                setErrorMessage((prevState) => [...prevState, e.errors.middle_name]);
                toastsErrorMessage(e?.errors?.middle_name, dispatch);
            }
            if (e?.errors?.mobile_number) {
                setErrorMessage((prevState) => [...prevState, e.errors.mobile_number]);
                toastsErrorMessage(e?.errors?.mobile_number, dispatch);
            }
            setSubmitError(true);
        }
        setLoader(false);
    };

    // display error from server side
    const displayError = useCallback(() => {
        let errorsList = [];
        if (submitError || errorMessage) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    }, [errorMessage, submitError]);

    const [options, setOptions] = useState([]);
    useEffect(() => {
        let outletData = [];
        outletList.forEach((outlet) => {
            outletData.push({
                id: outlet.value,
                label: outlet.inputDisplay,
            });
        });
        setOptions(outletData);
    }, [outletList]);

    return (
        <>
            {isShowingImageSelectionModal ? (
                <ImageSelectionContainer
                    imageType={'riders'}
                    handleOnSave={handleOnSave}
                    toggleModal={toggleModal}
                    handleImageSelection={handleImageSelection}
                    selectedImages={selectedImages}
                    aspectRatio={'1'}
                />
            ) : null}
            <EuiForm component='form'>
                <EuiFlyout ownFocus onClose={closeFlyout} aria-labelledby='flyoutWithBannerTitle'>
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle size='m'>
                            <h2 id='flyoutWithBannerTitle'>Add Rider</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody>
                        {!isEmpty(selectedImages) ? (
                            <EuiFormRow label='Upload your profile'>
                                <EuiImage
                                    size='m'
                                    hasShadow
                                    alt={selectedImages.original_file_name}
                                    src={selectedImages.image_url}
                                />
                            </EuiFormRow>
                        ) : null}
                        <EuiSpacer size='s' />
                        {selectedImages.image_url ? (
                            <EuiButtonEmpty onClick={toggleModal}>Change Image </EuiButtonEmpty>
                        ) : (
                            <EuiCard
                                style={{ width: '260px', height: '260px' }}
                                title={
                                    <EuiFlexGroup
                                        justifyContent='center'
                                        alignItems='center'
                                        style={{ height: '100%' }}
                                    >
                                        <EuiFlexItem>
                                            <EuiButtonEmpty onClick={toggleModal}>
                                                Upload your profile picture
                                            </EuiButtonEmpty>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                }
                            />
                        )}
                        <EuiSpacer />
                        <TextField
                            errors={errors}
                            label='First name'
                            name='first_name'
                            placeholder='Please enter first name'
                            rules={{ required: 'Please enter first name' }}
                            control={control}
                        />
                        <TextField
                            errors={errors}
                            label='Middle name'
                            name='middle_name'
                            placeholder='Please enter middle name'
                            rules={{ required: 'Please enter middle name' }}
                            control={control}
                        />
                        <TextField
                            errors={errors}
                            label='Last name'
                            name='last_name'
                            placeholder='Please enter last name'
                            rules={{ required: 'Please enter last name' }}
                            control={control}
                        />
                        <NumberField
                            label='Normal  radius'
                            errors={errors}
                            name='normal_service_radius'
                            placeholder='Please enter normal  radius'
                            rules={{
                                required: 'Please enter normal  radius',
                            }}
                            prepend={
                                <EuiToolTip content='content'>
                                    <EuiText size='s'>In km</EuiText>
                                </EuiToolTip>
                            }
                            step='any'
                            min={0}
                            control={control}
                        />
                        <NumberField
                            label=' Delivery rate for normal  radius'
                            errors={errors}
                            name='delivery_rate'
                            placeholder='Please enter delivery for normal  radius'
                            rules={{
                                required: 'Please enter normal  radius',
                            }}
                            prepend={
                                <EuiToolTip content='content'>
                                    <EuiText size='s'>In km</EuiText>
                                </EuiToolTip>
                            }
                            step='any'
                            min={0}
                            control={control}
                        />
                        <EuiSpacer size='s' />
                        <NumberField
                            label=' Extra km rate'
                            errors={errors}
                            name='extra_delivery_rate'
                            placeholder='Please enter extra km rate'
                            prepend={
                                <EuiToolTip content='content'>
                                    <EuiText size='s'>In km</EuiText>
                                </EuiToolTip>
                            }
                            step='any'
                            min={0}
                            control={control}
                        />
                        <EuiSpacer size='s' />
                        <MobileNumber
                            watch={watch}
                            setValue={setValue}
                            label={'Mobile number'}
                            isInvalid={errors['mobile_number']}
                            errors={errors}
                            placeholder='Enter the contact number of the outlet'
                            name={'mobile_number'}
                            control={control}
                            rules={true}
                        />
                        <EuiSpacer size='s' />
                        <NumberField
                            label='Age'
                            errors={errors}
                            name='age'
                            rules={{
                                required: 'Please enter age',
                                validate: (value) => value > 0 || 'Please enter positive value',
                            }}
                            placeholder='Please enter age'
                            step='any'
                            min={0}
                            control={control}
                        />
                        <TextAreaField
                            errors={errors}
                            label='Address'
                            name='address'
                            placeholder='Write address'
                            rules={{ required: 'Please write address ' }}
                            control={control}
                            rows={4}
                        />
                        <ComboBoxField
                            options={options}
                            // onChange={(selectedOutlet) => setValue('outlets', selectedOutlet)}
                            errors={errors}
                            label='Select outlet to link rider'
                            name='outlets'
                            placeholder='Select one or more payment provider'
                            rules={{
                                validate: (value) =>
                                    (watch('outlets') && watch('outlets').length > 0) ||
                                    'Please select atleast one outlet',
                            }}
                            control={control}
                        />
                        {submitError || errorMessage?.length ? (
                            <EuiFlexGroup
                                direction='column'
                                style={{
                                    backgroundColor: '#f8e9e9',
                                    margin: '10px',
                                    padding: '15px',
                                    borderRadius: '5px',
                                }}
                            >
                                <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                    <b>Please resolve below validations</b>
                                </EuiTextColor>
                                {displayError()}
                            </EuiFlexGroup>
                        ) : null}
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiFlexGroup justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty iconType='cross' onClick={closeFlyout} flush='left'>
                                    Close
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton
                                    isLoading={loader}
                                    onClick={handleSubmit(onSubmit)}
                                    type='submit'
                                    fill
                                    style={{ margin: '10px 0px' }}
                                >
                                    Save form
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            </EuiForm>
        </>
    );
};

export default AddRiderForm;
