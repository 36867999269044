import APIV3 from '../../../../api/axios/APIV3';
import APIV5 from '../../../../api/axios/APIV5';
console.log(`APIV5 ==>`, APIV5);
export const fetchSearchedCategories = async (keyword, languageId, outlet_id) => {
    let url = `restaurants/:restaurantId/categories/internal-name?language_id=${languageId}&keyword=${keyword}`;

    if (outlet_id) {
        url += `&outlet_id=${outlet_id}`;
    }

    const response = await APIV3.get(url);

    return response;
};
// v5/restaurants/32/items/25978/variant-options
export const fetchVaraints = async (varaintID) => {
    // return  {
    let response;

    try {
        response = await APIV5.get(`/restaurants/:restaurantId/items/${varaintID}/variant-options`);
    } catch (error) {
        response = error;
    }

    return response;
    // };
};
