import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ShiftTable from '../ShiftTable';

const OrderFlyoutHeader = ({ selectedTable, isPastTableSession }) => {
    const tableSessionDetails = useSelector((state) => state.tableManagement.tableOrderDetails?.current_session);

    const [tableTime, setTableTime] = useState(
        moment(tableSessionDetails?.started_at)
            .fromNow()
            .replace('a few seconds ago', '0 Mins')
            .replace('a minute ago', '0 Mins')
            .replace('minutes', 'Mins')
            .replace('ago', '')
            .replace('hours', 'hrs')
    );

    useEffect(() => {
        if (tableSessionDetails?.started_at) {
            var interval = setInterval(() => {
                setTableTime(
                    moment(tableSessionDetails?.started_at)
                        .fromNow()
                        .replace('a few seconds ago', '0 Mins')
                        .replace('a minute ago', '0 Mins')
                        .replace('minutes', 'Mins')
                        .replace('ago', '')
                        .replace('hours', 'hrs')
                );
            }, 600);
        }
        return () => {
            clearInterval(interval);
        };
    }, [tableSessionDetails?.started_at]);

    return (
        <EuiFlexGroup alignItems='flexEnd'>
            <EuiFlexItem>
                <EuiFlexGroup direction='column' gutterSize='s'>
                    <EuiFlexItem>
                        <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={false}>
                                <EuiTitle size='s'>
                                    <h2>Table: {selectedTable.internal_name}</h2>
                                </EuiTitle>
                            </EuiFlexItem>
                            {!isPastTableSession ? (
                                <EuiFlexItem grow={false}>
                                    <ShiftTable selectedTable={selectedTable} />
                                </EuiFlexItem>
                            ) : null}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        {!isPastTableSession && (
                            <EuiText size='s'>
                                {!selectedTable?.current_session
                                    ? 'Ideal'
                                    : selectedTable?.current_session?.kot_running_order_counts > 0
                                      ? 'KOT Running'
                                      : selectedTable?.current_session?.running_order_counts > 0 &&
                                          selectedTable?.current_session?.kot_running_order_counts === 0
                                        ? 'Running'
                                        : null}
                            </EuiText>
                        )}
                        <EuiText size='s'>{tableTime}</EuiText>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiCard
                            title='Total Bill'
                            textAlign='left'
                            description={
                                <EuiText>
                                    ₹{tableSessionDetails?.net_amount > 0 ? tableSessionDetails.net_amount : 0}
                                </EuiText>
                            }
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiCard
                            title='To Pay'
                            textAlign='left'
                            description={
                                <EuiText>
                                    ₹{tableSessionDetails?.to_pay_amount > 0 ? tableSessionDetails.to_pay_amount : 0}
                                </EuiText>
                            }
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiCard
                            title='Paid'
                            textAlign='left'
                            description={
                                <EuiText>
                                    ₹{tableSessionDetails?.paid_amount > 0 ? tableSessionDetails.paid_amount : 0}
                                </EuiText>
                            }
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(OrderFlyoutHeader);
