export const multipleTimings = (orderMode, data) => {
    let time_slots;
    if (
        data[`timer_for_item_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`] ===
        `same_day_of_the_week_${orderMode.restaurant_ordering_mode_id}`
    ) {
        time_slots = null;
        orderMode.time_slot_setting = 'no_time_slots';
    } else if (
        data[`timer_for_item_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`] ===
        `same_time_for_all_days_${orderMode.restaurant_ordering_mode_id}`
    ) {
        orderMode.time_slot_setting = 'same_time_slot_for_all_days';
        let time = data[
            `time_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        if (time) {
            time_slots = [
                {
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: time,
                },
            ];
        } else {
            time_slots = [];
        }
    } else {
        time_slots = [];
        orderMode.time_slot_setting = 'different_time_slots_for_different_days';
        let sunday = data.week[
            `${'sunday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        sunday?.length &&
            time_slots.push({
                days_of_week: [7],
                time_slots: sunday,
            });
        let monday = data?.week[
            `${'monday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        monday?.length &&
            time_slots.push({
                days_of_week: [1],
                time_slots: monday,
            });
        let tuesday = data?.week[
            `${'tuesday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        tuesday?.length &&
            time_slots.push({
                days_of_week: [2],
                time_slots: tuesday,
            });
        let wednesday = data?.week[
            `${'wednesday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        wednesday?.length &&
            time_slots.push({
                days_of_week: [3],
                time_slots: wednesday,
            });
        let thursday = data?.week[
            `${'thursday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        thursday?.length &&
            time_slots.push({
                days_of_week: [4],
                time_slots: thursday,
            });
        let friday = data?.week[
            `${'friday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        friday?.length &&
            time_slots.push({
                days_of_week: [5],
                time_slots: friday,
            });
        let saturday = data?.week[
            `${'saturday'}_${orderMode.restaurant_ordering_mode_id}_${orderMode.restaurant_ordering_mode_name}`
        ]?.filter((item) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        saturday?.length > 0 &&
            time_slots.push({
                days_of_week: [6],
                time_slots: saturday,
            });
    }
    // data.time_slots = time_slots;
    orderMode.schedules = time_slots;
};

export const timeSlotData = (data, orderMode) => {
    let schedules;
    if (data.timer_for_item === 'same_day_of_the_week' || data.timer_for_item === true) {
        schedules = null;
        data.time_slot_setting = 'no_time_slots';
    } else if (data.timer_for_item === 'same_time_for_all_days') {
        data.time_slot_setting = 'same_time_slot_for_all_days';
        let time =
            data.time &&
            data.time.length > 0 &&
            data.time.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        if (time) {
            schedules = [
                {
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: time,
                },
            ];
        } else {
            schedules = [];
        }
    } else {
        schedules = [];
        data.time_slot_setting = 'different_time_slots_for_different_days';
        let sunday =
            data.week &&
            data.week.sunday &&
            data.week.sunday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        sunday &&
            sunday.length > 0 &&
            schedules.push({
                days_of_week: [7],
                time_slots: sunday,
            });
        let monday =
            data.week &&
            data.week.monday &&
            data.week.monday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        monday &&
            monday.length > 0 &&
            schedules.push({
                days_of_week: [1],
                time_slots: monday,
            });
        let tuesday =
            data.week &&
            data.week.tuesday &&
            data.week.tuesday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        tuesday &&
            tuesday.length > 0 &&
            schedules.push({
                days_of_week: [2],
                time_slots: tuesday,
            });
        let wednesday =
            data.week &&
            data.week.wednesday &&
            data.week.wednesday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        wednesday &&
            wednesday.length > 0 &&
            schedules.push({
                days_of_week: [3],
                time_slots: wednesday,
            });
        let thursday =
            data.week &&
            data.week.thursday &&
            data.week.thursday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        thursday &&
            thursday.length > 0 &&
            schedules.push({
                days_of_week: [4],
                time_slots: thursday,
            });
        let friday =
            data.week &&
            data.week.friday &&
            data.week.friday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        friday &&
            friday.length > 0 &&
            schedules.push({
                days_of_week: [5],
                time_slots: friday,
            });
        let saturday =
            data.week &&
            data.week.saturday &&
            data.week.saturday.filter((item, index) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
            });
        saturday &&
            saturday.length > 0 &&
            schedules.push({
                days_of_week: [6],
                time_slots: saturday,
            });
    }
    data.schedules = schedules;
    if (orderMode) {
        orderMode.schedules = schedules;
        orderMode.time_slot_setting = data.time_slot_setting;
    }
};
