import {
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiLink,
    EuiPanel,
    EuiSpacer,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { autoSuggestionAddonGroup } from '../utils/autoSuggestionItems';
import AddonChoiceOrderingModes from './AddonChoiceOrderingModes';

const AddonGroupLink = ({ group, groupOptions, rowRef }) => {
    const {
        control,
        formState: { errors, isDirty },
        setValue,
        watch,
    } = useFormContext({
        mode: 'all',
    });
    // const { append, fields } = useFieldArray({
    //     control,
    //     name: `addon_${group.addonGroupId}`,
    // });
    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );
    const [selectedAddonForChoices, setSelectedAddonForChoices] = useState({});
    const [petpoojaAddonChoices, setPetpoojaAddonChoices] = useState([]);
    const [selectedChoices, setSelectedChoices] = useState([]);
    const [errorMessageCheck, setErrorMessageCheck] = useState('');
    const [selectedItemForChoices, setSelectedItemForChoices] = useState({});
    const [groupName, setGroupName] = useState(`addon_${group.addonGroupId}[0].selectedGroup`);
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedAddons);
    const [suggestionGroups, setSuggestionGroups] = useState([]);

    useEffect(() => {
        if (!isDirty) {
            setErrorMessageCheck([]);
        }
    }, [isDirty]);

    useEffect(() => {
        // console.log('group', group);
        // petPoojaChoices
        // addOnChoices
        setPetpoojaAddonChoices(group.petPoojaChoices);
        setSelectedItemForChoices((prevState) => ({
            ...prevState,
            [`group_${group.addonGroupId}`]: group,
        }));
    }, [group]);

    useEffect(() => {
        const suggestedGroups = autoSuggestionAddonGroup(groupOptions, group).groups;
        setSuggestionGroups(suggestedGroups);
    }, []);

    useEffect(() => {
        return function cleanup() {
            setGroupName('');
        };
    }, [group, watch]);

    const handleRegisterSuggestionAction = useCallback(
        (selectedValue) => {
            if (!isEmpty(selectedValue)) {
                selectedValue.module_id = selectedValue.addonGroupId;
                setValue(groupName, [selectedValue], { shouldDirty: true });
                setPetpoojaAddonChoices(selectedValue?.addOnChoices?.length ? selectedValue.addOnChoices : []);
            }

            setSelectedAddonForChoices((prevState) => ({
                ...prevState,
                [`addon_chocies_${group.addonGroupId}`]: selectedValue.addOnChoices,
            }));
        },
        [group.addonGroupId, groupName, setValue]
    );

    const handleAddonGroupSelection = useCallback(
        (selectedValue, item) => {
            setPetpoojaAddonChoices(selectedValue?.length ? selectedValue[0]?.addOnChoices : []);
            setValue(groupName, selectedValue, { shouldDirty: true });
            if (selectedValue?.length) {
                selectedValue[0].module_id = group.addonGroupId;
            } else {
                setErrorMessageCheck([]);
            }

            setSelectedItemForChoices((prevState) => ({
                ...prevState,
                [`group_${group.addonGroupId}`]: selectedValue,
            }));
            setSelectedAddonForChoices((prevState) => ({
                ...prevState,
                [`addon_chocies_${group.addonGroupId}`]: selectedValue?.[0]?.addOnChoices,
            }));
            // UNLINK LOGIC
            if (!selectedValue?.length && item?.length && linkedItems?.length) {
                const currentRemovedItem = item[0];
                currentRemovedItem.module_name = 'restaurant_addon_group';
                currentRemovedItem.restaurant_ordering_mode_id = null;

                for (const linkedItem of linkedItems) {
                    if (
                        linkedItem.third_party_module_id == currentRemovedItem.third_party_module_id &&
                        currentRemovedItem.module_id == linkedItem.module_id
                    ) {
                        if (watch('menu_unlinks')?.length) {
                            setValue('menu_unlinks', [...watch('menu_unlinks'), currentRemovedItem], {
                                shouldDirty: true,
                            });
                        } else {
                            setValue('menu_unlinks', [currentRemovedItem], { shouldDirty: true });
                        }
                    }
                }
            }
            // REMOVE UNLINK ITEMS FROM UNLINK STATE IF LINKED BACK

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.third_party_module_id === selectedValue[0].third_party_module_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });

                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [group.addonGroupId, groupName, linkedItems, setValue, watch]
    );

    const conditionCheck = useCallback(
        (selectedItem) => {
            if (!selectedItem?.length) return false;

            if (group.addOnChoices?.length) {
                if (selectedItem?.[0]?.addOnChoices?.length >= group.addOnChoices?.length) {
                    setErrorMessageCheck(``);
                    return false;
                } else {
                    setErrorMessageCheck(`Choices mismatch`);
                    return true;
                }
            }

            // if (
            //     !(group.addOnChoices.length > 0 && selectedItem?.[0]?.addOnChoices?.length > 0) ||
            //     (group.addOnChoices.length === 0 && selectedItem?.[0]?.addOnChoices?.length === 0)
            // ) {
            //     setErrorMessageCheck(`Choices mismatch`);
            //     return true;
            // }

            return false;
        },
        [group.addOnChoices]
    );

    if (!groupName) {
        return '';
    }

    return (
        <div ref={rowRef} id={`addon_${group?.addonGroupId}`}>
            <EuiPanel style={{ marginLeft: '20px', width: '95%' }}>
                <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='center'>
                    <EuiFlexItem grow={false}>
                        <EuiText style={{ fontWeight: 'bold' }}>{group.internal_name}</EuiText>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false} style={{ minWidth: 300 }}>
                        <EuiFormRow
                            display={errors?.message}
                            error={errors[groupName] ? errors[groupName]?.message : null}
                        >
                            <Controller
                                control={control}
                                name={groupName}
                                rules={{
                                    validate: {
                                        positive: (selectedItem) => {
                                            return conditionCheck(selectedItem) ? errorMessageCheck : null;
                                        },
                                    },
                                }}
                                render={({ field }) => {
                                    return (
                                        <EuiComboBox
                                            options={groupOptions}
                                            placeholder='Select group'
                                            isClearable={true}
                                            singleSelection={true}
                                            selectedOptions={field.value}
                                            onChange={(selectedValue) => {
                                                field.onChange(selectedValue);
                                                handleAddonGroupSelection(selectedValue, field.value);
                                            }}
                                            onBlur={field.onBlur}
                                        />
                                    );
                                }}
                            />
                        </EuiFormRow>
                        <EuiSpacer size='s' />
                        <EuiTextColor color='danger'>{errorMessageCheck}</EuiTextColor>
                        {suggestionGroups?.length ? (
                            <>
                                <EuiSpacer size='s' />
                                <EuiFlexItem grow={false}>
                                    <EuiText size='xs' style={{ fontWeight: 600 }}>
                                        Suggested Match
                                    </EuiText>
                                </EuiFlexItem>
                                <EuiFlexGroup style={{ maxWidth: 300 }} wrap>
                                    {suggestionGroups?.map(({ item }, index) => {
                                        return (
                                            <EuiFlexItem
                                                key={index.toString()}
                                                onClick={() => handleRegisterSuggestionAction(item)}
                                                grow={false}
                                            >
                                                <EuiLink style={{ cursor: 'pointer' }} key={index.toString()}>
                                                    {item.label}
                                                    {/* ({item.price} INR) */}
                                                </EuiLink>
                                            </EuiFlexItem>
                                        );
                                    })}
                                </EuiFlexGroup>
                                <EuiSpacer size='xs' />
                            </>
                        ) : null}
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            <EuiFlexGroup
                style={{ marginLeft: '40px' }}
                direction='column'
                justifyContent='spaceBetween'
                alignItems='flexStart'
                gutterSize='none'
            >
                {group?.addOnChoices?.map((choice, choiceIndex) => {
                    return (
                        <React.Fragment key={choice?.choice_id?.toString()}>
                            <EuiPanel style={{ width: '98%' }}>
                                <EuiFlexGroup direction='column' justifyContent='spaceBetween' alignItems='flexStart'>
                                    <EuiFlexItem grow={false}>
                                        <EuiText style={{ fontWeight: 'bold' }} size='s'>
                                            {choice?.name}
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiFlexGroup wrap alignItems='flexStart'>
                                            {choice?.orderingModes?.map((orderingMode, modeIndex) => {
                                                return outletOrderingModesEnabled?.map((enabledMode) => {
                                                    return (
                                                        <React.Fragment
                                                            key={enabledMode.restaurant_ordering_mode_id.toString()}
                                                        >
                                                            {enabledMode.restaurant_ordering_mode_id ===
                                                                orderingMode.restaurant_ordering_mode_id &&
                                                            enabledMode?.restaurant_ordering_mode?.status ? (
                                                                <EuiFlexItem style={{ minWidth: 240 }}>
                                                                    <AddonChoiceOrderingModes
                                                                        orderingMode={orderingMode}
                                                                        choiceIndex={choiceIndex}
                                                                        addonGroupId={group.addonGroupId}
                                                                        modeIndex={modeIndex}
                                                                        groupName={groupName}
                                                                        choice={choice}
                                                                        petpoojaAddonChoices={petpoojaAddonChoices}
                                                                        selectedItemForChoices={selectedItemForChoices}
                                                                        selectedAddonForChoices={
                                                                            selectedAddonForChoices[
                                                                                `addon_chocies_${group.addonGroupId}`
                                                                            ]
                                                                        }
                                                                        // .filter(
                                                                        //     (petpoojaChoices) =>
                                                                        //         !selectedChoices.some(
                                                                        //             (selectedChoice) =>
                                                                        //                 selectedChoice.petpoojaAddonChoice.choice_id ===
                                                                        //                 petpoojaChoices.choice_id
                                                                        //         )
                                                                        // )
                                                                        setSelectedChoices={setSelectedChoices}
                                                                    />
                                                                </EuiFlexItem>
                                                            ) : null}
                                                        </React.Fragment>
                                                    );
                                                });
                                            })}
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiPanel>
                            <EuiSpacer />
                        </React.Fragment>
                    );
                })}
            </EuiFlexGroup>
            <EuiSpacer />
        </div>
    );
};

export default React.memo(AddonGroupLink);
