import { EuiBadge, EuiBasicTable, EuiCard, EuiFlexItem, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SET_SELECTED_ORDER } from '../../reduxStore/types/orders';
import TimerComponent from '../liveOrders/timerComponent';
import { ReactComponent as SelfServe } from '../../assets/img/svg/self_human.svg';
import { ReactComponent as DineIn } from '../../assets/img/dineIn.svg';
import OrderRowCustomer from './OrderRowCustomer';
import OrderRowValue from './OrderRowValue';

const orderStatus = (order) => {
    if (order.status.delivered_at) {
        return 'SERVED';
    }

    if (order.status.restaurant_ready_at) {
        return 'READY';
    }

    if (order.status.restaurant_accepted_at) {
        return 'PREPARING';
    }

    if (order.status.rider_picked_up_at) {
        return 'DISPATCHED';
    }

    return 'PENDING';
};

const OrdersTable = () => {
    const themeMode = useSelector((state) => state.theme.theme);
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);
    const orders = useSelector((state) => state.tableManagement.tableOrderDetails?.orders);
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [newOrders, setNewOrders] = useState([]);
    const [state, setState] = useState(true);

    const setTimeOutFunction = (liveOrder) => {
        if (liveOrder?.ordering_mode === 'takeaway' && liveOrder.restaurant_ready_at) {
            let date1 = new Date(liveOrder.restaurant_ready_at);
            let date2 = new Date();
            let differenceInMs = date2.getTime() - date1.getTime();

            if (differenceInMs / 60000 < 5) {
                const timer = setTimeout(() => {
                    setState(htmlIdGenerator()());
                }, differenceInMs);
            }
        }
    };

    useEffect(() => {
        let allOrders = [];
        let newOrders = [];
        orders?.forEach((order) => {
            if (!order.status.restaurant_accepted_at) {
                newOrders.push({
                    id: order.order_id,
                    customer: order?.customer?.name,
                    age: order.status.placed_at,
                    value: order.net_total,
                    type: order,
                    status: order,
                    order_details: order,
                    setTimeOut: setTimeOutFunction(order),
                });
            } else {
                allOrders.push({
                    id: order.order_id,
                    customer: order?.customer?.name,
                    age: order.status.placed_at,
                    value: order.net_total,
                    type: order,
                    status: order,
                    order_details: order,
                    setTimeOut: setTimeOutFunction(order),
                });
            }
        });
        setNewOrders(newOrders);
        setItems(allOrders);
    }, [orders, state]);

    let columnsData = useMemo(() => {
        return [
            {
                field: 'customer',
                name: 'Customer',
                sortable: true,
                width: '40%',
                'data-test-subj': 'firstNameCell',
                render: (customer, item) => {
                    return <OrderRowCustomer customer={customer} item={item} />;
                },
            },
            {
                field: 'age',
                name: 'Age',
                width: '15%',
                truncateText: true,
                align: 'center',
                render: (age) => {
                    return <>{age ? <TimerComponent time={age} /> : null}</>;
                },
            },
            {
                field: 'value',
                name: 'Value',
                width: '15%',
                align: 'center',
                render: (value, item) => {
                    return <OrderRowValue value={value} item={item} />;
                },
            },
            {
                field: 'type',
                name: 'Type',
                width: '15%',
                align: 'center',
                render: (type) => {
                    const modeType = type?.ordering_mode_details?.type
                        ? type?.ordering_mode_details?.type
                        : type.ordering_mode;

                    if (modeType === 'fine_dine') {
                        return (
                            <EuiFlexItem style={{ alignItems: 'center' }}>
                                <DineIn height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                                <span style={{ fontSize: '12px' }}>Dine in</span>{' '}
                            </EuiFlexItem>
                        );
                    } else if (modeType === 'qsr') {
                        return (
                            <EuiFlexItem style={{ alignItems: 'center' }}>
                                <DineIn height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                                <span style={{ fontSize: '12px' }}>QSR</span>{' '}
                            </EuiFlexItem>
                        );
                    } else if (modeType === 'self_serve') {
                        return (
                            <EuiFlexItem style={{ alignItems: 'center' }}>
                                <SelfServe height='18' width='18' fill={themeMode === 'light' ? 'black' : 'white'} />{' '}
                                <span style={{ fontSize: '12px' }}>Self Serve</span>{' '}
                            </EuiFlexItem>
                        );
                    }
                },
            },
            {
                field: 'status',
                name: 'Status',
                width: '15%',
                align: 'center',
                render: (order) => {
                    let statusData = orderStatus(order);
                    return (
                        <EuiBadge
                            color={
                                statusData === 'PENDING'
                                    ? '#275AF6'
                                    : statusData === 'PREPARING'
                                      ? '#4DBE9F'
                                      : statusData === 'READY'
                                        ? '#1B1A28'
                                        : statusData === 'SERVED'
                                          ? '#4BB543'
                                          : '#C0C5FF'
                            }
                        >
                            {statusData}
                        </EuiBadge>
                    );
                },
            },
        ];
    }, [themeMode]);

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        if (columnsData) {
            setColumns(columnsData);
        }
    }, [columnsData]);

    const getRowProps = (item) => {
        const { id } = item;
        return {
            'data-test-subj': `row-${id}`,
            style: { backgroundColor: selectedOrderId == id ? (themeMode === 'light' ? '#f5f7fa' : '#6d737e') : '' },
            className: 'customRowClass',
            onClick: () => {
                dispatch({
                    type: SET_SELECTED_ORDER,
                    payload: id,
                });
            },
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            className: 'customCellClass',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };

    return (
        <>
            {newOrders.length ? (
                <EuiFlexItem grow={false}>
                    <EuiCard
                        style={{
                            backgroundColor: themeMode === 'light' ? '#dfe7ff' : '#445a91',
                            padding: '0px',
                            boxShadow:
                                '0 10px 10px -2px rgb(152 162 179 / 30%), 0 1px 24px -2px rgb(152 162 179 / 30%)',
                        }}
                        paddingSize='none'
                        layout='horizontal'
                        title={
                            <EuiFlexItem style={{ padding: '5px 10px' }}>
                                <EuiText size='s'>
                                    <h3>
                                        <b>New Orders</b>
                                    </h3>
                                </EuiText>
                            </EuiFlexItem>
                        }
                        description={
                            <EuiBasicTable
                                items={newOrders}
                                rowHeader='firstName'
                                columns={columns}
                                rowProps={getRowProps}
                                cellProps={getCellProps}
                                noItemsMessage='No orders found'
                            />
                        }
                    />
                    <EuiSpacer size='m' />
                </EuiFlexItem>
            ) : null}
            <EuiFlexItem>
                {newOrders.length ? (
                    <EuiText size='s'>
                        <h3>
                            <b>Running orders</b>
                        </h3>
                    </EuiText>
                ) : null}
                <EuiBasicTable
                    items={items}
                    rowHeader='firstName'
                    columns={columns}
                    rowProps={getRowProps}
                    cellProps={getCellProps}
                    noItemsMessage='No orders found'
                />
            </EuiFlexItem>
        </>
    );
};

export default OrdersTable;
