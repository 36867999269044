import {
    EuiAccordion,
    EuiButtonEmpty,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AvailabilitySwitch from '../../ItemAvailabilityScreen/Components/AvailabilitySwitch';
import { fetchAddonGroupChoices } from '../Api/FetchAddonGroupChoices';
import AddonChoice from './AddonChoice';

export const checkAddonGroupStatus = (group) => {
    const status =
        group.in_stock_choice_ordering_mode_count > 0 &&
        group.total_addon_ordering_modes > 0 &&
        group.in_stock_choice_ordering_mode_count !== group.total_addon_ordering_modes
            ? 'partial_state'
            : group.in_stock_choice_ordering_mode_count > 0 &&
                group.total_addon_ordering_modes > 0 &&
                group.in_stock_choice_ordering_mode_count === group.total_addon_ordering_modes
              ? 'turned_on'
              : group.in_stock_choice_ordering_mode_count === 0 && group.total_addon_ordering_modes === 0
                ? 'turned_off'
                : 'turned_off';

    return status;
};

const AddonGroupRow = ({ group, handleAvailablityModal, fetchAddonGroups, searchedChoices }) => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [groupDetails, setGroupDetails] = useState({
        choices: [],
        isLoading: true,
    });

    const fetchAddonChoices = useCallback(
        async (searchedChoices) => {
            fetchAddonGroupChoices(outletId, group.id).then((response) => {
                let choices = [];
                if (!response.error && response.success) {
                    if (searchedChoices?.length) {
                        let mappedItems = [];
                        for (const choice of response?.choices) {
                            for (const matchedItem of searchedChoices) {
                                if (matchedItem.choice_id === choice.id) {
                                    mappedItems.push(choice);
                                }
                            }
                        }
                        choices = [...mappedItems];
                    } else {
                        choices = [...response.choices];
                    }
                    setGroupDetails((prevState) => ({ ...prevState, choices: choices, isLoading: false }));
                }
            });
        },
        [group.id, outletId]
    );

    // availability switch logic

    const defaultButtonColor =
        group.in_stock_choice_ordering_mode_count > 0 &&
        group.total_addon_ordering_modes > 0 &&
        group.in_stock_choice_ordering_mode_count !== group.total_addon_ordering_modes
            ? 'warning'
            : group.in_stock_choice_ordering_mode_count > 0 &&
                group.total_addon_ordering_modes > 0 &&
                group.in_stock_choice_ordering_mode_count === group.total_addon_ordering_modes
              ? 'success'
              : group.in_stock_choice_ordering_mode_count === 0 && group.total_addon_ordering_modes === 0
                ? 'danger'
                : 'danger';

    const buttonPosition = checkAddonGroupStatus(group);

    const [toggleCompressedIdSelected, setToggleCompressedIdSelected] = useState(buttonPosition);
    const [buttonColor, setButtonColor] = useState(defaultButtonColor);

    useEffect(() => {
        setToggleCompressedIdSelected(buttonPosition);
    }, [buttonPosition]);

    useEffect(() => {
        setButtonColor(defaultButtonColor);
    }, [defaultButtonColor]);

    useEffect(() => {
        if (searchedChoices?.length && !groupDetails.choices.length) {
            fetchAddonChoices(searchedChoices);
        }
    }, [searchedChoices?.length, searchedChoices, groupDetails.choices.length, fetchAddonChoices]);

    const handleAvailabilitySwitchChange = useCallback(
        (optionId, isCategory, selectedElement) => {
            let callbackData = {
                showModal: true,
            };
            if (!optionId === 'partial_state') setToggleCompressedIdSelected(optionId);
            if (optionId === 'turned_off') {
                setButtonColor('danger');
            } else if (optionId === 'turned_on') {
                setButtonColor('success');
            }

            if (optionId === 'turned_off' || optionId === 'turned_on') {
                callbackData.modalResponse = {
                    data: selectedElement,
                    isActive: optionId === 'turned_off' ? 0 : 1,
                };
            }

            handleAvailablityModal({
                showModal: callbackData.showModal,
                modalResponse: callbackData.modalResponse,
                group_id: group.id,
                current_tab_addon: true,
                clearSwitchStateOnClose: () => {
                    setToggleCompressedIdSelected(buttonPosition);
                    setButtonColor(buttonColor);
                },
                fetchUpdatedAddonGroup: () => {
                    fetchAddonGroups();
                },
            });
        },
        [buttonColor, buttonPosition, group.id, handleAvailablityModal, fetchAddonGroups]
    );

    return (
        <>
            <EuiHorizontalRule margin='none' />
            <EuiAccordion
                paddingSize='s'
                initialIsOpen={searchedChoices?.length}
                buttonContent={
                    <EuiFlexGroup direction='column' justifyContent='flexStart' alignItems='flexStart' gutterSize='xs'>
                        <EuiFlexItem>
                            <EuiTitle size='s'>
                                <h4>{group.internal_name}</h4>
                            </EuiTitle>
                        </EuiFlexItem>
                        {group?.min_addons === 1 ? (
                            <EuiFlexItem>
                                <EuiText size='xs'>Minimum 1 Addon Required</EuiText>
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexGroup>
                }
                extraAction={
                    <AvailabilitySwitch
                        buttonColor={buttonColor}
                        handleAvailabilitySwitchChange={handleAvailabilitySwitchChange}
                        toggleCompressedIdSelected={toggleCompressedIdSelected}
                        selectedElement={group}
                        name={group.id.toString()}
                    />
                }
                id={group.id.toString()}
                onToggle={(isOpen) => {
                    if (isOpen && !groupDetails.choices.length) {
                        fetchAddonChoices();
                    }
                }}
                style={{ padding: '16px 0' }}
            >
                {groupDetails.isLoading ? (
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty isLoading={true}>Loading</EuiButtonEmpty>
                    </EuiFlexItem>
                ) : (
                    <>
                        {groupDetails.choices?.length ? (
                            <>
                                {groupDetails.choices.map((choice) => {
                                    return (
                                        <React.Fragment key={choice.id.toString()}>
                                            <EuiSpacer size='xs' />
                                            <AddonChoice
                                                handleAvailablityModal={handleAvailablityModal}
                                                choice={choice}
                                                fetchAddonChoices={fetchAddonChoices}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        ) : (
                            <EuiCard title='No Choice Found' />
                        )}
                    </>
                )}
            </EuiAccordion>
        </>
    );
};

export default React.memo(AddonGroupRow);
