import React, { useState, useEffect } from 'react';
import {
    htmlIdGenerator,
    EuiHorizontalRule,
    EuiFlexItem,
    EuiSpacer,
    EuiText,
    EuiCard,
    EuiIcon,
    EuiEmptyPrompt,
    EuiLoadingContent,
} from '@elastic/eui';
import './style.css';
import LiveOrderDetailsTable from './liveOrderDetailsTable';
import { useSelector, useDispatch } from 'react-redux';
import LiverOrderCustomerInformation from './liveOrderCustomerInformation';
import LiveOrderRiderInformation from './liveOrderRiderInformation';
import LiveOrderDetailsAction from './liveOrderDetailsActions';
import ActionRequiredErrorCard from './actionRequiredErrorCard';
import OrderTotalBill from './orderTotalBill';
import { ReactComponent as Moped } from '../../assets/img/moped.svg';
import { OrderRating } from '../orderDetailsCard/orderRating';
import { RiderRating } from '../orderDetailsCard/riderRating';
import { TimmingDetails } from '../orderDetailsCard/timmingDetails';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import * as types from '../../reduxStore/types/orders';
import { isEmpty } from 'lodash';
import { formatDistance } from 'date-fns';

const LiveOrderDetails = (props) => {
    const themeMode = useSelector((state) => state.theme.theme);
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);
    const tableLoader = useSelector((state) => state.orders.tableLoader);
    const selectedOrderDetails = useSelector((state) => state.orders.selectedOrderDetails);
    const dispatch = useDispatch();

    const [orderDetailsLoader, setOrderDetailsLoader] = useState({ loader: true, error: false });
    // const [selectedOrderDetails, setSelectedOrderDetails] = useState({})
    useEffect(() => {
        if (!selectedOrderId) {
            setOrderDetailsLoader({ loader: false, error: false });
            dispatch({
                type: types.SET_SELECTED_ORDER_DETAILS,
                payload: {},
            });
            return;
        }

        setOrderDetailsLoader({ loader: true, error: false });
        dispatch(getLiveOrderDetailsAPI()).then((liveOrderDetailsResponse) => {
            if (liveOrderDetailsResponse.success) {
                setOrderDetailsLoader({ loader: false, error: false });
                // setSelectedOrderDetails(liveOrderDetailsResponse.order)
                return;
            }
            setOrderDetailsLoader({ loader: true, error: true });
        });
    }, [selectedOrderId]);

    const [isInterventionOver, setIsInterventionOver] = useState(false);
    const [showRiderAssignment, setShowRiderAssignment] = useState(false);
    const [timer, setTimer] = useState();

    useEffect(() => {
        if (!isEmpty(selectedOrderDetails)) {
            const orderAge = getTimeAgo(selectedOrderDetails?.status?.placed_at);
            const orderSeconds = getSeconds(selectedOrderDetails?.status?.placed_at);
            if (
                (selectedOrderDetails?.outlet?.settings?.intervention_time_minutes !== null ||
                    selectedOrderDetails?.outlet?.settings?.intervention_time_seconds !== null) &&
                (selectedOrderDetails?.outlet?.settings?.enable_rider_auto_assignment_by_preference !== 0 ||
                    selectedOrderDetails?.outlet?.settings?.enable_rider_auto_assignment_by_priority !== 0)
            ) {
                if (
                    selectedOrderDetails?.outlet?.settings?.intervention_time_minutes === 0 &&
                    selectedOrderDetails?.outlet?.settings?.intervention_time_seconds === 0
                ) {
                    setIsInterventionOver(true);
                } else if (
                    selectedOrderDetails?.outlet?.settings?.intervention_time_minutes <= parseInt(orderAge) &&
                    selectedOrderDetails?.outlet?.settings?.intervention_time_seconds <= orderSeconds
                ) {
                    setIsInterventionOver(true);
                }
            } else {
                setIsInterventionOver(false);
            }
        }
    }, [selectedOrderDetails, timer, selectedOrderId]);

    const getTimeAgo = (dateObj) => {
        if (dateObj) {
            let date = formatDistance(new Date(dateObj), new Date(), {
                addSuffix: false,
                addPrefix: false,
                style: 'narrow',
                includeSeconds: true,
            });
            return date
                .replace('about', '')
                .replace('ago', '')
                .replace('hours', 'hrs')
                .replace('hour', 'hr')
                .replace('minutes', '')
                .replace('less than a minute', '');
        }
    };

    const getSeconds = (dateObj) => {
        if (dateObj) {
            let seconds = formatDistance(new Date(dateObj), new Date(), {
                addSuffix: false,
                addPrefix: false,
                includeSeconds: true,
            });
            return seconds.includes('sec')
                ? parseInt(seconds.replace('less than', '').replace('seconds', '').replace('half a minute', 30).trim())
                : null;
        }
    };

    useEffect(() => {
        if (!isEmpty(selectedOrderDetails)) {
            const time = selectedOrderDetails?.status?.placed_at;
            if (time && window) {
                var interval = window.setInterval(() => {
                    setTimer(getTimeAgo(time));
                }, 60000);
            }
        }
        return () => clearInterval(interval);
    }, [selectedOrderDetails, selectedOrderId]);

    useEffect(() => {
        if (selectedOrderDetails?.rider_assignment_action_required) {
            setShowRiderAssignment(true);
        } else if (isInterventionOver) {
            setShowRiderAssignment(true);
        } else {
            setShowRiderAssignment(false);
        }
    }, [selectedOrderDetails, isInterventionOver, selectedOrderId]);

    if (orderDetailsLoader.loader) return <EuiLoadingContent lines={10} />;

    if (orderDetailsLoader.error) return 'Error in loading order details..';

    if (!selectedOrderId) {
        return (
            <EuiFlexItem style={{ height: '90%', justifyContent: 'center' }}>
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>No order selected</h2>}
                    body={
                        <>
                            <p>Plesae select an order.</p>
                        </>
                    }
                />
            </EuiFlexItem>
        );
    } else if (selectedOrderDetails.cart_items && !selectedOrderDetails.cart_items.length) {
        return (
            <div
                key={htmlIdGenerator()()}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '90%' }}
            >
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>It's an Empty cart</h2>}
                    body={<p>User might have remove the items after creating a cart</p>}
                />
            </div>
        );
    }

    const isThirdPartAssigned =
        !selectedOrderDetails.rider &&
        !selectedOrderDetails.elt_order &&
        !selectedOrderDetails.dunzo_order &&
        !selectedOrderDetails.porter_order &&
        !selectedOrderDetails.flash_order &&
        !selectedOrderDetails.sfx_store_based_order &&
        !selectedOrderDetails.wefast_order &&
        !selectedOrderDetails.scout_order &&
        !selectedOrderDetails.shadowfax_order &&
        !selectedOrderDetails.pidge_order;

    return (
        <EuiFlexItem style={{ height: '100%' }}>
            {!tableLoader.loader ? (
                <>
                    <EuiFlexItem>
                        {/* Action required error card */}
                        {!props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder &&
                        !(
                            selectedOrderDetails.rider ||
                            selectedOrderDetails.elt_order ||
                            selectedOrderDetails.dunzo_order ||
                            selectedOrderDetails.porter_order ||
                            selectedOrderDetails.wefast_order ||
                            selectedOrderDetails.scout_order ||
                            selectedOrderDetails.shadowfax_order ||
                            selectedOrderDetails.pidge_order ||
                            selectedOrderDetails.flash_order ||
                            selectedOrderDetails.sfx_store_based_order
                        ) ? (
                            <>
                                <ActionRequiredErrorCard selectedOrder={selectedOrderDetails} />
                            </>
                        ) : (
                            ''
                        )}

                        {/* rider information */}

                        {!props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder &&
                        showRiderAssignment &&
                        !selectedOrderDetails.rider_assignment_action_required &&
                        selectedOrderDetails.ordering_mode === 'delivery' &&
                        (selectedOrderDetails.outlet.settings.enable_rider_auto_assignment_by_preference === 1 ||
                            (selectedOrderDetails.outlet.settings.enable_rider_auto_assignment_by_priority === 1 &&
                                selectedOrderDetails.status.rider_accept_at === null)) &&
                        isThirdPartAssigned &&
                        selectedOrderDetails.status.restaurant_accepted_at ? (
                            <>
                                <EuiCard
                                    style={{
                                        boxShadow: 'none',
                                        backgroundColor: themeMode === 'light' ? '#D0F2EB' : '#636a69',
                                    }}
                                    display='plain'
                                    titleSize='xs'
                                    layout='horizontal'
                                    paddingSize='s'
                                    title={
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Moped />
                                            <div style={{ marginLeft: '10px' }}>
                                                <h1>Rider Auto Assignment is in effect.</h1>
                                            </div>
                                        </div>
                                    }
                                    description={<span>Rider will be assign shortly.</span>}
                                />
                                <EuiSpacer size='s' />
                            </>
                        ) : (
                            <>
                                {!props.isPast &&
                                !props.isAbandoned &&
                                !props.isCustomerOrder &&
                                (selectedOrderDetails.rider ||
                                    selectedOrderDetails.elt_order ||
                                    selectedOrderDetails.dunzo_order ||
                                    selectedOrderDetails.wefast_order ||
                                    selectedOrderDetails.scout_order ||
                                    selectedOrderDetails.porter_order ||
                                    selectedOrderDetails.shadowfax_order ||
                                    selectedOrderDetails.pidge_order ||
                                    selectedOrderDetails.flash_order ||
                                    selectedOrderDetails.sfx_store_based_order) ? (
                                    <>
                                        <EuiCard
                                            style={{
                                                boxShadow: 'none',
                                                backgroundColor: themeMode === 'light' ? '#D0F2EB' : '#636a69',
                                            }}
                                            display='plain'
                                            titleSize='xs'
                                            layout='horizontal'
                                            paddingSize='s'
                                            title={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <Moped />
                                                    <div style={{ marginLeft: '10px' }}>
                                                        <span>Rider Information</span>
                                                    </div>
                                                </div>
                                            }
                                            description={<LiveOrderRiderInformation order={selectedOrderDetails} />}
                                        />
                                        <EuiSpacer size='s' />
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        )}

                        {/* customer information */}
                        <LiverOrderCustomerInformation {...props} selectedOrder={selectedOrderDetails} />

                        <EuiSpacer />

                        {/* order items component */}
                        <LiveOrderDetailsTable {...props} selectedOrder={selectedOrderDetails} />
                    </EuiFlexItem>

                    {selectedOrderDetails?.cart?.special_instructions && (
                        <>
                            <EuiSpacer />
                            <div style={{ borderLeft: '2px solid #F5A700', padding: '5px 20px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        color: '#F5A700',
                                    }}
                                >
                                    <EuiIcon className='iconMarginRight' type='globe' />
                                    <EuiText size='s'>cooking instructions</EuiText>
                                </div>
                                <div>
                                    <EuiText size='s'>{selectedOrderDetails.cart.special_instructions}</EuiText>
                                </div>
                            </div>
                        </>
                    )}

                    {(props.isPast || props.isCustomerOrder) && (
                        <EuiFlexItem>
                            <EuiHorizontalRule margin='s' />
                            <OrderRating data={props} order={selectedOrderDetails} />
                            {selectedOrderDetails.ordering_mode === 'delivery' && (
                                <RiderRating data={props} order={selectedOrderDetails} />
                            )}
                            <TimmingDetails order={selectedOrderDetails} />
                            <EuiSpacer size='s' />
                            {selectedOrderDetails.ordering_mode === 'delivery' &&
                                (selectedOrderDetails.rider ||
                                    selectedOrderDetails.elt_order ||
                                    selectedOrderDetails.dunzo_order ||
                                    selectedOrderDetails.wefast_order ||
                                    selectedOrderDetails.scout_order ||
                                    selectedOrderDetails.porter_order ||
                                    selectedOrderDetails.shadowfax_order ||
                                    selectedOrderDetails.pidge_order ||
                                    selectedOrderDetails.flash_order ||
                                    selectedOrderDetails.sfx_store_based_order) && (
                                    <EuiCard
                                        style={{
                                            boxShadow: 'none',
                                            backgroundColor: themeMode === 'light' ? '#D0F2EB' : '#636a69',
                                        }}
                                        display='plain'
                                        titleSize='xs'
                                        layout='horizontal'
                                        paddingSize='s'
                                        title={
                                            <div
                                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                                            >
                                                <Moped />
                                                <div style={{ marginLeft: '10px' }}>
                                                    <span>Rider Information</span>
                                                </div>
                                            </div>
                                        }
                                        description={
                                            <LiveOrderRiderInformation {...props} order={selectedOrderDetails} />
                                        }
                                    />
                                )}
                            <EuiSpacer size='s' />
                        </EuiFlexItem>
                    )}

                    <EuiFlexItem style={{ justifyContent: 'flex-end' }}>
                        <EuiSpacer size='xl' />

                        <OrderTotalBill selectedOrder={selectedOrderDetails} />

                        <EuiSpacer />

                        <LiveOrderDetailsAction {...props} selectedOrder={selectedOrderDetails} />
                    </EuiFlexItem>
                </>
            ) : (
                <EuiLoadingContent lines={10} />
            )}
        </EuiFlexItem>
    );
};

export default LiveOrderDetails;
