import React, { Fragment, useEffect, useState } from 'react';
import { EuiBasicTable, htmlIdGenerator, EuiText, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import RupeeSymbol from '../rupeeComponent.js';
import './style.css';

const LiveOrderDetailsTable = (props) => {
    const { selectedOrder } = props;
    const languageId = useSelector((state) => state.language.languageId);
    const themeMode = useSelector((state) => state.theme.theme);
    const [items, setItems] = useState([]);
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});

    const toggleDetails = (id, item, itemIdToExpandedRowMapValues) => {
        let innerArray = [];
        if (!isEmpty(item.variant_option_information)) {
            innerArray.push(
                <div>
                    <span style={{ fontSize: '12px' }} key={item.variant_option_information.id}>
                        <p>
                            ↑{' '}
                            {
                                item.variant_option_information?.variant_group_information?.translations?.title[
                                    languageId
                                ]
                            }
                            : <b>{item.variant_option_information.translations.title[languageId]}</b>
                        </p>
                    </span>
                </div>
            );
        }

        if (item.customizations.length) {
            item.customizations.map((addons, idx) => {
                innerArray.push(
                    <div key={idx}>
                        <span style={{ fontSize: '12px' }} key={addons.cart_addon_choice_id}>
                            <p>
                                ↑ {addons.translations.group_name[languageId]}:{' '}
                                <b>
                                    {addons.translations.name[languageId]} [<RupeeSymbol size={'12px'} />
                                    {addons.price.toFixed(2)}]
                                </b>
                            </p>
                        </span>
                    </div>
                );
            });
        }

        itemIdToExpandedRowMapValues[id] = (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'column' }}>{innerArray}</EuiFlexItem>
        );
    };

    const createTableStructureOfCart = () => {
        let innerObj = {};
        let itemIdToExpandedRowMapValues = {};
        let cart_items = props.isAbandoned ? selectedOrder?.cart_items : selectedOrder?.cart?.cart_items;
        if (cart_items) {
            cart_items.forEach((item) => {
                if (innerObj[item?.category?.translations?.title?.[languageId]]) {
                    let categoryItems = innerObj[item?.category?.translations?.title?.[languageId]];
                    innerObj[item?.category?.translations?.title?.[languageId]] = [...categoryItems, item];
                } else {
                    innerObj[item?.category?.translations?.title?.[languageId]] = [item];
                }
            });
            let itemsArray = [];
            Object.entries(innerObj).forEach(([key, value]) => {
                itemsArray.push({
                    itemName: key,
                });
                value.forEach((item) => {
                    let id = htmlIdGenerator()();
                    // item.item_id
                    itemsArray.push({
                        id: id,
                        itemName: item.item.translations.title[languageId],
                        qty: item.quantity,
                        price: item.price,
                        item_details: item,
                        addons: (item.customizations && item.customizations.length) || item.variant_option_information,
                    });

                    if (
                        ((item.customizations && item.customizations.length) || item.variant_option_information) &&
                        !item?.children?.length
                    ) {
                        toggleDetails(id, item, itemIdToExpandedRowMapValues);
                    }
                });
            });

            setItems(itemsArray);
            setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
        }
    };

    useEffect(() => {
        createTableStructureOfCart();
    }, [selectedOrder]);

    const getVariantOrCustomizations = (item) => {
        let innerArray = [];
        if (!isEmpty(item.variant_option_information)) {
            innerArray.push(
                <div style={{ display: 'block' }}>
                    <span style={{ fontSize: '12px' }} key={item.variant_option_information.id}>
                        <p>
                            ↑{' '}
                            {
                                item.variant_option_information?.variant_group_information?.translations?.title[
                                    languageId
                                ]
                            }
                            : <b>{item.variant_option_information.translations.title[languageId]}</b>
                        </p>
                    </span>
                </div>
            );
        }

        if (item.customizations) {
            item.customizations.map((addons, idx) => {
                innerArray.push(
                    <div style={{ display: 'block' }} key={idx}>
                        <span style={{ fontSize: '12px' }} key={addons.cart_addon_choice_id}>
                            <p>
                                ↑ {addons.translations.group_name[languageId]}:{' '}
                                <b>
                                    {addons.translations.name[languageId]} [<RupeeSymbol size={'12px'} />
                                    {addons.price.toFixed(2)}]
                                </b>
                            </p>
                        </span>
                    </div>
                );
            });
        }

        return innerArray;
    };

    const columns = [
        {
            field: 'itemName',
            name: 'Item',
            render: (itemName, item) => {
                if (item?.item_details?.children?.length) {
                    return (
                        <EuiFlexItem className='bogoItemStyle'>
                            <EuiText>{itemName}</EuiText>

                            {getVariantOrCustomizations(item.item_details)}

                            <EuiSpacer size='s' />

                            {item.item_details.children.map((child, index) => {
                                return (
                                    <Fragment key={index.toString() + languageId.toString()}>
                                        <EuiSpacer size='s' />
                                        <EuiText>{child.item.translations.title[languageId]}</EuiText>

                                        {getVariantOrCustomizations(child)}
                                    </Fragment>
                                );
                            })}
                        </EuiFlexItem>
                    );
                } else {
                    return <span>{itemName}</span>;
                }
            },
            width: '60%',
        },
        {
            field: 'qty',
            name: 'Qty',
            truncateText: true,
            render: (qty, item) => {
                return qty || item?.item_details?.promotional_quantity ? (
                    <div style={{ display: 'block' }}>
                        <span style={{ display: 'block' }}>
                            {item?.item_details?.promotional_quantity ? (
                                <>x{item.item_details.promotional_quantity}</>
                            ) : (
                                <>x{qty}</>
                            )}
                        </span>
                        {item?.item_details?.children?.[0]?.quantity ? (
                            <>
                                <span style={{ display: 'block' }}>
                                    {item?.item_details?.promotional_quantity ? (
                                        <>x{item.item_details.promotional_quantity}</>
                                    ) : (
                                        <span style={{ display: 'block' }}>
                                            {item.item_details.children.map((child, index) => {
                                                return (
                                                    <span
                                                        style={{ display: 'block' }}
                                                        key={index.toString() + child.quantity.toString()}
                                                    >
                                                        x{child.quantity}
                                                    </span>
                                                );
                                            })}{' '}
                                        </span>
                                    )}
                                </span>
                            </>
                        ) : null}
                    </div>
                ) : (
                    ''
                );
            },
            align: 'right',
        },
        {
            field: 'price',
            name: 'Amount',
            render: (price, item) => {
                return price != undefined ? (
                    <div style={{}}>
                        {item?.item_details?.promotional_quantity || !parseInt(price) ? (
                            <span style={{ color: 'green' }}>Free </span>
                        ) : null}
                        <span
                            style={item?.item_details?.promotional_quantity ? { textDecoration: 'line-through' } : {}}
                        >
                            {price ? (
                                <>
                                    <RupeeSymbol size='14px' />
                                    {item.qty * price}
                                </>
                            ) : null}
                        </span>
                        {item?.item_details?.children?.length ? (
                            <div style={{ display: 'block' }}>
                                {item.item_details.children.map((child, index) => {
                                    return (
                                        <span key={index.toString()} style={{ display: 'block' }}>
                                            {!parseInt(child?.price) ? (
                                                <span style={{ color: 'green' }}>Free </span>
                                            ) : (
                                                <>
                                                    <RupeeSymbol size='14px' /> {child.quantity * child?.price}
                                                </>
                                            )}
                                        </span>
                                    );
                                })}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                );
            },
            align: 'right',
        },
    ];

    const getRowProps = (item) => {
        const { id, addons } = item;
        return {
            'data-test-subj': `row-${id}`,
            className: addons ? 'customCellClassForVariant' : '',
        };
    };

    const getCellProps = (item, column) => {
        const { id } = item;
        const { field } = column;
        return {
            style: { '--var-for-theme': themeMode === 'light' ? '#f5f7fa' : '#6d737d' },
            className: !id
                ? 'customRowClassForCategory euiTableRow-isExpandedRow euiTableCellContent'
                : item?.addons
                  ? 'customCellClassForVariant'
                  : '',
            'data-test-subj': `cell-${id}-${field}`,
            textOnly: true,
        };
    };
    let border = `1px solid ${themeMode === 'light' ? '#d3dae6' : '#6c6c6c'}`;
    return (
        <>
            <EuiBasicTable
                style={{ borderTop: border, borderLeft: border, borderRight: border }}
                itemId='id'
                items={items}
                rowHeader='firstName'
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            />
        </>
    );
};

export default LiveOrderDetailsTable;
