import { EuiText } from '@elastic/eui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormRadioGroup from '../../../../../components/Form/FormRadioGroup';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as ItemUpsellingCartIcon } from '../../../../../assets/img/svg/shopping_cart_item_upselling.svg';

const ItemUpsellCart = () => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const themeMode = useSelector((state) => state.theme.theme);

    return (
        <ItemDetailsCollaspibleNav
            cardTitle='Upsell this item in cart'
            isNavOpen={isNavOpen}
            cardDescription={<EuiText size='s'> This item will be shown in the carousal on cart page.</EuiText>}
            setIsNavOpen={setIsNavOpen}
            icon={<ItemUpsellingCartIcon fill={themeMode === 'light' ? 'black' : 'white'} />}
        >
            <FormRadioGroup
                name='upselling_item'
                disabled={isOutletMenu}
                options={[
                    {
                        id: 'no',
                        label: `No, don't show this item in cart upselling carousal`,
                    },
                    {
                        id: 'yes',
                        label: `Yes, show this item in cart upselling carousal`,
                    },
                ]}
            />
        </ItemDetailsCollaspibleNav>
    );
};

export default ItemUpsellCart;
