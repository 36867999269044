import React, { useEffect } from 'react';
import {
    htmlIdGenerator,
    EuiHorizontalRule,
    EuiFlexItem,
    EuiSpacer,
    EuiText,
    EuiCard,
    EuiIcon,
    EuiEmptyPrompt,
    EuiLoadingContent,
} from '@elastic/eui';
import './style.css';
import LiveOrderDetailsTable from './liveOrderDetailsTable';
import { useSelector, useDispatch } from 'react-redux';
import LiverOrderCustomerInformation from './liveOrderCustomerInformation';
import LiveOrderRiderInformation from './liveOrderRiderInformation';
import LiveOrderDetailsAction from './liveOrderDetailsActions';
import ActionRequiredErrorCard from './actionRequiredErrorCard';
import OrderTotalBill from './orderTotalBill';
import { ReactComponent as Moped } from '../../assets/img/moped.svg';
import { isEmpty } from 'lodash';
import { OrderRating } from '../orderDetailsCard/orderRating';
import { RiderRating } from '../orderDetailsCard/riderRating';
import { TimmingDetails } from '../orderDetailsCard/timmingDetails';
import * as types from '../../reduxStore/types/orders';
import RefundStatusCard from './refundStatusCard';
import OrderFailureReasonCard from './orderFailureReasonCard';
import PaymentDetailsCard from './paymentCardDetails';

const LiveOrderDetailsForPast = (props) => {
    let orders = useSelector((state) =>
        props.isPast
            ? state.orders.pastOrders
            : props.isCustomerOrder
              ? state.customer.customerOrderDetails
              : props.isAbandoned
                ? state.orders.abandonedCarts
                : props.isFailedOrders
                  ? state.orders.failedOrders
                  : state.orders.liveOrders
    );

    const themeMode = useSelector((state) => state.theme.theme);
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);
    const tableLoader = useSelector((state) => state.orders.tableLoader);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({
                type: types.SET_SELECTED_ORDER_DETAILS,
                payload: {},
            });
            dispatch({
                type: types.SET_SELECTED_ORDER,
                payload: '',
            });
        };
    }, []);

    let selectedOrder;

    if (props.isAbandoned) {
        selectedOrder = orders.filter((data) => data.cart_id === selectedOrderId);
    } else {
        selectedOrder = orders.filter((data) => data.order_id === selectedOrderId);
    }
    selectedOrder = selectedOrder.length ? selectedOrder[0] : {};

    if (isEmpty(selectedOrder)) {
        return (
            <EuiFlexItem style={{ height: '90%', justifyContent: 'center' }}>
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>No order selected</h2>}
                    body={
                        <>
                            <p>Plesae select an order.</p>
                        </>
                    }
                />
            </EuiFlexItem>
        );
    } else if (selectedOrder.cart_items && !selectedOrder.cart_items.length) {
        return (
            <div
                key={htmlIdGenerator()()}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '90%' }}
            >
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>It's an Empty cart</h2>}
                    body={<p>User might have remove the items after creating a cart</p>}
                />
            </div>
        );
    }

    return (
        <EuiFlexItem style={{ height: '100%' }}>
            {!tableLoader.loader ? (
                <>
                    <EuiFlexItem>
                        {/* Refund information card */}
                        {(props.isPast || props.isFailedOrders || props.isCustomerOrder) &&
                            selectedOrder?.payment_method?.payment_method !== 'CASH_ON_DELIVERY' &&
                            selectedOrder?.refunds?.length > 0 && (
                                <RefundStatusCard {...props} selectedOrder={selectedOrder} />
                            )}

                        {/* Action required error card */}
                        {!props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder &&
                        !(
                            selectedOrder.rider ||
                            selectedOrder.flash_order ||
                            selectedOrder.elt_order ||
                            selectedOrder.dunzo_order ||
                            selectedOrder.porter_order ||
                            selectedOrder.wefast_order ||
                            selectedOrder.scout_order ||
                            selectedOrder.pidge_order
                        ) ? (
                            <>
                                <ActionRequiredErrorCard selectedOrder={selectedOrder} />
                            </>
                        ) : (
                            ''
                        )}

                        {/* rider information */}
                        {!props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder &&
                        (selectedOrder.rider ||
                            selectedOrder.flash_order ||
                            selectedOrder.elt_order ||
                            selectedOrder.dunzo_order ||
                            selectedOrder.wefast_order ||
                            selectedOrder.scout_order ||
                            selectedOrder.porter_order ||
                            selectedOrder.shadowfax_order ||
                            selectedOrder.pidge_order) ? (
                            <>
                                <EuiCard
                                    style={{
                                        boxShadow: 'none',
                                        backgroundColor: themeMode === 'light' ? '#D0F2EB' : '#636a69',
                                    }}
                                    display='plain'
                                    titleSize='xs'
                                    layout='horizontal'
                                    paddingSize='s'
                                    title={
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            <Moped />
                                            <div style={{ marginLeft: '10px' }}>
                                                <span>Rider Information</span>
                                            </div>
                                        </div>
                                    }
                                    description={<LiveOrderRiderInformation order={selectedOrder} />}
                                />
                                <EuiSpacer size='s' />
                            </>
                        ) : (
                            ''
                        )}

                        {/* customer information */}

                        <PaymentDetailsCard {...props} order={selectedOrder} />

                        <OrderFailureReasonCard {...props} order={selectedOrder} />

                        <LiverOrderCustomerInformation {...props} selectedOrder={selectedOrder} />

                        <EuiSpacer />

                        {/* order items component */}
                        <LiveOrderDetailsTable {...props} selectedOrder={selectedOrder} />
                    </EuiFlexItem>

                    {selectedOrder?.cart?.special_instructions && (
                        <>
                            <EuiSpacer />
                            <div style={{ borderLeft: '2px solid #F5A700', padding: '5px 20px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        color: '#F5A700',
                                    }}
                                >
                                    <EuiIcon className='iconMarginRight' type='globe' />
                                    <EuiText size='s'>cooking instructions</EuiText>
                                </div>
                                <div>
                                    <EuiText size='s'>{selectedOrder.cart.special_instructions}</EuiText>
                                </div>
                            </div>
                        </>
                    )}

                    {(props.isPast || props.isCustomerOrder) && (
                        <EuiFlexItem>
                            <EuiHorizontalRule margin='s' />
                            <OrderRating data={props} order={selectedOrder} />
                            {selectedOrder.ordering_mode === 'delivery' && (
                                <RiderRating data={props} order={selectedOrder} />
                            )}
                            <TimmingDetails order={selectedOrder} />
                            <EuiSpacer size='s' />
                            {selectedOrder.ordering_mode === 'delivery' &&
                                (selectedOrder.rider ||
                                    selectedOrder.elt_order ||
                                    selectedOrder.dunzo_order ||
                                    selectedOrder.wefast_order ||
                                    selectedOrder.porter_order ||
                                    selectedOrder.scout_order ||
                                    selectedOrder.shadowfax_order ||
                                    selectedOrder.flash_order ||
                                    selectedOrder.pidge_order) && (
                                    <EuiCard
                                        style={{
                                            boxShadow: 'none',
                                            backgroundColor: themeMode === 'light' ? '#D0F2EB' : '#636a69',
                                        }}
                                        display='plain'
                                        titleSize='xs'
                                        layout='horizontal'
                                        paddingSize='s'
                                        title={
                                            <div
                                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                                            >
                                                <Moped />
                                                <div style={{ marginLeft: '10px' }}>
                                                    <span>Rider Information</span>
                                                </div>
                                            </div>
                                        }
                                        description={<LiveOrderRiderInformation {...props} order={selectedOrder} />}
                                    />
                                )}
                            <EuiSpacer size='s' />
                        </EuiFlexItem>
                    )}

                    <EuiFlexItem style={{ justifyContent: 'flex-end' }}>
                        <EuiSpacer size='xl' />

                        <OrderTotalBill selectedOrder={selectedOrder} />

                        <EuiSpacer />

                        <LiveOrderDetailsAction {...props} selectedOrder={selectedOrder} />
                    </EuiFlexItem>
                </>
            ) : (
                <EuiLoadingContent lines={10} />
            )}
        </EuiFlexItem>
    );
};

export default LiveOrderDetailsForPast;
