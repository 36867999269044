import { EuiButton } from '@elastic/eui';
import React from 'react';

const PetPoojaSynceButton = ({ isLoading, handleOnClick, isDisabled }) => {
    return (
        <EuiButton fill isLoading={isLoading} isDisabled={isDisabled} onClick={handleOnClick}>
            Validate Sync
        </EuiButton>
    );
};

export default PetPoojaSynceButton;
