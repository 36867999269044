import {
    EuiButton,
    EuiCard,
    EuiDatePicker,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiHorizontalRule,
    EuiModalFooter,
    EuiRadio,
    EuiSpacer,
    EuiText,
    EuiTextColor,
    EuiTitle,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import '../style.css';
import AvailabilityExpose from '../../../../components/ItemAvailability/AvailabilityExpose';
import { useSelector } from 'react-redux';
import APIV3 from '../../../../api/axios/APIV3';
import { dayName, nextAvailableTimeCheck } from '../../../ItemAvailability/utils/utils';
import moment from 'moment';
import ManualTurnOnAvailability from './ManualTurnOnAvailability';
import { SET_AVAILABILITY_CATALOGUE } from '../../../../reduxStore/types/ItemAvailabilityTypes';
import { fetchItemAvailabilityCategories } from '../../../menuComponentsRefactorCopy/catalogue/Api/ItemAvailability/FetchItemAvailabilityData';
import { useDispatch } from 'react-redux';

const AvailabilityTimeModal = ({ state, handleAvailablityModal }) => {
    const dispatch = useDispatch();
    const [outletSelectedDetails, setOutletSelectedDetails] = useState({});
    const orderingModes = useSelector((state) => state.outlet.outletOrderingModes?.ordering_modes);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletList = useSelector((state) => state.outlet.outletList);
    const modalResponse = state.modalResponse;
    const [startDate, setStartDate] = useState(moment().add(1, 'days'));
    const [radioIdSelected, setRadioIdSelected] = useState(``);
    const [errorTimeSlot, setErrorTimeSlot] = useState();

    const [orderingModeState, setOrderingModeState] = useState({
        available_ordering_mode: [],
        unavailable_ordering_mode: [],
    });
    const [checkedInvisible, setCheckedInvisible] = useState(false);

    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (outletList) {
            outletList.forEach((outlet) => {
                if (outlet.value === outletId) {
                    setOutletSelectedDetails(outlet.outlet_data);
                }
            });
        }
    }, [outletList, outletId]);

    const handleChange = useCallback((date) => {
        setStartDate(date);
    }, []);

    const onCloseAvailabilityModal = useCallback(() => {
        state.clearSwitchStateOnClose();
        handleAvailablityModal({
            showModal: false,
            modalResponse: null,
        });

        // clear state
        setStartDate(null);
        setRadioIdSelected('');
        setSubmitError(false);
        setErrorMessage('');
        setErrorTimeSlot('');

        setCheckedInvisible(false);
        setOrderingModeState({
            available_ordering_mode: [],
            unavailable_ordering_mode: [],
        });
    }, [handleAvailablityModal, state]);

    const onChange = useCallback((optionId) => {
        setRadioIdSelected(optionId);
    }, []);

    const handleCatch = useCallback((error, response) => {
        setSubmitError(true);
        response = error;
        setErrorTimeSlot(error?.time_slots ? error.time_slots : error);
        setErrorMessage(error?.message ? error.message : error);
    }, []);

    const handleOnApiSuccess = useCallback(
        (item, success) => {
            setSubmitError(false);
            handleAvailablityModal({
                showModal: false,
                modalResponse: null,
                toast: {
                    title: success ? `${item} Updated Successfully` : `Error in updating ${item}`,
                },
            });
        },
        [handleAvailablityModal]
    );

    const handleSaveModalData = useCallback(async () => {
        let payload = {};
        let response;

        if (state?.current_tab_addon) {
            if (state.choice_id) {
                // addon group => choice availability
                payload = {
                    choices: [
                        {
                            choice_id: state.choice_id,
                            available_ordering_mode: orderingModeState.available_ordering_mode,
                            unavailable_ordering_mode: orderingModeState.unavailable_ordering_mode,
                            next_available_time: nextAvailableTimeCheck(
                                radioIdSelected,
                                startDate,
                                outletSelectedDetails
                            ),
                        },
                    ],
                };
                try {
                    response = await APIV3.patch(
                        `/restaurants/:restaurantId/outlets/${outletId}/addon-choices/update-stock`,
                        payload
                    );
                    handleOnApiSuccess('Addon Choice', true);
                    state.fetchUpdatedAddonChoice();
                } catch (error) {
                    handleCatch(error, response);
                }
            } else {
                // addon group availability
                payload = {
                    addon_groups: [
                        {
                            group_id: state.group_id,
                            available_ordering_mode: orderingModeState.available_ordering_mode,
                            unavailable_ordering_mode: orderingModeState.unavailable_ordering_mode,
                            next_available_time: nextAvailableTimeCheck(
                                radioIdSelected,
                                startDate,
                                outletSelectedDetails
                            ),
                        },
                    ],
                };
                try {
                    response = await APIV3.patch(
                        `/restaurants/:restaurantId/outlets/${outletId}/addon-groups/update-stock`,
                        payload
                    );
                    handleOnApiSuccess('Addon Group', true);
                    state.fetchUpdatedAddonGroup();
                } catch (error) {
                    handleCatch(error, response);
                }
            }
        } else {
            if (!state?.variant_option_id) {
                if (state.isCategory) {
                    payload = {
                        categories: [
                            {
                                category_id: state.modalResponse.category_id,
                                available_ordering_mode: orderingModeState.available_ordering_mode,
                                unavailable_ordering_mode: orderingModeState.unavailable_ordering_mode,
                                next_available_time: nextAvailableTimeCheck(
                                    radioIdSelected,
                                    startDate,
                                    outletSelectedDetails
                                ),
                                hidden: state.modalResponse.isActive
                                    ? false
                                    : checkedInvisible
                                      ? checkedInvisible
                                      : false,
                            },
                        ],
                    };
                } else {
                    payload = {
                        items: [
                            {
                                item_id: state.modalResponse.item_id,
                                available_ordering_mode: orderingModeState.available_ordering_mode,
                                unavailable_ordering_mode: orderingModeState.unavailable_ordering_mode,
                                next_available_time: nextAvailableTimeCheck(
                                    radioIdSelected,
                                    startDate,
                                    outletSelectedDetails
                                ),
                                hidden: state.modalResponse.isActive
                                    ? false
                                    : checkedInvisible
                                      ? checkedInvisible
                                      : false,
                            },
                        ],
                    };
                }

                try {
                    response = await APIV3.patch(
                        `/restaurants/:restaurantId/outlets/${outletId}/${
                            state?.isCategory ? 'categories' : 'items'
                        }/update-stock`,
                        payload
                    );
                    handleOnApiSuccess(state?.isCategory ? 'Category' : 'Item', true);
                    if (state.isCategory) {
                        state.fetchUpdateCategories();
                    } else if (state.modalResponse.item_id && !state.isCategory) {
                        state.fetchUpdatedItems();
                    }
                } catch (error) {
                    handleCatch(error, response);
                }
            } else {
                // update variant option status
                payload = {
                    variant_options: [
                        {
                            variant_option_id: state.variant_option_id,
                            available_ordering_mode: orderingModeState.available_ordering_mode,
                            unavailable_ordering_mode: orderingModeState.unavailable_ordering_mode,
                            next_available_time: nextAvailableTimeCheck(
                                radioIdSelected,
                                startDate,
                                outletSelectedDetails
                            ),
                            hidden: state.modalResponse.isActive ? true : checkedInvisible ? checkedInvisible : false,
                        },
                    ],
                };

                try {
                    response = await APIV3.patch(
                        `/restaurants/:restaurantId/outlets/${outletId}/variant-options/update-stock`,
                        payload
                    );
                    handleOnApiSuccess('Variant', true);
                    state.fetchUpdatedVariantOption();
                } catch (error) {
                    handleCatch(error, response);
                }
            }

            let availabilityCategories = await fetchItemAvailabilityCategories(outletId);
            if (response.success) {
                dispatch({
                    type: SET_AVAILABILITY_CATALOGUE,
                    payload: availabilityCategories.categories,
                });
            }
        }

        return response;
    }, [
        checkedInvisible,
        dispatch,
        handleCatch,
        handleOnApiSuccess,
        orderingModeState.available_ordering_mode,
        orderingModeState.unavailable_ordering_mode,
        outletId,
        outletSelectedDetails,
        radioIdSelected,
        startDate,
        state,
    ]);

    //display error
    const displayError = useCallback(() => {
        let errorsList = [];
        if (submitError) {
            errorsList.push(
                <EuiTextColor style={{ padding: 5 }} color='danger'>
                    {errorMessage}
                </EuiTextColor>
            );
        }
        return errorsList;
    }, [errorMessage, submitError]);

    const displayErrorTimeSlot = useCallback(() => {
        let errorsTiemSlotList = [];
        if (submitError) {
            errorTimeSlot &&
                errorTimeSlot.length > 0 &&
                errorTimeSlot.forEach((item, index) => {
                    errorsTiemSlotList.push(
                        <EuiTextColor color='danger' style={{ padding: 5 }} key={index}>
                            {`${dayName(item.time_slots.day_of_week)} : Start Time :- ${
                                item.time_slots.start_time
                            } , End Time :- ${item.time_slots.end_time}`}
                        </EuiTextColor>
                    );
                });
        }
        return errorsTiemSlotList;
    }, [errorTimeSlot, submitError]);

    return (
        <>
            <EuiFlyout onClose={onCloseAvailabilityModal}>
                <EuiFlyoutHeader>
                    <EuiTitle>
                        <h2>
                            Edit{' '}
                            {state.isCategory
                                ? 'Category'
                                : state.choice_id
                                  ? 'Choice'
                                  : state?.current_tab_addon
                                    ? 'Addon Group'
                                    : 'Item'}{' '}
                            Availability
                        </h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiHorizontalRule margin='xs' />
                <EuiFlyoutBody>
                    <EuiFlexItem style={{ margin: '0 -24px', paddingTop: '0' }}>
                        {!state.modalResponse.isActive ? (
                            <EuiFlexGroup>
                                <EuiFlexItem style={{ padding: '0 24px 4px 24px' }}>
                                    <EuiText style={{ marginBottom: '10px', fontWeight: '500' }}>
                                        Auto turn on item after
                                    </EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        ) : null}

                        <EuiFlexGroup>
                            <EuiFlexItem>
                                {!state.modalResponse.isActive ? (
                                    <React.Fragment>
                                        <EuiFlexItem style={{ padding: '0 24px 4px 24px' }}>
                                            <EuiRadio
                                                id={'category_item_availability_2'}
                                                label='2 Hours'
                                                checked={radioIdSelected === 'category_item_availability_2'}
                                                onChange={(e) => onChange(e.target.id)}
                                            />
                                        </EuiFlexItem>
                                        <EuiHorizontalRule margin='xs' />
                                        <EuiFlexItem style={{ padding: '4px 24px 4px 24px' }}>
                                            <EuiRadio
                                                id={'category_item_availability_3'}
                                                label='4 Hours'
                                                checked={radioIdSelected === 'category_item_availability_3'}
                                                onChange={(e) => onChange(e.target.id)}
                                            />
                                        </EuiFlexItem>
                                        <EuiHorizontalRule margin='xs' />
                                        <EuiFlexItem style={{ padding: '4px 24px 4px 24px' }}>
                                            <EuiRadio
                                                id={'category_item_availability_4'}
                                                label='Next Business Day'
                                                checked={radioIdSelected === 'category_item_availability_4'}
                                                onChange={(e) => onChange(e.target.id)}
                                            />
                                        </EuiFlexItem>
                                        <EuiHorizontalRule margin='xs' />
                                        <EuiFlexItem style={{ padding: '4px 24px' }}>
                                            <EuiRadio
                                                id={'category_item_availability_1'}
                                                label='Schedule time to reopen'
                                                checked={radioIdSelected === 'category_item_availability_1'}
                                                onChange={(e) => onChange(e.target.id)}
                                            />
                                            {radioIdSelected === 'category_item_availability_1' ? (
                                                <EuiFlexItem style={{ paddingTop: '4px' }}>
                                                    <EuiDatePicker
                                                        showTimeSelect
                                                        selected={startDate}
                                                        onChange={handleChange}
                                                        timeIntervals={15}
                                                        placeholder='Enter next available time'
                                                        fullWidth
                                                    />
                                                </EuiFlexItem>
                                            ) : null}
                                        </EuiFlexItem>
                                        <EuiHorizontalRule margin='xs' />
                                        <ManualTurnOnAvailability
                                            radioIdSelected={radioIdSelected}
                                            checkedInvisible={checkedInvisible}
                                            onChange={onChange}
                                            setCheckedInvisible={setCheckedInvisible}
                                            state={state}
                                        />

                                        <div
                                            style={{
                                                height: '10px',
                                                width: '100%',
                                                backgroundColor: '#8080803b',
                                            }}
                                        />
                                        <EuiSpacer />
                                    </React.Fragment>
                                ) : null}
                                {orderingModes.filter((orderingMode) => orderingMode.is_enabled)?.length ? (
                                    <EuiFlexItem style={{ padding: '0 24px 16px 24px', marginBottom: '0' }}>
                                        <EuiText style={{ fontWeight: '500' }}>
                                            Toggle availability for following modes
                                        </EuiText>
                                        <EuiSpacer />
                                        {orderingModes
                                            .filter((orderingMode) => orderingMode.is_enabled)
                                            .map((mode) => {
                                                return (
                                                    <EuiFlexGroup
                                                        alignItems='center'
                                                        style={{ maxWidth: 350 }}
                                                        justifyContent='flexStart'
                                                        wrap
                                                        responsive
                                                        key={mode.id.toString()}
                                                    >
                                                        <AvailabilityExpose
                                                            state={state}
                                                            orderingModeState={orderingModeState}
                                                            setOrderingModeState={setOrderingModeState}
                                                            orderingMode={mode}
                                                            modalResponse={modalResponse}
                                                            // isDisabled={
                                                            //     orderingModeState?.default_unavailable_ordering_mode?.filter(
                                                            //         (orderingModeId) =>
                                                            //             orderingModeId ===
                                                            //             mode.restaurant_ordering_mode_id
                                                            //     )?.length
                                                            // }
                                                        />
                                                    </EuiFlexGroup>
                                                );
                                            })}
                                    </EuiFlexItem>
                                ) : (
                                    <EuiCard
                                        title='Ordering modes are not found for this outlet'
                                        style={{ margin: '0 24px 16px 24px' }}
                                    />
                                )}

                                {submitError && (
                                    <EuiFlexGroup
                                        direction='column'
                                        style={{
                                            backgroundColor: '#f8e9e9',
                                            margin: '10px',
                                            padding: '15px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                            <b>Please resolve below validations</b>
                                        </EuiTextColor>
                                        {displayError()}
                                        {displayErrorTimeSlot()}
                                    </EuiFlexGroup>
                                )}
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiModalFooter>
                        <EuiButton
                            onClick={() => {
                                handleSaveModalData();
                                setRadioIdSelected('');
                            }}
                            style={{ margin: '16px 0', width: '100%' }}
                            fill
                            fullWidth
                            disabled={
                                (!orderingModeState.available_ordering_mode?.length &&
                                    !orderingModeState.unavailable_ordering_mode?.length) ||
                                (modalResponse.isActive ? false : !radioIdSelected)
                            }
                        >
                            Save
                        </EuiButton>
                    </EuiModalFooter>
                </EuiFlyoutBody>
            </EuiFlyout>
        </>
    );
};

export default React.memo(AvailabilityTimeModal);
