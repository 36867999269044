import React, { useCallback, useEffect, useState } from 'react';
import {
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiDragDropContext,
    EuiDroppable,
    EuiText,
    euiDragDropReorder,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import AddonCard from './addOnCardSequence';
import { debounce, isEmpty } from 'lodash';
import { fetchAddons } from '../catalogue/Api/FetchCatalogueInfo';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import { SET_ADDONS } from '../Store/MenuTypes';

const VariantAddonLinking = (props) => {
    const { option, control, withVariant, variantAddonLinkState, setVariantAddonLinkState, isOutletMenu, addonList } =
        props;
    const languageId = useSelector((state) => state.language.languageId);
    // const addOnList = useSelector((state) => state.menuReducer.addons);
    const [addOnList, setaddOnList] = useState([...addonList]);
    const variantAddonLink = useSelector((state) => state.menu.variantAddonLink);
    const [addonSelected, setAddonSelected] = useState('');
    const [addOnListState, setAddOnListState] = useState([]);

    const [currentPage, setcurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [lastPage, setLastPage] = useState(0);
    const dispatch = useDispatch();

    const fetchMoreData = async ({ nextpage, keyword }) => {
        try {
            setIsLoading(true);
            const response = await fetchAddons({ pageNumber: nextpage ?? currentPage, keyword: keyword ?? '' });
            if (response) {
                dispatch({
                    type: SET_ADDONS,
                    payload: response.restaurant_addon_groups.data,
                });
                setaddOnList((perviosuState) => [...perviosuState, ...response.restaurant_addon_groups.data]);
                setLastPage(response.restaurant_addon_groups.last_page);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const selectedAddonDetails = (id) => {
        let addonDetail = {};
        addOnListState.map((addon) => {
            if (addon.value == id) {
                addonDetail = addon.record;
            }
        });

        return addonDetail;
    };
    const CustomLoadingIndicator = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ClipLoader color='#2196F3' cssOverride={{ marginRight: 10 }} loading size={20} speedMultiplier={3} />
        </div>
    );
    useEffect(() => {
        let addon_list = [...addOnList];

        if (variantAddonLinkState[option.option_id] && variantAddonLinkState[option.option_id].length) {
            variantAddonLinkState[option.option_id].map((addon) => {
                addon_list.map((addonList, index) => {
                    if (addonList.value == addon.addon_group_id) {
                        addon_list.splice(index, 1);
                    }
                });
            });
            setAddOnListState(addon_list);
        }
        if (addon_list?.length) {
            let addOnArray = [{ value: 0, label: 'Select addon', record: {} }];
            addon_list?.map((addon) =>
                addOnArray.push({
                    value: addon.addon_group_id,
                    label: `${addon.translations.group_name[languageId]} - ${addon.internal_name}`,
                    record: addon,
                })
            );

            if (addOnArray.length) {
                setAddOnListState(addOnArray);
            }
        }
    }, [addOnList]);

    const onChangeAddon = (value) => {
        let valueOfaddon = value.value;
        let addonDetails = selectedAddonDetails(valueOfaddon);

        let innerObj = JSON.parse(JSON.stringify(variantAddonLinkState));
        if (innerObj[option.option_id]) {
            let innerArray = [...innerObj[option.option_id]];
            innerArray.push(addonDetails);
            innerObj[option.option_id] = innerArray;
        } else {
            innerObj[option.option_id] = [addonDetails];
        }

        setVariantAddonLinkState(innerObj);

        setAddonSelected(valueOfaddon);

        removeAddonFromList(valueOfaddon);
    };

    const filterAddons = (id, currentId) => {
        return id == currentId;
    };

    const removeAddonFromList = (value) => {
        let addonArray = [...addOnListState];
        let indexNumber = addonArray.findIndex((addon) => filterAddons(addon.value, value));
        if (indexNumber >= 0) {
            addonArray.splice(indexNumber, 1);
            setAddOnListState(addonArray);
        }
    };

    const addAddonToList = (addon) => {
        setAddOnListState([
            ...addOnListState,
            { value: addon.addon_group_id, text: addon.translations.group_name[languageId], record: addon },
        ]);
    };

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            if (!option.option_id) {
                option.option_id = option.id;
            }

            const items = euiDragDropReorder(variantAddonLinkState[option.option_id], source.index, destination.index);

            let innerObj = JSON.parse(JSON.stringify(variantAddonLinkState));
            innerObj[option.option_id] = items;

            setVariantAddonLinkState(innerObj);
        }
    };

    const onDeleteAddon = (index) => {
        let innerObj = JSON.parse(JSON.stringify(variantAddonLinkState));
        let innerArray = innerObj[option.option_id];

        addAddonToList(innerArray[index]);

        innerArray.splice(index, 1);

        innerObj[option.option_id] = innerArray;

        setVariantAddonLinkState(innerObj);
    };

    const [query, setquery] = useState('');
    const handleScroll = () => {
        if (currentPage == lastPage) {
            setcurrentPage(lastPage);
            return;
        }

        setcurrentPage((prevPage) => prevPage + 1);
        const nextPage = currentPage + 1;

        fetchMoreData({ keyword: query, nextpage: nextPage });
    };

    const delayedCategoriesSearchQuery = useCallback(
        debounce(async (keyword) => {
            setcurrentPage(1);
            setquery(keyword);
            setaddOnList([]);
            fetchMoreData({ keyword: keyword });
        }, 1000)
    );

    return (
        <>
            <div
                style={{
                    backgroundColor: 'rgb(242, 240, 240)',
                    width: '100%',
                    fontSize: '14px',
                    margin: '10px 0 0',
                    padding: '10px 0 10px 20px',
                }}
            >
                <h1>{withVariant ? option[`variant_title-${languageId}`] : option.translations.title[languageId]}</h1>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', margin: '10px' }}>
                <EuiFlexItem>
                    <EuiFormRow label='Add on Groups' fullWidth={true}>
                        <Controller
                            render={({}) => (
                                <Select
                                    options={addOnListState}
                                    onMenuScrollToBottom={() => handleScroll()}
                                    onChange={(e) => onChangeAddon(e)}
                                    onInputChange={(e) => delayedCategoriesSearchQuery(e)}
                                    filterOption={false}
                                    components={{ LoadingIndicator: CustomLoadingIndicator }}
                                    isLoading={isLoading}
                                    isSearchable={false}
                                    maxMenuHeight={220}
                                    menuPortalTarget={typeof window !== 'undefined' ? document.body : null}
                                    styles={{
                                        menuPortal: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                            backgroundColor: 'white',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                            top: '100%',

                                            backgroundColor: 'white',
                                        }),
                                    }}
                                />
                            )}
                            name='group_name'
                            fullWidth={true}
                            control={control}
                        />
                    </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem
                    style={{
                        flexDirection: 'row',
                        overflowY: 'auto',
                        width: '100%',
                        marginTop: '10px',
                    }}
                >
                    <EuiDragDropContext onDragEnd={onDragEnd}>
                        <EuiDroppable
                            droppableId='CUSTOM_HANDLE_DROPPABLE_AREA'
                            spacing='m'
                            withPanel
                            direction='horizontal'
                            style={{
                                display: 'flex',
                                width: '100%',
                                heigth: '200px',
                                overflow: 'auto',
                            }}
                        >
                            {(!isEmpty(variantAddonLinkState) &&
                                variantAddonLinkState[option.option_id] &&
                                variantAddonLinkState[option.option_id].length >= 0) ||
                            variantAddonLink.length ? (
                                <AddonCard
                                    addOnList={
                                        variantAddonLinkState[option.option_id]
                                            ? variantAddonLinkState[option.option_id]
                                            : variantAddonLink[option.option_id]
                                    }
                                    addOnDelete={onDeleteAddon}
                                    disabled={isOutletMenu}
                                />
                            ) : (
                                <EuiText> No AddOns Found</EuiText>
                            )}
                        </EuiDroppable>
                    </EuiDragDropContext>
                </EuiFlexItem>
            </div>
        </>
    );
};

export default VariantAddonLinking;
