import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTextColor } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const OfferValidationError = ({ submitError, showError, setShowError, errorMessage }) => {
    const {
        formState: { errors },
    } = useFormContext();

    const displayError = useCallback(() => {
        let errorsList = [];
        if (submitError) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    }, [errorMessage, submitError]);

    const displayValidation = useCallback((errors) => {
        let innerArray = [];
        if (!isEmpty(errors)) {
            for (var key in errors) {
                if (Array.isArray(errors[key])) {
                    errors[key].forEach((itemError, index) => {
                        if (itemError && itemError.variant_option_id?.message) {
                            innerArray.push(
                                <EuiText key={`${key}-${index}`}>
                                    <ul>
                                        <li>{itemError.variant_option_id.message}</li>
                                    </ul>
                                </EuiText>
                            );
                        }
                    });
                } else if (errors?.[key]?.message) {
                    innerArray.push(
                        <EuiText key={key}>
                            <ul>
                                <li>{errors[key].message}</li>
                            </ul>
                        </EuiText>
                    );
                }
            }
        }
        return innerArray;
    }, []);

    return (
        <>
            {submitError && showError && (
                <EuiPanel>
                    <EuiFlexGroup
                        direction='column'
                        style={{
                            backgroundColor: '#f8e9e9',
                            margin: '10px',
                            padding: '15px',
                            borderRadius: '5px',
                        }}
                    >
                        <EuiFlexItem
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                <b>Please resolve below validations</b>
                            </EuiTextColor>
                            <EuiButtonIcon
                                color={'warning'}
                                onClick={() => setShowError(false)}
                                iconType='cross'
                                aria-label='cross'
                            />
                        </EuiFlexItem>
                        {errors['free_items']?.items?.message}

                        {displayError()}
                    </EuiFlexGroup>
                </EuiPanel>
            )}
            {!isEmpty(errors) && showError && (
                <EuiPanel>
                    <EuiFlexGroup direction='column' style={{ backgroundColor: '#f8e9e9', padding: '15px' }}>
                        <EuiFlexItem
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                <b>Please resolve below validations</b>
                            </EuiTextColor>

                            <EuiButtonIcon
                                color={'warning'}
                                onClick={() => setShowError(false)}
                                iconType='cross'
                                aria-label='cross'
                            />
                        </EuiFlexItem>
                        {errors['free_items']?.items?.message}
                        {displayValidation(errors)}
                    </EuiFlexGroup>
                </EuiPanel>
            )}
        </>
    );
};

export default React.memo(OfferValidationError);
