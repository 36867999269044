import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiSuperSelect, EuiTitle } from '@elastic/eui';
import React, { useCallback } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { outletSettingsV4 } from '../../../../api/outlet/outletSettingsAPI';
import useFormActionsHandler from '../../../../hooks/useFormActionsHandler';
import useOutletFormValues from '../../Hooks/useOutletFormValues';

const CategoryLayout = () => {
    const dispatch = useDispatch();

    const updateFormValues = useCallback((data) => {
        return {
            ...{
                category_list_display_settings: data.category_list_display_settings,
                outlet_screen_display_configurations: data.outlet_screen_display_configurations,
            },
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(
                outletSettingsV4('layout_settings', {
                    category_list_display_settings: data.category_list_display_settings,
                })
            );
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch]
    );

    const { control, setValue, watch } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'category_list_display_settings',
    });

    const handleAddRow = useCallback(() => {
        append({ column_rank: fields?.length + 1, category_display_count: 1 });
        setValue('', '', { shouldDirty: true });
    }, [append, fields?.length, setValue]);

    const handleRemoveRow = useCallback(
        (idx) => {
            remove(idx);
            let updatedDisplaySettings = [];
            if (watch('category_list_display_settings')?.length) {
                for (let [index, setting] of watch('category_list_display_settings')?.entries()) {
                    setting.column_rank = index + 1;
                    updatedDisplaySettings.push(setting);
                }
            }
            setValue('category_list_display_settings', updatedDisplaySettings, { shouldDirty: true });
        },
        [remove, setValue, watch]
    );

    return (
        <>
            {fields?.map((field, index) => {
                return (
                    <React.Fragment key={field.id.toString()}>
                        <span>
                            <EuiFlexItem>
                                <EuiTitle size='xs'>
                                    <h4>
                                        Row{' '}
                                        {field?.row_rank
                                            ? field.row_rank
                                            : field.column_rank
                                              ? field.column_rank
                                              : null}
                                    </h4>
                                </EuiTitle>
                            </EuiFlexItem>
                            <EuiFlexGroup alignItems='flexEnd'>
                                <EuiFlexItem>
                                    <EuiFormRow label='Category Display Count'>
                                        <Controller
                                            control={control}
                                            name={`category_list_display_settings[${index}].category_display_count`}
                                            rules={{ required: 'Please Select Category Display Count' }}
                                            render={({ field }) => {
                                                return (
                                                    <EuiSuperSelect
                                                        onChange={field.onChange}
                                                        valueOfSelected={field.value}
                                                        options={rowOptions}
                                                    />
                                                );
                                            }}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    {' '}
                                    <EuiButton onClick={() => handleRemoveRow(index)}>Remove</EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </span>
                        <EuiSpacer />
                    </React.Fragment>
                );
            })}

            <EuiButton fill onClick={handleAddRow}>
                Add Row
            </EuiButton>
        </>
    );
};

const rowOptions = [
    { value: 1, inputDisplay: '1' },
    { value: 2, inputDisplay: '2' },
    { value: 3, inputDisplay: '3' },
];
export default React.memo(CategoryLayout);
