import React from 'react';
import { Controller } from 'react-hook-form';
import { htmlIdGenerator } from '@elastic/eui/lib/services';
import { EuiCheckbox } from '@elastic/eui';

const CheckBoxField = (props) => {
    const { name, defaultValue, control, setValue } = props;
    return (
        <Controller
            render={({ field }) => (
                <EuiCheckbox
                    label={props?.label}
                    name={props?.name}
                    placeholder={props?.placeholder}
                    id={htmlIdGenerator()()}
                    onChange={(e) => {
                        field.onChange(e.target.checked);
                        setValue(name, e.target.checked);
                    }}
                    checked={Boolean(field.value)}
                    disabled={props?.isDisabled}
                    compressed
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ''}
        />
    );
};

export default CheckBoxField;
