import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

const BannerOrderingModes = ({ restaurantOrderingModes, control, setValue, watch }) => {
    const itemOrderingModes = useWatch({
        control,
        name: 'ordering_modes',
    });
    const [orderingModeState, setOrderingModeState] = useState([]);
    const [removedOrderingModes, setRemovedOrderingModes] = useState([]);

    useEffect(() => {
        if (itemOrderingModes?.length) {
            setOrderingModeState(itemOrderingModes);
        }
    }, [itemOrderingModes, watch]);

    const updateOrderingMode = useCallback(
        (mode, event) => {
            let selectedMode = {};
            selectedMode = {
                restaurant_ordering_mode_id: mode?.id,
                restaurant_ordering_mode_name: mode?.display_name,
            };
            const isChecked = event.target.checked;

            if (!isChecked) {
                // removed ordering mode
                const doesModeExisted = restaurantOrderingModes?.filter(
                    (res_mode) => res_mode.id === selectedMode.restaurant_ordering_mode_id
                )?.[0];

                if (!isEmpty(doesModeExisted)) {
                    setRemovedOrderingModes([...removedOrderingModes, doesModeExisted?.id]);
                    const updatedOrderingMode = orderingModeState.filter(
                        (mode) => mode.restaurant_ordering_mode_id !== selectedMode.restaurant_ordering_mode_id
                    );
                    if (updatedOrderingMode.length) {
                        setOrderingModeState(updatedOrderingMode);
                        setValue(`ordering_modes`, updatedOrderingMode);
                    }
                    setValue(`delete_ordering_modes`, [...removedOrderingModes, doesModeExisted?.id]);
                } else {
                    const removedFromOrderingState = orderingModeState.filter(
                        (mode) => mode.restaurant_ordering_mode_id !== selectedMode.restaurant_ordering_mode_id
                    );
                    setOrderingModeState(removedFromOrderingState);
                    setValue(`ordering_modes`, removedFromOrderingState);
                }
            } else {
                setOrderingModeState((prevState) => [...prevState, selectedMode]);

                // check if the id is in removeState
                const destroyFromRemoveState = removedOrderingModes?.filter(
                    (id) => id != selectedMode.restaurant_ordering_mode_id
                );
                setRemovedOrderingModes(destroyFromRemoveState);
                setValue(`delete_ordering_modes`, destroyFromRemoveState);
                setValue(`ordering_modes`, [...orderingModeState, selectedMode]);
                // setOrderingModeState([...orderingModeState, modeComeBack]);
            }
        },
        [orderingModeState, removedOrderingModes, restaurantOrderingModes, setValue]
    );

    return (
        <>
            <EuiFlexGroup alignItems='center' style={{ padding: '10px' }} justifyContent='flexStart' wrap responsive>
                {restaurantOrderingModes
                    .filter((orderingMode) => orderingMode.status)
                    .map((mode) => {
                        return (
                            <EuiFlexItem key={mode.id.toString()}>
                                <EuiFlexGroup direction='row' justifyContent='flexStart' alignItems='center'>
                                    <EuiFlexItem grow={false}>
                                        <Controller
                                            render={({ field }) => (
                                                <EuiCheckbox
                                                    onChange={(e) => {
                                                        field.onChange(e.target.checked);
                                                        updateOrderingMode(mode, e);
                                                        setValue(`mode_${mode.id}`, e.target.checked);
                                                    }}
                                                    id={htmlIdGenerator()()}
                                                    checked={Boolean(field.value)}
                                                    compressed
                                                />
                                            )}
                                            name={`mode_${mode.id}`}
                                            control={control}
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiText style={{ textTransform: 'capitalize' }}>{mode.display_name}</EuiText>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        );
                    })}
            </EuiFlexGroup>
        </>
    );
};

// "ordering_modes": [
//     {
//         "restaurant_ordering_mode_id": 2
//     }
// ],

export default BannerOrderingModes;
