import React, { useEffect, Fragment, useState, useCallback } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4, v1 as uuid } from 'uuid';
import moment from 'moment';
import TextField from '../../../components/Form/TextField';
import SelectField from '../../../components/Form/SelectField';
import TimeSlot from '../../../components/Form/TimeSlot';
import API from '../../../api/axios/API';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiForm,
    EuiDatePicker,
    EuiSelect,
    EuiLoadingContent,
    EuiTextColor,
    EuiFieldSearch,
    EuiComboBox,
} from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useHistory } from 'react-router-dom';
import { createBanner } from '../../../api/banner/createBanner';
import { editBanner } from '../../../api/banner/editBanner';
import { isEmpty } from 'lodash';
import BannerOrderingModes from '../../../components/banner/bannerEdit/BannerOrderingModes';
import ImageSelectionZone from '../Components/ImageSelectionZone';
import { fetchRestaurantCatalogue } from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { useParams } from 'react-router-dom';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import FormRadioGroup from '../../../components/Form/FormRadioGroup';
import { clearConfigCache } from 'prettier';

import { useLocalStorage, useSetState } from 'react-use';
import SearchableDropDown from '../../../components/Form/SearchableDropdown';

const BannerCreateEditForm = () => {
    const currentTab = useSelector((state) => state.banner.bannerType);
    const history = useHistory();
    let editBannerId = useParams().id;
    editBannerId = editBannerId ? Number(editBannerId) : null;

    const banners = useSelector((state) => state.banner.banners);

    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState([]); // for images
    const [timeSlotSave, setTimeSlotSave] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const restaurantOrderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    // state
    const [allScreens, setAllScreens] = useState(null);
    const [categoryList, setCategoryList] = useState([]);

    const [imageDeleteState, setImageDeleteState] = useState({});

    const [loader, setLoader] = useState(false);
    let startDate = moment();
    let endDate = moment();
    const [aspectRatio, setAspectRatio] = useState({
        width: 16,
        height: 9.152,
        bannerType: 'primary',
        imageType: 'primary_banner',
    });

    const languageId = useSelector((state) => state.language.languageId);
    useEffect(() => {
        if (currentTab) {
            if (currentTab === 'secondary' || currentTab === 'tertiary') {
                setAspectRatio((prevState) => ({
                    ...prevState,
                    width: 26,
                    height: 9.624,
                    bannerType: currentTab === 'secondary' ? 'is_secondary' : 'is_tertiary',
                    imageType: currentTab === 'secondary' ? 'secondary_banner' : 'tertiary_banner',
                }));
            } else {
                setAspectRatio((prevState) => ({
                    ...prevState,
                    width: 16,
                    height: 9.152,
                    bannerType: 'primary',
                }));
            }
        }
    }, [currentTab]);

    const fetchCategories = useCallback(async () => {
        await fetchRestaurantCatalogue(languageId).then((response) => {
            if (response?.categories?.length) {
                const list = [];
                for (const category of response.categories) {
                    list.push({
                        value: category.category_id.toString(),
                        inputDisplay: `${category.translations.title[languageId]} - ${category.internal_name} `,
                    });
                }
                setCategoryList(list);
            }
        });
    }, [languageId]);

    const listOfDropdownCategories = categoryList.map((item) => item.inputDisplay);

    const fetchScreens = useCallback(async () => {
        setLoader(true);
        try {
            const screenAndCategory = await Promise.all([API.get(`/mobile/screens-list`)]);
            setAllScreens(screenAndCategory[0]);

            setLoader(false);
        } catch (e) {
            setLoader(false);
        }
    }, []);

    useEffect(() => {
        fetchScreens();
    }, [fetchScreens]);

    useEffect(() => {
        const banner = banners.filter((item) => item.id === editBannerId);

        setDefaultValues(updateFormValues(banner?.[0]));
    }, [editBannerId]);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        if (!isEmpty(data)) {
            defaultValue.title = data.title;
            const image = {};
            for (const property in data?.translations?.image) {
                image[parseInt(property)] = {
                    ...data.translations.image[property],
                    src: data.translations.image[property]?.image_url,
                    image_url: data.translations.image[property]?.image_url,
                };
            }
            setImageDeleteState(image);
            defaultValue.image = image;

            if (data.ordering_modes?.length) {
                defaultValue['ordering_modes'] = data.ordering_modes;

                for (const element of data.ordering_modes) {
                    defaultValue[`mode_${element.restaurant_ordering_mode_id}`] = true;
                }
            }
            defaultValue.timer_for_banner =
                data.time_slot_setting === 'no_time_slots'
                    ? 'banner_no_time_slots'
                    : data.time_slot_setting === 'same_time_slot_for_all_days'
                      ? 'banner_same_time_slot_for_all_days'
                      : data.time_slot_setting === 'different_time_slots_for_different_days'
                        ? 'banner_different_time_slots_for_different_days'
                        : 'banner_no_time_slots';

            let formatedSscheduleMap = {};
            for (let scheduleElement of data.schedules) {
                if (formatedSscheduleMap.hasOwnProperty(scheduleElement.time_slots.day_of_week)) {
                    formatedSscheduleMap[scheduleElement.time_slots.day_of_week].push({
                        start_time: scheduleElement.time_slots.start_time,
                        end_time: scheduleElement.time_slots.end_time,
                    });
                } else {
                    formatedSscheduleMap[scheduleElement.time_slots.day_of_week] = [
                        {
                            start_time: scheduleElement.time_slots.start_time,
                            end_time: scheduleElement.time_slots.end_time,
                        },
                    ];
                }
            }

            //banner time slot
            if (data.time_slot_setting === 'same_time_slot_for_all_days') {
                let fieldData = [];
                formatedSscheduleMap &&
                    formatedSscheduleMap[1] &&
                    formatedSscheduleMap[1].map((item) =>
                        fieldData.push({
                            id: uuid(),
                            start_time: item.start_time,
                            end_time: item.end_time,
                        })
                    );
                defaultValue.time = fieldData;
            }
            defaultValue.weak = {
                sunday: [],
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
            };
            if (data.time_slot_setting === 'different_time_slots_for_different_days') {
                for (const key in formatedSscheduleMap) {
                    if (key.toString() === '7') {
                        formatedSscheduleMap[7].map((item) =>
                            defaultValue.weak.sunday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }
                    if (key.toString() === '1') {
                        formatedSscheduleMap[1].forEach((item) =>
                            defaultValue.weak.monday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }

                    if (key.toString() === '2') {
                        formatedSscheduleMap[2].map((item) =>
                            defaultValue.weak.tuesday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }

                    if (key.toString() === '3') {
                        formatedSscheduleMap[3].map((item) =>
                            defaultValue.weak.wednesday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }

                    if (key.toString() === '4') {
                        formatedSscheduleMap[4].map((item) =>
                            defaultValue.weak.thursday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }

                    if (key.toString() === '5') {
                        formatedSscheduleMap[5].map((item) =>
                            defaultValue.weak.friday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }

                    if (key.toString() === '6') {
                        formatedSscheduleMap[6].map((item) =>
                            defaultValue.weak.saturday.push({
                                id: uuidv4(),
                                start_time: item.start_time,
                                end_time: item.end_time,
                            })
                        );
                    }
                }
            }
            defaultValue.param = data.param;
            // set delivery,dine-in , takeaway data
            defaultValue.delivery = data.delivery ? 1 : 0;
            defaultValue.dine_in = data.dine_in ? 1 : 0;
            defaultValue.takeaway = data.takeaway ? 1 : 0;

            //set screen
            defaultValue.screen = !isEmpty(data.screen) ? data.screen : null;
            //set date
            defaultValue.validate_from = data.from_date ? moment(data.from_date) : '';
            defaultValue.validate_to = data.to_date ? moment(data.to_date) : '';

            defaultValue.perpetual_banner = data.from_date || data.to_date ? 'with_expiry' : 'with_out_expiry';

            // set default data to banner form
            reset({
                ...defaultValue,
                time: defaultValue?.time ? defaultValue.time : [],
                weak: defaultValue?.weak
                    ? defaultValue.weak
                    : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            });
        } else {
            defaultValue.perpetual_banner = 'with_out_expiry';
        }

        return {
            ...defaultValue,
            time: defaultValue?.time ? defaultValue.time : [],
            weak: defaultValue?.weak
                ? defaultValue.weak
                : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
        };
    }, []);

    const addBanner = useCallback(
        async (data) => {
            setLoader(true);
            data.screen = !isEmpty(data.screen) ? data.screen : null;
            const response = await dispatch(createBanner(data));
            if (response.success) {
                toastSuccessMessage('Banner successfully added.', dispatch);
                // clearPortal();
                history.goBack();
            } else {
                if (response?.errors?.title) {
                    toastsErrorMessage(response?.errors?.title, dispatch);
                } else if (response?.errors?.schedules?.days_of_week) {
                    toastsErrorMessage(response?.errors?.schedules?.days_of_week, dispatch);
                } else if (response?.errors?.schedules?.schedules?.time_slots?.start_time) {
                    toastsErrorMessage(response?.errors?.schedules?.schedules?.time_slots?.start_time, dispatch);
                } else if (response?.errors?.schedules?.schedules?.time_slots?.end_time) {
                    toastsErrorMessage(response?.errors?.schedules?.schedules?.time_slots?.end_time, dispatch);
                } else if (response?.errors?.schedules?.schedules?.time_slots?.translations) {
                    toastsErrorMessage(response?.errors?.schedules?.schedules?.time_slots?.translations, dispatch);
                } else if (errorMessage?.length) {
                    toastsErrorMessage(errorMessage[0], dispatch);
                }
            }

            setLoader(false);

            return response;
        },
        [dispatch, errorMessage, history]
    );
    //Add this function in editBannerData ehn you want to call delete api when image is repalced in banner and add it in dependency
    // const imageChecker = useCallback(
    //     async (data) => {
    //         for (var key in data.translations.image) {
    //             if (data?.translations?.image?.[key]) {
    //                 if (imageDeleteState[key]?.id && data.translations.image[key] !== imageDeleteState[key]?.id) {
    //                     try {
    //                         API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState[key].id}`);
    //                     } catch (error) {}
    //                 }
    //             } else {
    //                 if (imageDeleteState[key]?.id) {
    //                     try {
    //                         API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState[key].id}`);
    //                     } catch (error) {}
    //                 }
    //             }
    //         }
    //     },
    //     [imageDeleteState]
    // );

    const editBannerData = useCallback(
        async (data) => {
            // imageChecker(data);
            setLoader(true);

            const response = await dispatch(editBanner(editBannerId, data));
            if (response.success) {
                toastSuccessMessage('Banner updated successfully.', dispatch);
                // clearPortal();
                history.goBack();
            } else {
                if (response?.errors?.title) {
                    toastsErrorMessage(response?.errors?.title, dispatch);
                } else if (response?.errors?.schedules?.days_of_week) {
                    toastsErrorMessage(response?.errors?.schedules?.days_of_week, dispatch);
                } else if (response?.errors?.schedules?.schedules?.time_slots?.start_time) {
                    toastsErrorMessage(response?.errors?.schedules?.schedules?.time_slots?.start_time, dispatch);
                } else if (response?.errors?.schedules?.schedules?.time_slots?.end_time) {
                    toastsErrorMessage(response?.errors?.schedules?.schedules?.time_slots?.end_time, dispatch);
                } else if (response?.errors?.schedules?.schedules?.time_slots?.translations) {
                    toastsErrorMessage(response?.errors?.schedules?.schedules?.time_slots?.translations, dispatch);
                } else if (errorMessage?.length) {
                    toastsErrorMessage(errorMessage[0], dispatch);
                }
            }

            setLoader(false);

            return response;
        },
        [dispatch, editBannerId, errorMessage, history]
    );

    const languages = useSelector((state) => state.language.availableLanguages);

    const displayError = useCallback(() => {
        let errorsList = [];
        if (errorMessage) {
            for (var key in errorMessage) {
                errorsList.push(
                    <EuiTextColor key={key} color='danger'>
                        {errorMessage[key]}
                    </EuiTextColor>
                );
            }
        }
        return errorsList;
    }, [errorMessage]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const selectedImages = languages.map((language, index) => data?.image?.[language.language_id]);
            const checkForEmptyImage = selectedImages?.every((image) => image?.id);

            if (!checkForEmptyImage || isEmpty(data?.image)) {
                setErrorMessage((prevState) => [...prevState, 'Please select an image']);
                return {
                    response: false,
                    message: 'Please select an image',
                };
            } else {
                setErrorMessage([]);
            }

            if (timeSlotSave) {
                setTimeSlotSave(false);
            }
            const image = {};

            for (const property in data.image) {
                image[parseInt(property)] =
                    data.image[property] && data.image[property].id ? data.image[property].id : null;
            }

            data.translations = { image: image };

            const imageValidation = Object.values(data.translations.image).some((v) => v);

            if (imageValidation) {
            }

            //set Banner time slot

            let schedules;
            if (data.timer_for_banner === 'banner_no_time_slots' || data.timer_for_banner === true) {
                schedules = null;
                data.time_slot_setting = 'no_time_slots';
            } else if (data.timer_for_banner === 'banner_same_time_slot_for_all_days') {
                data.time_slot_setting = 'same_time_slot_for_all_days';
                schedules = [];

                let singleTimeSlot =
                    data.time &&
                    data.time.length > 0 &&
                    data.time.filter((item) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                singleTimeSlot &&
                    singleTimeSlot.length > 0 &&
                    schedules.push({
                        days_of_week: [1, 2, 3, 4, 5, 6, 7],
                        time_slots: singleTimeSlot,
                    });
                data.schedules = schedules.length > 0 ? schedules : null;
                if (!data.schedules) {
                    data.time_slot_setting = 'no_time_slots';
                }
            } else {
                schedules = [];
                data.time_slot_setting = 'different_time_slots_for_different_days';

                let sunday =
                    data.weak &&
                    data.weak.sunday &&
                    data.weak.sunday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                sunday &&
                    sunday.length > 0 &&
                    schedules.push({
                        days_of_week: [7],
                        time_slots: sunday,
                    });
                let monday =
                    data.weak &&
                    data.weak.monday &&
                    data.weak.monday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                monday &&
                    monday.length > 0 &&
                    schedules.push({
                        days_of_week: [1],
                        time_slots: monday,
                    });

                let tuesday =
                    data.weak &&
                    data.weak.tuesday &&
                    data.weak.tuesday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                tuesday &&
                    tuesday.length > 0 &&
                    schedules.push({
                        days_of_week: [2],
                        time_slots: tuesday,
                    });

                let wednesday =
                    data.weak &&
                    data.weak.wednesday &&
                    data.weak.wednesday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                wednesday &&
                    wednesday.length > 0 &&
                    schedules.push({
                        days_of_week: [3],
                        time_slots: wednesday,
                    });

                let thursday =
                    data.weak &&
                    data.weak.thursday &&
                    data.weak.thursday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                thursday &&
                    thursday.length > 0 &&
                    schedules.push({
                        days_of_week: [4],
                        time_slots: thursday,
                    });

                let friday =
                    data.weak &&
                    data.weak.friday &&
                    data.weak.friday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                friday &&
                    friday.length > 0 &&
                    schedules.push({
                        days_of_week: [5],
                        time_slots: friday,
                    });

                let saturday =
                    data.weak &&
                    data.weak.saturday &&
                    data.weak.saturday.filter((item, index) => {
                        if (item.start_time !== undefined && item.end_time !== undefined) {
                            return item;
                        }
                        return null;
                    });
                saturday &&
                    saturday.length > 0 &&
                    schedules.push({
                        days_of_week: [6],
                        time_slots: saturday,
                    });
                data.schedules = schedules.length > 0 ? schedules : null;
                if (!data.schedules) {
                    data.time_slot_setting = 'no_time_slots';
                }
            }
            //setOutlet
            data.outlet_id =
                data.outlet &&
                data.outlet.map((item) => {
                    return item.value;
                });

            data.param = data.screen === 'category-detail-screen' ? data.param : null;

            data.delivery = data.delivery ? 1 : 0;

            data.takeaway = data.takeaway ? 1 : 0;

            data.from_date =
                data.perpetual_banner === 'with_expiry' ? moment(data.validate_from).format('YYYY-MM-DD') : null;
            data.to_date =
                data.perpetual_banner === 'with_expiry' ? moment(data.validate_to).format('YYYY-MM-DD') : null;

            // banner type

            if (currentTab === 'primary') {
                data['is_tertiary'] = 0;
                data['is_secondary'] = 0;
            } else if (currentTab === 'secondary') {
                data['is_secondary'] = 1;
            } else if (currentTab === 'tertiary') {
                data['is_tertiary'] = 1;
            }

            delete data.image;
            delete data.validate_from;
            delete data.validate_to;

            let response = null;

            const selectedCategories =
                data?.item?.map((item) => {
                    const categoryLabel = item?.label;
                    const categoryValue = categoryList.find((el) => el.inputDisplay === categoryLabel)?.value;
                    return Number(categoryValue);
                }) || [];

            data.params = selectedCategories;

            if (!editBannerId) {
                response = await addBanner(data);
            } else {
                response = await editBannerData(data);
            }

            return response;
        },
        [addBanner, currentTab, editBannerData, editBannerId, languages, timeSlotSave, categoryList]
    );

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };

    const perpetual_banner = useWatch({
        control,
        name: 'perpetual_banner',
    });

    const selectedScreen = useWatch({
        control,
        name: 'screen',
    });

    useEffect(() => {
        if (selectedScreen === 'category-detail-screen' && !categoryList?.length) {
            fetchCategories();
        }
    }, [dispatch, fetchCategories, watch, selectedScreen, categoryList?.length]);

    // set screen for selection
    let screenList = [];
    allScreens &&
        allScreens.screens.map((item) =>
            screenList.push({
                value: item,
                inputDisplay: item,
            })
        );

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!categoryList.length) return;

        const listOfDropdownCategories = categoryList.map((item) => item.inputDisplay);

        const items = listOfDropdownCategories.map((label) => ({ label }));

        setOptions(items);
    }, [categoryList]);

    const [defaultValue, setDefaultValue] = useState([]);

    useEffect(() => {
        const selectedBanner = banners.find((banner) => banner.id === editBannerId);
        const paramsOfSelectedBanner = selectedBanner?.params || [];

        const InputDisplayNames = categoryList
            .filter((item) => paramsOfSelectedBanner.includes(Number(item.value)))
            .map((item) => ({ label: item.inputDisplay }));

        setValue('item', InputDisplayNames);
        setDefaultValue(InputDisplayNames);
    }, [banners, categoryList, editBannerId]);

    if (loader) {
        return (
            <EuiFlexGroup direction='column' gutterSize='xl'>
                <EuiFlexItem>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={4}>
                            <EuiLoadingContent lines={2} />
                        </EuiFlexItem>
                        <EuiFlexItem grow={6}>
                            <EuiLoadingContent lines={6} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={4}>
                            <EuiLoadingContent lines={2} />
                        </EuiFlexItem>
                        <EuiFlexItem grow={6}>
                            <EuiLoadingContent lines={6} />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        );
    }

    return (
        <FormProvider {...methods}>
            <EuiForm component='form'>
                <FormGroupDescription title='Banner title'>
                    <TextField
                        errors={errors}
                        // label='Title'
                        name='title'
                        placeholder='Please enter title'
                        rules={{ required: 'Please enter title' }}
                        control={control}
                    />
                </FormGroupDescription>
                <FormGroupDescription title='Banner image'>
                    {languages.map((item, index) => (
                        <Fragment key={item.language_id.toString() + index.toString()}>
                            <ImageSelectionZone language={item} aspectRatio={aspectRatio} />
                        </Fragment>
                    ))}
                </FormGroupDescription>

                <FormGroupDescription title='Banner screen'>
                    <SelectField
                        errors={errors}
                        // label='Screen'
                        name='screen'
                        // screenList
                        options={screenList}
                        valueOfSelected={watch('screen') ? watch('screen') : null}
                        placeholder='Please enter your screen'
                        control={control}
                        defaultValue={watch('screen') ? watch('screen') : null}
                    />
                </FormGroupDescription>
                {/* https://api.staging.fab.delivery/api/v2/restaurants/32/banners/287 */}
                {watch('screen') === 'category-detail-screen' && (
                    <>
                        <FormGroupDescription title='Banner categories'>
                            <SearchableDropDown
                                name='item'
                                placeholder='Please select categories'
                                control={control}
                                errors={errors}
                                options={options}
                                onChange={(value) => {
                                    setValue('item', value);
                                }}
                                rules={{ required: 'Please select categories' }}
                                defaultValue={defaultValue}
                            />
                        </FormGroupDescription>
                    </>
                )}
                <FormGroupDescription title='Banner mode'>
                    <BannerOrderingModes
                        restaurantOrderingModes={restaurantOrderingModes}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                    />
                </FormGroupDescription>

                <FormGroupDescription title='Banner validity'>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem>
                            <FormRadioGroup
                                label='Do you want to make this banner perpetual?'
                                name='perpetual_banner'
                                options={[
                                    { id: 'with_out_expiry', label: 'Yes' },
                                    { id: 'with_expiry', label: 'No' },
                                ]}
                            />
                        </EuiFlexItem>
                        {perpetual_banner === 'with_expiry' ? (
                            <EuiFlexItem>
                                <EuiFlexGroup direction='row'>
                                    <EuiFlexItem>
                                        <EuiFormRow
                                            label='Start Date'
                                            fullWidth={true}
                                            isInvalid={errors.validate_from}
                                            error={errors.validate_from ? errors.validate_from.message : ''}
                                        >
                                            <Controller
                                                render={({ field }) => (
                                                    <EuiDatePicker
                                                        selected={field.value}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                    />
                                                )}
                                                name='validate_from'
                                                control={control}
                                                placeholder='select starting date'
                                                rules={{
                                                    required: 'Please must be select ending date',
                                                    validate: (value) =>
                                                        value <= watch('validate_to') ||
                                                        'start date must be less than end date',
                                                }}
                                                isInvalid={errors.validate_from}
                                                fullWidth={true}
                                                defaultValue={
                                                    watch('validate_from') ? watch('validate_from') : startDate
                                                }
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>
                                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                                        <EuiFormRow
                                            label='End Date'
                                            fullWidth={true}
                                            isInvalid={errors.validate_to}
                                            error={errors.validate_to ? errors.validate_to.message : ''}
                                        >
                                            <Controller
                                                render={({ field }) => (
                                                    <EuiDatePicker
                                                        selected={field.value}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                        }}
                                                    />
                                                )}
                                                name='validate_to'
                                                control={control}
                                                defaultValue={watch('validate_to') ? watch('validate_to') : endDate}
                                                placeholder='select ending date'
                                                rules={{
                                                    required: 'Please must be select ending date',
                                                    validate: (value) =>
                                                        value >= watch('validate_from') ||
                                                        'end date must be greter than start date',
                                                }}
                                                isInvalid={errors.validate_to}
                                                fullWidth={true}
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexGroup>
                </FormGroupDescription>
                {/* <EuiSpacer size="xxl"/> */}
            </EuiForm>
            <FormGroupDescription title='Banner timing'>
                <EuiFlexGroup alignItems='center' style={{ marginTop: '10px' }}>
                    <EuiFlexItem grow={false}>
                        <Controller
                            render={({ field }) => (
                                <EuiFormRow
                                    // label={"Decide Outlet Timing"}
                                    isInvalid={errors.timer_for_banner}
                                    error={errors.timer_for_banner ? errors.timer_for_banner.message : ''}
                                >
                                    <EuiSelect
                                        options={bannerTimeSlot}
                                        value={
                                            watch('timer_for_banner')
                                                ? watch('timer_for_banner')
                                                : 'banner_no_time_slots'
                                        }
                                        onChange={(e) =>
                                            setValue(
                                                'timer_for_banner',
                                                e.target.value ? e.target.value : 'banner_no_time_slots'
                                            )
                                        }
                                    />
                                </EuiFormRow>
                            )}
                            name='timer_for_banner'
                            control={control}
                            fullWidth
                            defaultValue={
                                watch('timer_for_banner') ? watch('timer_for_banner') : 'banner_no_time_slots'
                            }
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>

                {watch('timer_for_banner') === 'banner_same_time_slot_for_all_days' && (
                    <>
                        <EuiFlexItem style={{ marginTop: '30px' }}>
                            <TimeSlot
                                setTimeSlotSave={setTimeSlotSave}
                                control={control}
                                setValue={setValue}
                                name='time'
                                errors={errors}
                                watch={watch}
                            />
                        </EuiFlexItem>
                    </>
                )}

                {watch('timer_for_banner') === 'banner_different_time_slots_for_different_days' && (
                    <>
                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    marginTop: '30px',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    <strong> Sunday </strong>
                                </EuiFlexItem>

                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.sunday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    <strong> Monday </strong>
                                </EuiFlexItem>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.monday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    {' '}
                                    <strong> Tuesday </strong>{' '}
                                </EuiFlexItem>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.tuesday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    {' '}
                                    <strong> Wednesday </strong>{' '}
                                </EuiFlexItem>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.wednesday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                                justifyContent: 'center',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    {' '}
                                    <strong> Thursday </strong>{' '}
                                </EuiFlexItem>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.thursday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    {' '}
                                    <strong> Friday </strong>{' '}
                                </EuiFlexItem>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.friday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>

                        <EuiFlexGroup
                            style={{
                                marginBottom: 30,
                                paddingBottom: 40,
                                borderBottom: '1px solid black',
                            }}
                        >
                            <EuiFlexGroup
                                style={{
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                }}
                            >
                                <EuiFlexItem
                                    style={{
                                        textAlign: 'left',
                                        marginLeft: '3%',
                                    }}
                                >
                                    {' '}
                                    <strong> Saturday </strong>{' '}
                                </EuiFlexItem>
                                <TimeSlot
                                    control={control}
                                    setValue={setValue}
                                    name={`weak.saturday`}
                                    errors={errors}
                                    watch={watch}
                                />
                            </EuiFlexGroup>
                        </EuiFlexGroup>
                    </>
                )}
            </FormGroupDescription>
            {errorMessage?.length ? (
                <EuiFlexGroup
                    direction='column'
                    style={{
                        backgroundColor: '#f8e9e9',
                        margin: '10px',
                        padding: '15px',
                        borderRadius: '5px',
                    }}
                >
                    <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                        <b>Please resolve below validations</b>
                    </EuiTextColor>
                    {displayError()}
                </EuiFlexGroup>
            ) : null}
        </FormProvider>
    );
};

export default BannerCreateEditForm;

const bannerTimeSlot = [
    {
        value: 'banner_no_time_slots',

        text: 'Banner is available at  for all days of the week ',
    },

    {
        value: 'banner_same_time_slot_for_all_days',

        text: 'Banner is available at same time for all days of the week',
    },

    {
        value: 'banner_different_time_slots_for_different_days',

        text: 'Banner is available at specific time for all days of the week',
    },
];
