import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import moment from 'moment';

export const HLDedicated = (props) => {
    const { order, onOrderStatusChange } = props;
    const [priceEstimate, setPriceEstimate] = useState({});
    const dispatch = useDispatch();

    const tdStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    };

    const weFastCostEstimate = async () => {
        let hl_Dedicated;
        try {
            hl_Dedicated = await API.post(
                `/restaurants/:restaurantId/sfxhlstorebased/get-order-estimation-time?order_id=${props.orderId}`
            );
            console.log(`asdfsdsadfdsff ==>`, hl_Dedicated);
        } catch (error) {
            hl_Dedicated = error;
        }

        if (hl_Dedicated.success) {
            setPriceEstimate(hl_Dedicated?.estimation);
        } else {
            alert(hl_Dedicated.message);
        }
    };

    useEffect(() => {
        weFastCostEstimate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignWeFastRider = async () => {
        let hl_Dedicated = '';
        props.setRiderModalOpen();
        try {
            hl_Dedicated = await API.post(`/restaurants/:restaurantId/sfxhlstorebased/assign-order`, {
                order_id: props.orderId,
            });
        } catch (error) {
            hl_Dedicated = error;
        }

        if (hl_Dedicated.success) {
            // props.stateUpdateData((prevState) => !prevState)
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
            // onOrderStatusChange();
        } else {
            alert(hl_Dedicated.message);
        }
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                {!isEmpty(priceEstimate) ? (
                    <EuiFlexItem>
                        <b>HL Dedicated Estimates</b>
                        <table style={{ marginTop: '10px' }}>
                            {/* <tr>
                                <td>Estimated Distance</td>
                                <td>:</td>
                                <td style={tdStyle}>{priceEstimate.dunzo_order_estimation.distance ? `${parseFloat(priceEstimate.dunzo_order_estimation.distance).toFixed(2)} Km` : ''}</td>
                            </tr> */}
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Approx Distance</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate?.approx_distance ? priceEstimate?.approx_distance : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Available Rider Count</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate?.available_rider_count ? priceEstimate?.available_rider_count : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Busy Rider Count</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate?.busy_rider_count ? priceEstimate?.busy_rider_count : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Delivery Cost</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    ₹{priceEstimate?.delivery_cost ? priceEstimate?.delivery_cost : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Drop Estimation Time</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>{priceEstimate?.drop_eta ? priceEstimate?.drop_eta : 0}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Free Riders</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>{priceEstimate?.free_riders ? priceEstimate?.free_riders : 0}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Estimation Time</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>{priceEstimate?.pickup_eta ? priceEstimate?.pickup_eta : 0}</td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Rain Surge Amount</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    ₹{priceEstimate?.rain_surge_amount ? priceEstimate?.rain_surge_amount : 0}
                                </td>
                            </tr>
                        </table>
                    </EuiFlexItem>
                ) : (
                    <EuiLoadingContent lines={5} />
                )}
            </EuiFlexItem>
            <EuiFlexItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EuiButton fullWidth={true} fill onClick={assignWeFastRider} disabled={isEmpty(priceEstimate)}>
                    Assign HL Dedicated Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
