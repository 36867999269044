import React, { useState } from 'react';
import TextField from '../../Form/TextField';
import {
    EuiButton,
    EuiCopy,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import FormGroupDescription from '../../formGroupDescription';
import ButtonGroup from '../../Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { requestForRiderService } from '../../../api/requestThirdPartyRiderService';
import { getOutletDetails } from '../../../api/outlet/getOutletDetails';

const FlashRider = () => {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useFormContext();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const outlet = useSelector((state) => state.outlet.selectedOutletDetails);
    const approvalDate = outlet?.third_party_rider_setting?.flash_request?.approval_date;
    const serviceStatus = outlet?.third_party_rider_setting?.flash_request?.status;
    const dispatch = useDispatch();
    const startService = () => {
        dispatch(requestForRiderService('flash', 'rider service request for flash')).then(() => {
            dispatch(getOutletDetails(outletId));
        });
    };
    let flyout;
    if (isFlyoutVisible) {
        flyout = (
            <EuiFlyout
                ownFocus
                onClose={() => {
                    setIsFlyoutVisible(!isFlyoutVisible);
                }}
                aria-labelledby={'simpleFlyoutTitle'}
            >
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>
                        <h2>Flash riders</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <ButtonGroup
                        idSelected={watch('is_flash_integration_enabled') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_flash_integration_enabled'
                        watch={watch}
                        label={'Flash integration'}
                    />

                    {watch('is_flash_integration_enabled') ? (
                        <>
                            <TextField
                                errors={errors}
                                label='Flash auth token'
                                name='flash_token'
                                placeholder='Enter Flash auth token'
                                rules={watch('flash_token') ? { required: 'Please Enter Flash auth token' } : {}}
                                control={control}
                            />
                            {/* flash_credits_key */}
                            <TextField
                                errors={errors}
                                label='Flash Key'
                                name='flash_credits_key'
                                placeholder='Enter Flash Key'
                                rules={watch('flash_credits_key') ? { required: 'Please Enter Flash Key' } : {}}
                                control={control}
                            />
                            <TextField
                                errors={errors}
                                label='Flash Store Brand'
                                name='flash_store_brand_id'
                                placeholder='Enter Flash Key'
                                rules={watch('flash_store_brand_id') ? { required: 'Please Enter Flash Key' } : {}}
                                control={control}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </EuiFlyoutBody>
            </EuiFlyout>
        );
    }

    return (
        <div style={{ maxWidth: '650px' }}>
            <FormGroupDescription
                title={restaurantDescriptions?.flash_riders?.display_name}
                description={restaurantDescriptions?.flash_riders?.description}
            >
                <EuiFlexItem>
                    <EuiButton
                        style={{
                            backgroundColor: serviceStatus === 'approved' && '#228B22',
                            color: serviceStatus === 'approved' && 'white',
                        }}
                        fill
                        onClick={startService}
                        disabled={serviceStatus ? true : false}
                    >
                        {serviceStatus ? serviceStatus : 'START SERVICE'}
                    </EuiButton>
                </EuiFlexItem>
                <EuiSpacer style={{ marginTop: '6px' }}>
                    <EuiText textAlign='center' size='xs'>
                        ------ OR ------
                    </EuiText>
                </EuiSpacer>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={() => setIsFlyoutVisible(!isFlyoutVisible)}>CONNECT ACCOUNT</EuiButton>
                </EuiFlexItem>
                {flyout}
            </FormGroupDescription>
            {serviceStatus === 'pending' && (
                <EuiText
                    style={{
                        padding: '8px 12px',
                        background: '#BBB',
                        color: 'white',
                        marginBottom: '12px',
                        borderRadius: '4px',
                        // width: '65%',
                    }}
                >
                    <p> Your reqeust has been received and it shall be activated by {approvalDate} </p>
                </EuiText>
            )}
        </div>
    );
};

export default FlashRider;
