import { EuiSpacer } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';
import MultipleOutletModes from './MultipleOutletModes';

const OutletOrderingTimings = ({ setValue, watch, control, errors, stateUpdate, reset }) => {
    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);

    return (
        <>
            {outletOrderingModes
                ?.filter((orderingMode) => orderingMode.is_enabled)
                .map((mode) => {
                    return (
                        <React.Fragment key={mode?.restaurant_ordering_mode_id.toString()}>
                            <MultipleOutletModes
                                orderingMode={mode}
                                display_name={mode.type}
                                errors={errors}
                                stateUpdate={stateUpdate}
                                weekDays={weekDays}
                                reset={reset}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                            />
                            <EuiSpacer size='s' />
                        </React.Fragment>
                    );
                })}
        </>
    );
};

export default React.memo(OutletOrderingTimings);
const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
