import React, { useState } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiOverlayMask,
    EuiConfirmModal,
    EuiRadioGroup,
    EuiFlexItem,
    EuiSpacer,
} from '@elastic/eui';
import { liverOrderStatusChange } from '../../api/liveOrders/liverOrderStatusChange';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import { useDispatch, useSelector } from 'react-redux';
import { PreparationTimeModal } from '../orderDetailsCard/preparationTimeModal';
import { userHasPermission } from '../../helper/auth';
import { RiderManageModal } from '../riderManageModal';
import { RiderInfoModal } from '../orderDetailsCard/riderInfoModal';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import { refundAmount } from '../../api/pastOrder/refundAmount';
import { getPastOrders } from '../../api/pastOrder/getPastOrders';
import { SET_SELECTED_ORDER } from '../../reduxStore/types/orders';
import { fetchTableOrderDetails } from '../TableManagement/Api/tableOperations';

const LiveOrderDetailsAction = (props) => {
    let { selectedOrder } = props;
    const [preparationTimeModal, setPreparationTimeModal] = useState(false);
    const [preparationTimeDetails, setPreparationTimeDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const currentOrderState = useSelector((state) => state.orders.currentOrderState);
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantList = useSelector((state) => state.auth.restaurantList);
    const dispatch = useDispatch();

    const [radioIdSelected, setRadioIdSelected] = useState('restaurant');
    const [confirmOrderCancel, setConfirmOrderCancel] = useState(false);
    const [riderModal, setRiderModal] = useState(false);
    const [refundLoader, setRefundLoader] = useState(false);
    const [state, setState] = useState({
        riderModalOpen: false,
        riderInfoModalOpen: false,
        userModalOpen: false,
        assinedRiderId: null,
        cancellLoading: false,
        acceptLoading: false,
    });
    const radios = [
        {
            id: 'restaurant',
            label: 'Restaurant',
        },
        {
            id: 'customer',
            label: 'Customer',
        },
    ];

    const updateOrderStatusCall = (status, time = 0) => {
        setIsLoading(true);
        dispatch({
            type: SET_SELECTED_ORDER,
            payload: selectedOrder.order_id,
        });
        dispatch(
            liverOrderStatusChange({ status: status, kitchen_preparation_time: time, cancelled_by: radioIdSelected })
        )
            .then((res) => {
                setIsLoading(false);
                if (res.success) {
                    if (props.isFromTableManagement) {
                        dispatch(fetchTableOrderDetails());
                        // electron auto print call
                        if (window?.api?.send && status === 'restaurant_accepted') {
                            let restaurantId = parseInt(localStorage.getItem('selectedRestaurant'));
                            let restaurant = restaurantList.restaurants.filter(
                                (restaurant) => restaurant.id == restaurantId
                            );
                            window.api.send('toMainPrint', {
                                orderId: selectedOrder.order_id,
                                restaurantName: restaurant[0].name,
                            });
                        }

                        if (status === 'restaurant_cancelled') return;
                    } else {
                        dispatch(getLiveOrders(currentOrderState));

                        // electron auto print call
                        if (window?.api?.send && status === 'restaurant_accepted') {
                            let restaurantId = parseInt(localStorage.getItem('selectedRestaurant'));
                            let restaurant = restaurantList.restaurants.filter(
                                (restaurant) => restaurant.id == restaurantId
                            );
                            window.api.send('toMainPrint', {
                                orderId: selectedOrder.order_id,
                                restaurantName: restaurant[0].name,
                            });
                        }

                        if (status === 'restaurant_cancelled') return;
                        dispatch(getLiveOrderDetailsAPI());
                    }
                }
            })
            .catch((err) => setIsLoading(false));
    };

    const onOrderStatusChange = (order) => {
        if (props.isFromTableManagement) {
            dispatch({
                type: SET_SELECTED_ORDER,
                payload: order.order_id,
            });
        }
        if (!order?.status?.restaurant_accepted_at) {
            setPreparationTimeModal(true);
        } else {
            updateOrderStatusCall(
                order.status.delivery_status === 'placed' ? 'restaurant_accepted' : 'restaurant_ready'
            );
        }
    };

    const onConfirmOrderCancel = (order) => {
        updateOrderStatusCall('restaurant_cancelled');
        setConfirmOrderCancel(false);
    };

    const onChange = (optionId) => {
        setRadioIdSelected(optionId);
    };

    let DeleteConformationModal = (
        <EuiOverlayMask>
            <EuiConfirmModal
                maxWidth={false}
                title='Are you sure you want to cancel this order?'
                onCancel={() => setConfirmOrderCancel(false)}
                onConfirm={() => onConfirmOrderCancel(selectedOrder)}
                cancelButtonText="No, don't do it"
                confirmButtonText='Yes, do it'
                buttonColor='danger'
                defaultFocusedButton='cancel'
            >
                <EuiRadioGroup
                    options={radios}
                    idSelected={radioIdSelected}
                    onChange={(id) => onChange(id)}
                    name='radio group'
                    legend={{
                        children: <span>Who is cancelling this order?</span>,
                    }}
                />
            </EuiConfirmModal>
        </EuiOverlayMask>
    );

    const riderButtonText = (order) => {
        if (
            order.rider ||
            order.elt_order ||
            order.dunzo_order ||
            order.wefast_order ||
            order.scout_order ||
            order.shadowfax_order ||
            order.porter_order ||
            order.pidge_order
        ) {
            return 'Rider Info';
        }

        return 'Rider Management';
    };

    const openRiderModal = () => {
        setRiderModal(true);
    };

    const closeRiderModal = () => {
        setRiderModal(false);

        if (!isEmpty(preparationTimeDetails)) {
            updateOrderStatusCall(preparationTimeDetails.status, preparationTimeDetails.value);
            setPreparationTimeDetails({});
        }
    };

    const RiderPermission = (order) => {
        if (userHasPermission(permissions, '/live-orders', 'write')) {
            return (
                !props.isPast &&
                !props.isFailedOrders &&
                order.ordering_mode !== 'takeaway' &&
                order.ordering_mode !== 'dine_in' &&
                !props.isCustomerOrder &&
                riderButtonText(order) !== 'Rider Info' && (
                    <div style={{ marginBottom: '10px' }} grow={5}>
                        <EuiButton
                            size='s'
                            fullWidth={true}
                            iconType='reporter'
                            color='primary'
                            onClick={() => openRiderModal()}
                        >
                            {riderButtonText(order)}
                        </EuiButton>
                    </div>
                )
            );
        } else if (userHasPermission(permissions, '/live-orders', 'read') && order.rider && order.rider_order) {
            return (
                !props.isPast &&
                !props.isFailedOrders &&
                order.ordering_mode !== 'takeaway' &&
                order.ordering_mode !== 'dine_in' &&
                !props.isCustomerOrder &&
                riderButtonText(order) !== 'Rider Info' && (
                    <div style={{ marginBottom: '10px' }} grow={5}>
                        <EuiButton
                            size='s'
                            fullWidth={true}
                            iconType='reporter'
                            color='primary'
                            onClick={() => openRiderModal()}
                        >
                            {riderButtonText(order)}
                        </EuiButton>
                    </div>
                )
            );
        }
    };

    const closeRiderInfoModal = () => {
        setRiderModal(false);
    };

    const setAssignRider = (id) => {
        setState({
            ...state,
            assinedRiderId: id,
        });

        if (!isEmpty(preparationTimeDetails)) {
            updateOrderStatusCall(preparationTimeDetails.status, preparationTimeDetails.value);
            setPreparationTimeDetails({});
        }
    };

    const unAssignRider = async (id) => {
        let response = '';
        try {
            response = await API.patch(`/restaurants/:restaurantId/orders/${id}/un-assign`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            setRiderModal(false);
        } else {
            alert('error in un assign');
        }
    };

    const riderCondition = (order) => {
        if (
            order.rider ||
            order.elt_order ||
            order.dunzo_order ||
            order.wefast_order ||
            order.scout_order ||
            order.shadowfax_order ||
            order.porter_order ||
            order.pidge_order
        ) {
            return (
                <RiderInfoModal
                    order={order}
                    isPast={props.isPast}
                    closeRiderModal={closeRiderModal}
                    unAssignRider={unAssignRider}
                />
            );
        }

        return (
            <RiderManageModal
                closeRiderModal={closeRiderModal}
                riderModalOpen={riderModal}
                setRiderModalOpen={closeRiderInfoModal}
                setAssignRider={setAssignRider}
                orderId={order.order_id}
                onOrderStatusChange={() => onOrderStatusChange(order)}
                isPast={props.isPast}
                id={order.outlet_id}
                distance={order.distance}
                order={order}
            />
        );
    };

    const refundAmountAPI = () => {
        setRefundLoader(true);
        dispatch(refundAmount(selectedOrder.order_id)).then((response) => {
            if (response.success) {
                dispatch(getPastOrders(props.startDateTime, props.endDateTime, props.state, currentOrderingMode));
                setRefundLoader(false);
                return;
            }
            setRefundLoader(false);
            alert(response.message);
        });
    };

    return (
        <>
            {preparationTimeModal && (
                <PreparationTimeModal
                    liveOrderComponenent={true}
                    order={selectedOrder}
                    setPreparationTimeModal={setPreparationTimeModal}
                    updateOrderStatusCall={updateOrderStatusCall}
                    liveOrderComponent={true}
                    setRiderModal={setRiderModal}
                    setPreparationTimeDetails={setPreparationTimeDetails}
                />
            )}

            {riderModal && !props.isPast && !props.isAbandoned && riderCondition(selectedOrder)}

            {confirmOrderCancel && DeleteConformationModal}

            <>{!props.isPast && !props.isAbandoned && RiderPermission(selectedOrder)}</>

            {!props.isPast &&
                (selectedOrder.ordering_mode === 'takeaway' || selectedOrder.ordering_mode === 'dine_in') &&
                selectedOrder?.status?.restaurant_ready_at &&
                !props.isCustomerOrder &&
                userHasPermission(permissions, '/live-orders', 'write') &&
                !props.isPast &&
                !props.isAbandoned &&
                !props.isFailedOrders && (
                    <div style={{ marginBottom: '10px' }} grow={5}>
                        <EuiButton
                            size='s'
                            fill
                            fullWidth={true}
                            iconType='reporter'
                            color='primary'
                            disabled={selectedOrder.status.delivered_at || isLoading}
                            onClick={() =>
                                updateOrderStatusCall(
                                    selectedOrder.ordering_mode === 'dine_in' ? 'delivered' : 'customer_picked_up'
                                )
                            }
                        >
                            {selectedOrder.status.delivered_at ? 'Order Served' : 'Complete order'}
                        </EuiButton>
                    </div>
                )}

            {!props.isPast && !props.isCustomerOrder && !props.isFailedOrders && !props.isOrderFlyout && (
                <>
                    {!selectedOrder?.status?.restaurant_accepted_at
                        ? userHasPermission(permissions, '/live-orders', 'write') &&
                          !props.isPast &&
                          !props.isAbandoned && (
                              <EuiButton
                                  size='s'
                                  onClick={() => onOrderStatusChange(selectedOrder)}
                                  disabled={isLoading}
                                  style={{ backgroundColor: '#0071c2' }}
                                  fill
                              >
                                  Accept order
                              </EuiButton>
                          )
                        : ''}

                    {selectedOrder?.status?.restaurant_accepted_at &&
                    !selectedOrder?.status?.restaurant_ready_at &&
                    !props.isPast &&
                    !props.isAbandoned
                        ? userHasPermission(permissions, '/live-orders', 'write') && (
                              <EuiButton
                                  size='s'
                                  onClick={() => onOrderStatusChange(selectedOrder)}
                                  style={{ backgroundColor: '#0071c2' }}
                                  disabled={isLoading}
                                  fill
                              >
                                  Ready order
                              </EuiButton>
                          )
                        : ''}

                    {userHasPermission(permissions, '/live-orders', 'delete') &&
                        !props.isPast &&
                        !props.isAbandoned &&
                        !selectedOrder?.status?.delivered_at && (
                            <EuiButtonEmpty
                                size='s'
                                onClick={() => setConfirmOrderCancel(true)}
                                style={{ color: '#b4251d' }}
                            >
                                Cancel order
                            </EuiButtonEmpty>
                        )}
                </>
            )}
            {userHasPermission(permissions, '/past-orders', 'delete') &&
                selectedOrder.net_total !== 0 &&
                props.isPast &&
                selectedOrder?.payment_method?.payment_provider !== 'cash-on-delivery' &&
                !selectedOrder?.refunds?.length && (
                    <EuiFlexItem grow={false}>
                        <EuiButton fill size='s' onClick={refundAmountAPI} isLoading={refundLoader}>
                            Refund
                        </EuiButton>
                        <EuiSpacer size='s' />
                    </EuiFlexItem>
                )}
        </>
    );
};

export default LiveOrderDetailsAction;
