import APIV3 from '../../../../api/axios/APIV3';

export const fetchAddonGroupsAvailability = () => {
    return async (dispatch, getState) => {
        let response;
        const { selectedOutletId } = getState().outlet;

        try {
            // todo replace with addons API route
            response = await APIV3.get(`/restaurants/:restaurantId/outlets/${selectedOutletId}/addon-groups/stock`);
        } catch (error) {
            response = {
                isError: error,
            };
        }

        return response;
    };
};
