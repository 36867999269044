import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import LanguageBannerImage from '../../../modules/Banners/Components/LanguageBannerImage';

const VariantImage = ({ index, control, setValue, defaultValue }) => {
    const [selectedImages, setSelectedImages] = useState({});

    const itemImage = useWatch({
        control,
        name: `variantGroup[${index}].image_url`,
    });

    useEffect(() => {
        if (!isEmpty(itemImage)) {
            setSelectedImages(itemImage);
        } else {
            setSelectedImages({});
        }
    }, [itemImage]);

    return (
        <>
            <LanguageBannerImage
                buttonLabel={'Select an image'}
                selectedImgButtonLabel={'Change variant image'}
                imageType={'variant_options'}
                selectedImages={selectedImages}
                withRemove={true}
                name={`variantGroup[${index}].image_url`}
                defaultImageValue={defaultValue}
                setValue={setValue}
                // label='Brand Logo'
                aspectRatio={{
                    width: 1,
                    height: 1,
                }}
                setSelectedImages={setSelectedImages}
            />
        </>
    );
};

export default React.memo(VariantImage);
