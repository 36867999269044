import React, { useCallback } from 'react';
import {
    EuiButton,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiIcon,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import { useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { offerTypes } from '../Utils/utils';

const OutletOfferList = ({ outletOfferList, handleUnlink, totalPageCount, setOutletOfferList }) => {
    const currentLanguage = useSelector((state) => state.language.languageId);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const getOfferDisplayName = useCallback((content) => {
        let name = '';
        offerTypes.forEach((offer) => {
            if (offer.value === content.offer_type) {
                name = offer.displayName;
            }
        });
        return name;
    }, []);
    const onDragEnd = useCallback(
        async ({ source, destination }) => {
            const offers = euiDragDropReorder(outletOfferList, source.index, destination.index);
            let payloadOffers = [];
            for (const [index, offer] of offers.entries()) {
                payloadOffers.push({
                    id: offer.id,
                    display_sequence: index + 1,
                });
            }

            setOutletOfferList((prevState) => ({
                ...prevState,
                offers: offers,
            }));

            try {
                await API.patch(`/restaurants/:restaurantId/outlets/${outletId}/offers/change-sequence`, {
                    offers: payloadOffers,
                });
            } catch (error) {}
        },
        [outletId, outletOfferList, setOutletOfferList]
    );

    return (
        <EuiFlexGroup gutterSize='none' direction='column'>
            <EuiFlexItem>
                <EuiFlexGroup>
                    {colHeader.map((header, idx) => {
                        return (
                            <EuiFlexItem key={idx}>
                                <EuiText size='s' style={{ fontWeight: 'bold' }}>
                                    {header}
                                </EuiText>
                            </EuiFlexItem>
                        );
                    })}
                </EuiFlexGroup>
                <EuiSpacer size='s' />
                <EuiHorizontalRule margin='none' />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiDragDropContext onDragEnd={onDragEnd}>
                    <EuiDroppable droppableId='DROPPABLE_AREA' spacing='none' withPanel={false}>
                        {outletOfferList.map((content, idx) => {
                            return (
                                <EuiFlexItem key={idx.toString()}>
                                    <EuiSpacer size='s' />
                                    <EuiDraggable
                                        spacing='none'
                                        key={content.id.toString()}
                                        index={idx}
                                        draggableId={content.id.toString()}
                                    >
                                        {(provided) => (
                                            <>
                                                <EuiFlexGroup alignItems='center'>
                                                    <EuiFlexItem>
                                                        <EuiFlexGroup alignItems='center'>
                                                            <EuiFlexItem
                                                                grow={false}
                                                                {...provided.dragHandleProps}
                                                                aria-label='Drag Handle'
                                                            >
                                                                <EuiIcon type='grab' />
                                                            </EuiFlexItem>
                                                            <EuiFlexItem>
                                                                <EuiText>{getOfferDisplayName(content)}</EuiText>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiFlexItem>
                                                    <EuiFlexItem>
                                                        <EuiText>
                                                            {content?.translations?.title?.[currentLanguage]}
                                                        </EuiText>
                                                    </EuiFlexItem>
                                                    <EuiFlexItem>
                                                        <EuiText>
                                                            {content?.translations?.description?.[
                                                                currentLanguage
                                                            ]?.slice(0, 60)}
                                                            ...
                                                        </EuiText>
                                                    </EuiFlexItem>
                                                    <EuiFlexItem>
                                                        <EuiText>{content.coupon_code}</EuiText>
                                                    </EuiFlexItem>
                                                    <EuiFlexItem>
                                                        <EuiButton size='s' onClick={() => handleUnlink(content.id)}>
                                                            <EuiText size='s'> Unlink</EuiText>
                                                        </EuiButton>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </>
                                        )}
                                    </EuiDraggable>
                                    <EuiSpacer size='s' />
                                    <EuiHorizontalRule margin='none' />
                                </EuiFlexItem>
                            );
                        })}
                    </EuiDroppable>
                </EuiDragDropContext>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

const colHeader = ['Offer Type', 'Title', 'Description', 'Coupon Code', 'Unlink'];

export default React.memo(OutletOfferList);
