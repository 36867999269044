import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import moment from 'moment';

export const FlashRider = (props) => {
    const { order, onOrderStatusChange } = props;
    const [priceEstimate, setPriceEstimate] = useState({});
    const dispatch = useDispatch();

    const tdStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    };
    const weFastCostEstimate = async () => {
        let flashResponse;
        try {
            flashResponse = await API.post(
                `/restaurants/:restaurantId/flash/get-order-estimation-time?order_id=${props.orderId}`
            );
        } catch (error) {
            flashResponse = error;
        }

        if (flashResponse.success) {
            setPriceEstimate(flashResponse);
        } else {
            alert(flashResponse.message);
        }
    };

    useEffect(() => {
        weFastCostEstimate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignWeFastRider = async () => {
        let flashResponse = '';
        props.setRiderModalOpen();
        try {
            flashResponse = await API.post(`/restaurants/:restaurantId/flash/assign-order `, {
                order_id: props.orderId,
            });
        } catch (error) {
            flashResponse = error;
        }

        if (flashResponse.success) {
            // props.stateUpdateData((prevState) => !prevState)
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
            // onOrderStatusChange();
        } else {
            alert(flashResponse.message);
        }
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                {!isEmpty(priceEstimate) ? (
                    <EuiFlexItem>
                        <b>Flash Estimates</b>
                        <table style={{ marginTop: '10px' }}>
                            {/* <tr>
                                <td>Estimated Distance</td>
                                <td>:</td>
                                <td style={tdStyle}>{priceEstimate.dunzo_order_estimation.distance ? `${parseFloat(priceEstimate.dunzo_order_estimation.distance).toFixed(2)} Km` : ''}</td>
                            </tr> */}
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Rain Rider Incentive</td>
                                <td style={tdStyle}>
                                    {priceEstimate.estimation.rain_rider_incentive
                                        ? priceEstimate.estimation.rain_rider_incentive
                                        : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>High Demand Surge Amount</td>
                                <td style={tdStyle}>
                                    {priceEstimate.estimation.high_demand_surge_amount
                                        ? priceEstimate.estimation.high_demand_surge_amount
                                        : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Estimation Time</td>
                                <td style={tdStyle}>
                                    {priceEstimate.estimation.pickup_eta ? priceEstimate.estimation.pickup_eta : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Total Amount</td>
                                <td style={tdStyle}>
                                    ₹{priceEstimate.estimation.total_amount ? priceEstimate.estimation.total_amount : 0}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Total Distance</td>
                                <td style={tdStyle}>
                                    {priceEstimate.estimation.total_distance
                                        ? priceEstimate.estimation.total_distance.toFixed(2)
                                        : 0}
                                </td>
                            </tr>
                        </table>
                    </EuiFlexItem>
                ) : (
                    <EuiLoadingContent lines={5} />
                )}
            </EuiFlexItem>
            <EuiFlexItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EuiButton fullWidth={true} fill onClick={assignWeFastRider} disabled={isEmpty(priceEstimate)}>
                    Assign Flash Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
