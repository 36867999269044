import React, { useRef } from 'react';
import { EuiFormRow, EuiFieldNumber } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const FieldArrayNumberField = (props) => {
    const numberRef = useRef(null);
    const onWheel = () => {
        numberRef.current.blur();
    };
    const { label, name, placeholder, rules, errors, isInvalid, control, fullWidth } = props;
    return (
        <EuiFormRow
            label={label}
            isInvalid={isInvalid}
            error={props.error ? props.error : errors ? errors : null}
            fullWidth={fullWidth ? fullWidth : false}
        >
            <Controller
                render={({ field }) => {
                    return (
                        <EuiFieldNumber
                            name={name}
                            placeholder={props?.placeholder}
                            value={field.value}
                            onChange={field.onChange}
                            // defaultValue={props.defaultValue}
                        />
                    );
                }}
                name={name}
                control={control}
                rules={rules}
                fullWidth={true}
                placeholder={placeholder}
                inputRef={numberRef}
                onWheel={onWheel}
            />
        </EuiFormRow>
    );
};

export default FieldArrayNumberField;
