import { EuiRadio, EuiText, htmlIdGenerator } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';

const TableHeader = ({ isPast, isAbandoned, isCustomerOrder, isFailedOrders, onChangeRadio }) => {
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);

    // console.log(`renders`);

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {!isPast ? (
                <div style={{ width: '40%', fontSize: '20.3px', fontWeight: 'bold' }}>
                    <EuiText>
                        <h3>
                            <b>
                                {isAbandoned
                                    ? 'Abandoned orders'
                                    : isCustomerOrder
                                      ? 'Customer orders'
                                      : isFailedOrders
                                        ? 'Failed Orders'
                                        : !isPast
                                          ? 'Live Orders'
                                          : null}
                            </b>
                        </h3>
                    </EuiText>
                </div>
            ) : null}

            <div
                style={{
                    width: isPast ? '100%' : '60%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isPast ? 'start' : 'space-between',
                    paddingRight: '0 5px',
                    gap: isPast && 15,
                }}
            >
                <EuiRadio
                    className='radioFontSize'
                    id={htmlIdGenerator()()}
                    checked={currentOrderingMode === 'all'}
                    label='All'
                    onChange={() => onChangeRadio('all')}
                />
                <EuiRadio
                    className='radioFontSize'
                    id={htmlIdGenerator()()}
                    checked={currentOrderingMode === 'takeaway'}
                    label='Takeaway'
                    onChange={() => onChangeRadio('takeaway')}
                />
                <EuiRadio
                    className='radioFontSize'
                    id={htmlIdGenerator()()}
                    checked={currentOrderingMode === 'delivery'}
                    label='Delivery'
                    onChange={() => onChangeRadio('delivery')}
                />
                <EuiRadio
                    className='radioFontSize'
                    id={htmlIdGenerator()()}
                    checked={currentOrderingMode === 'dine_in'}
                    label='Dine In'
                    onChange={() => onChangeRadio('dine_in')}
                />
            </div>
        </div>
    );
};

export default React.memo(TableHeader);
